import React from "react";
import { SystemProps } from "@storyofams/react-ui";
import hoistNonReactStatics from "hoist-non-react-statics";
import { Layout } from "~components";

const getDisplayName = (Component) =>
  Component.displayName || Component.name || "Component";

export function withLayout(Component: any, layoutProps?: SystemProps) {
  const LayoutComponent = ({ footer, navigation, ...props }) => {
    return (
      <Layout {...layoutProps} footer={footer} navigation={navigation}>
        <Component {...props} />
      </Layout>
    );
  };

  hoistNonReactStatics(LayoutComponent, Component);

  LayoutComponent.displayName = `withLayout(${getDisplayName(Component)})`;

  return LayoutComponent;
}
