import { Box, Flex } from "@storyofams/react-ui";
import { findLastIndex } from "lodash";
import styled from "styled-components";

import { Text } from "~components/common/Text";

const ProgressItem = styled(Flex)<{ completed: boolean; zIndex: number }>`
  position: relative;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  margin-bottom: 32px;
  padding: 0 16px;
  text-align: center;
  z-index: ${(p) => p.zIndex};

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    flex-direction: row;
    padding: 0;
    text-align: left;
  }

  &:before {
    content: "";
    position: absolute;
    background-color: ${(p) =>
      p.theme.colors[p.completed ? "primary" : "primary400"]};
    height: 4px;
    top: 14px;
    left: -50%;
    width: 100%;

    @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
      left: 14px;
      top: -32px;
      bottom: 0;
      height: auto;
      width: 4px;
    }
  }

  &:first-child:before {
    display: none;
  }

  &:last-child:before {
    right: 50%;
  }
`;

const Dot = styled.div<{ completed: boolean }>`
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: ${(p) =>
    p.theme.colors[p.completed ? "primary" : "primary400"]};
  border-radius: 50%;
  position: relative;
`;

const InnerDot = styled.div<{ variant: "small" | "medium" }>`
  border-radius: 50%;
  position: absolute;

  width: ${(p) => (p.variant === "small" ? "16px" : "24px")};
  min-width: ${(p) => (p.variant === "small" ? "16px" : "24px")};
  height: ${(p) => (p.variant === "small" ? "16px" : "24px")};

  top: ${(p) => (p.variant === "small" ? "8px" : "4px")};
  left: ${(p) => (p.variant === "small" ? "8px" : "4px")};
  right: ${(p) => (p.variant === "small" ? "8px" : "4px")};
  bottom: ${(p) => (p.variant === "small" ? "8px" : "4px")};

  background-color: ${(p) =>
    p.theme.colors[p.variant === "small" ? "primary" : "primary200"]};
`;

export const Progress = ({ steps, ...props }) => {
  const lastCompletedIndex = findLastIndex(
    steps,
    (step: { completed: boolean }) => step.completed
  );

  return (
    <Box {...props} display="flex" flexDirection={["column", "row"]}>
      {steps.map((step, idx) => (
        <ProgressItem
          key={step.label}
          completed={step.completed}
          zIndex={steps.length - idx}
        >
          <Dot completed={step.completed}>
            {idx === lastCompletedIndex && (
              <>
                <InnerDot variant="medium" />
                <InnerDot variant="small" />
              </>
            )}
          </Dot>

          <Text
            fontSize={1.5}
            mt={[0, 2]}
            ml={[2, 0]}
            fontWeight="bold"
            color="grey700"
            lineHeight="normal"
          >
            {step.label}
          </Text>
        </ProgressItem>
      ))}
    </Box>
  );
};
