import { MotionProps, motion } from "framer-motion";
import { ImageProps } from "next/image";
import styled from "styled-components";
import shopifyImageLoader from "~lib/shopify/imageLoader";
import storyblokImageLoader from "~lib/storyblok/imageLoader";
import NextImage from "next/image";


type ImageType = ImageProps & {
  customLoader: "storyblok" | "shopify";
  isHovering?: boolean;
  className?: string;
  initial?: MotionProps["initial"];
  animate?: MotionProps["animate"];
};

export const Image = ({
  isHovering,
  customLoader,
  className,
  initial = {
    scale: 1,
  },
  animate,
  ...props
}: ImageType) => {
  return (
    <motion.div
      initial={initial}
      animate={
        animate || {
          scale: isHovering ? 1.1 : 1,
        }
      }
      transition={{
        type: "tween",
        duration: 0.6,
      }}
      className={className}
    >
      <NextImage
        loader={
          customLoader === "storyblok"
            ? storyblokImageLoader
            : shopifyImageLoader
        }
        {...props}
      />
    </motion.div>
  );
};

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
`;
