import { Icon, css } from "@storyofams/react-ui";
import { ApplePay, Mastercard, Visa, Bitcoin, Sofort, Ideal } from "../Icons";

export const PaymentIcon = ({ icon }) => {
  const icons = [
    { label: "applepay", icon: ApplePay },
    { label: "mastercard", icon: Mastercard },
    { label: "visa", icon: Visa },
    { label: "sofort", icon: Sofort },
    { label: "bitcoin", icon: Bitcoin },
    { label: "ideal", icon: Ideal },
  ];

  const paymentIcon = icon && icons.find((i) => icon === i.label);

  return (
    <Icon
      icon={paymentIcon.icon}
      width={["36px", "48px"]}
      height={["21px", "28px"]}
      css={css({ svg: { height: "100%", width: "100%" } })}
    />
  );
};
