import styled from "styled-components";

import { Slider, ProductCard, ProductCardPlaceholder } from "~components";
import { ViewedProductsProvider } from "~context/ViewedProducts";
import { InviewLoader } from "../InviewLoader";
import { columnToWidth } from "~components/atoms/Grid/Grid";

const SliderWrapper = styled.div`
  width: 100%;

  & .swiper-slide {
    width: ${() => columnToWidth(3, "xl")};

    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
      width: ${() => columnToWidth(5, "md")};
    }

    @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
      width: ${() => columnToWidth(7, "sm")};
    }

    @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
      width: ${() => columnToWidth(9, "xs")};
    }
  }
`;

type Props = {
  products: any;
  isLoading?: boolean;
};

export const ProductsSlider = ({ products, isLoading }: Props) => {
  return (
    <ViewedProductsProvider eventIdentifier="Products Slider">
      <SliderWrapper>
        <Slider spaceBetween={20} hideScrollBar={true}>
          {isLoading
            ? [
                <ProductCardPlaceholder key={"placeholder0"} />,
                <ProductCardPlaceholder key={"placeholder1"} />,
                <ProductCardPlaceholder key={"placeholder2"} />,
                <ProductCardPlaceholder key={"placeholder3"} />,
              ]
            : products?.map((product, index) => {
                if (product?.product || product?.node || product) {
                  return (
                    <InviewLoader key={index}>
                      <ProductCard
                        showCardButton={true}
                        {...(product?.product || product?.node || product)}
                      />
                    </InviewLoader>
                  );
                } else {
                  return null;
                }
              })}
        </Slider>
      </SliderWrapper>
    </ViewedProductsProvider>
  );
};
