import { ReactNode } from "react";
import styles from "./Grid.module.scss";
import { clsx } from "clsx";

type Props = {
  children: ReactNode | undefined;
  className?: string;
};

export const Grid = ({ children, className }: Props) => {
  return <div className={clsx(styles["section"], className)}>{children}</div>;
};

export const columnToWidth = (
  columns: number,
  size: "xs" | "sm" | "md" | "lg" | "xl"
) => {
  const gap =
    size === "xs" || size === "sm"
      ? 8
      : size === "md"
      ? 12
      : size === "lg"
      ? 16
      : 20;
  return `calc(
    (100% - ${gap * 11}px) / 12 * ${columns} +
      ${gap}px * ${columns - 1}
  )`;
};
