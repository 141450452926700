import React, { useEffect } from "react";
import { Spinner } from "@storyofams/react-ui";
import hoistNonReactStatics from "hoist-non-react-statics";
import Router, { useRouter } from "next/router";

import { useCustomer } from "~hooks";

const getDisplayName = (Component) =>
  Component.displayName || Component.name || "Component";

export function withAuth(Component: any) {
  const AuthComponent = (props) => {
    const { customer, customerIsLoading } = useCustomer();
    const router = useRouter();

    useEffect(() => {
      if (!customer && !customerIsLoading) {
        Router.replace({
          pathname: "/login",
          query: {
            to: router.pathname,
            as: router.asPath,
          },
        });
      }
    }, [customer, customerIsLoading]);

    if (customerIsLoading || !customer) {
      return <Spinner minHeight="400px" color="primary600" />;
    }

    return <Component {...props} />;
  };

  hoistNonReactStatics(AuthComponent, Component);

  AuthComponent.displayName = `withAuth(${getDisplayName(Component)})`;

  return AuthComponent;
}
