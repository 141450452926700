import React, { forwardRef, Ref, ReactElement } from "react";

import { Icon, Box, SystemProps } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { useId } from "react-id-generator";
import type { PolymorphicForwardRefExoticComponent } from "react-polymorphic-types";
import styled from "styled-components";

import { InputWrapper, InputWrapperProps } from "../InputWrapper";

const _defaultElement = "input";

type CustomProps = {
  icon?: ReactElement;
  id?: string;
  disabled?: boolean;
} & InputWrapperProps;

export const StyledInput = styled(Box)<{ error?: any } & SystemProps>`
  appearance: none;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.transparent};
  min-height: 44px;
  width: 100%;
  border: 1px solid
    ${({ error, theme }) => theme.colors[error ? "error300" : "grey500"]};
  transition: border-color 0.18s ease-in-out, background-color 0.18s ease-in-out;
  text-decoration: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey300};
  }

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
      border-color: ${({ theme }) => theme.colors.grey500};
    }
  }
`;

export const Input: PolymorphicForwardRefExoticComponent<
  CustomProps,
  typeof _defaultElement
> = forwardRef((props: CustomProps, ref: Ref<HTMLInputElement>) => {
  const {
    icon,
    label,
    status,
    statusMessage,
    error,
    disabled,
    id: givenId,
  } = props;
  const autoId = useId();
  const id = givenId || `input-${autoId}`;

  return (
    <InputWrapper
      id={id}
      label={label}
      status={status}
      statusMessage={statusMessage}
      error={error}
      fontSize={[2, 1.75]}
      fontWeight="regular"
      color="grey800"
      lineHeight="high"
      {...pick(props)}
    >
      <Box position="relative">
        <StyledInput
          id={id}
          ref={ref}
          pr={icon && 5}
          disabled={disabled}
          py={1.25}
          px={2}
          borderRadius="xs"
          as={_defaultElement}
          {...omit(props)}
        />
        {icon && (
          <Icon
            display="flex"
            alignItems="center"
            position="absolute"
            pointerEvents="none"
            right={1.5}
            top={0}
            bottom={0}
            opacity={disabled ? 0.6 : 1}
            icon={icon}
          />
        )}
      </Box>
    </InputWrapper>
  );
});
