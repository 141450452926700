import { useRouter } from "next/router";

const getHref = (defaultLocale, locale, locales) => {
  return (link) => {
    if (link?.linktype === "email") {
      return `mailto:${link?.email}`;
    }

    let href = "/";

    if (typeof link === "string") {
      href = link;
    }

    if (link?.linktype === "url") {
      href = link?.url;
    }

    if (link?.linktype === "story") {
      if (link?.story?.url) {
        href = link?.story?.full_slug;
      } else {
        href = link?.cached_url;
      }
    }

    if (href.startsWith("https://") || href.startsWith("http://")) {
      return href;
    }

    // Replace leading & trailing slashes
    href = `/${href.replace(/^\/*|\/*$/g, "")}`;
    // Replace all locales from start
    const langRegex = new RegExp(`^/((${locales.join("|")})/)+`);
    href = `${href.replace(langRegex, "/")}`;

    if (locale !== defaultLocale) {
      href = `/${locale}${href}`;
    }

    return href;
  };
};

export const useGetHref = () => {
  const { defaultLocale, locale, locales } = useRouter();

  return {
    getHref: getHref(defaultLocale, locale, locales),
  };
};
