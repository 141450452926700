import { defineMessages, useIntl } from "react-intl";

import { Button, CollectionCard, Container, Grid, Slider } from "~components";
import styles from "./MoreCollections.module.scss";
import { CollectionsQuery, StylesQuery } from "~lib/storyblok/sdk";

const messages = defineMessages({
  title: "More collections",
  titleStyles: "More styles",
  button: "View collection",
  buttonStyles: "View style",
});

type MoreInCollectionProps = {
  data: CollectionsQuery
  type: "collection" | "room"
} | {
  data: StylesQuery
  type: "style"
}

export const MoreInCollection = (props: MoreInCollectionProps) => {
  const { formatMessage } = useIntl();

  const visibleCollections = props.type === "style" 
  ? props.data.StyleItems.items 
  : props.data.CollectionItems.items?.filter((item) => {
      return item.content.exclude_from_other_collections !== true;
    });

  return (
    <Container className={styles.moreCollections}>
      <Grid>
        <h2 className={styles.title}>
          {formatMessage(messages.title)}
        </h2>
        <Slider className={styles.slider}>
          {visibleCollections.map((collection) => {
            return (
              <CollectionCard image={collection.content.image} title={collection.content.title} to={`/collections/${collection.slug}`} />
            );
          })}
        </Slider>
        <Button className={styles.button} as="link" color="secondaryOutline" href={"/collections"}>{formatMessage(messages.button)}</Button>
      </Grid>
    </Container>
  );
};
