import React, {
  ChangeEvent,
  useEffect,
  useState,
  MouseEvent,
  MutableRefObject,
} from "react";
import { Flex, Box, SystemProps, css, Icon } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { useId } from "react-id-generator";
import { useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { Plus, Minus } from "~components/common/Icons";

import { Input } from "../Input";
import { Text } from "../Text";
import styles from "./NumberInput.module.scss";

const messages = defineMessages({
  label: "Quantity",
});

const Btn = styled.button<{ cursor?: string }>`
  width: 30px;
  padding: ${(p) => p.theme.space[1.25]}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${(p) => p.theme.colors.grey900};
  font-size: ${(p) => p.theme.fontSizes[2]}px;
  line-height: 1;
  cursor: ${(p) => p.cursor || "pointer"};
  transition: 0.18s color ease-in-out;

  &:hover,
  &:active {
    color: ${(p) => p.theme.colors.primary};
  }
`;

export interface NumberInputProps extends SystemProps {
  saveButtonRef?: MutableRefObject<any>;
  value?: string;
  onBlur?(): void;
  onChange(string): void;
  /** a descriptive label for better accessibility */
  label?: string;
  required?: boolean;
  disabled?: boolean;
  id?: any;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  saveButtonRef,
  value,
  onChange,
  label: labelProp,
  id: givenId,
  ...props
}) => {
  const autoId = useId();
  const intl = useIntl();
  const id = givenId || `input-${autoId}`;
  const [localValue, setLocalValue] = useState<number | "">(Number(value));

  // Keep local value in sync with parent value state
  useEffect(() => {
    setLocalValue(Number(value));
  }, [value]);

  const label =
    labelProp || labelProp === null
      ? labelProp
      : intl.formatMessage(messages.label);

  function handleChange(newVal, e?: MouseEvent<HTMLButtonElement>) {
    if (e) {
      e.preventDefault();
    }

    if (newVal === 0) {
      onChange(1);
      setLocalValue(1);
      return;
    }

    if (newVal > 0) {
      onChange(newVal);
      setLocalValue(newVal);
    }
  }

  return (
    <Box
      flexDirection="column"
      display="inline-flex"
      css={css({
        input: {
          minHeight: "32px",
          height: "32px",
          border: "none",
          paddingTop: 0.75,
        },
      })}
      {...pick(props)}
    >
      {label && (
        <Text as="label" mb={1} htmlFor={id} variant="label">
          {label}
        </Text>
      )}
      <Flex
        display="inline-flex"
        height="32px"
        border="1px solid"
        borderColor="grey900"
        borderRadius="full"
        overflow="hidden"
        css={css({
          "> div": {
            minWidth: 32,
            mx: -1.25,
          },
        })}
      >
        <Btn
          data-testid="decrease-btn"
          disabled={Number(value) === 1}
          onClick={(e) => handleChange(Number(value) - 1, e)}
          // cursor={props.cursor as any}
        >
          <Icon fontSize={2} icon={Minus} />
        </Btn>

        <Input
          id={id}
          data-testid="input"
          type="number"
          value={localValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLocalValue(e.target.value === "" ? "" : Number(e.target.value));
          }}
          onBlur={() => {
            handleChange(localValue === "" ? 0 : localValue);
            if (saveButtonRef === undefined) return;
            saveButtonRef.current.style.pointerEvents = "auto";
          }}
          onFocus={() => {
            if (saveButtonRef === undefined) return;
            saveButtonRef.current.style.pointerEvents = "none";
          }}
          min="1"
          className={styles.input}
          {...omit(props)}
        />

        <Btn
          data-testid="increase-btn"
          onClick={(e) => handleChange(Number(value) + 1, e)}
          cursor={props.cursor as any}
        >
          <Icon fontSize={2} icon={Plus} />
        </Btn>
      </Flex>
    </Box>
  );
};
