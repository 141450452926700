import { yupResolver } from "@hookform/resolvers/yup";
import { Box, css } from "@storyofams/react-ui";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useIntl, defineMessages } from "react-intl";
import * as Yup from "yup";

import { showToast } from "~lib";
import { LegacyButton } from "~components/common/Button";
import { Heading } from "~components/common/Heading/heading";
import { Input } from "~components/common/Input";
import { Text } from "~components/common/Text";

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const messages = defineMessages({
  success: "Subscribed!",
  info: "You are already subscribed.",
  error: "Failed to subscribe.",
});

type SubscriptionFormInput = {
  email: string;
};

type SubscriptionFormProps = {
  title?: string;
  subtitle?: string;
  button?: string;
  placeholder?: string;
};

export const SubscriptionForm = ({
  title,
  subtitle,
  button,
  placeholder,
}: SubscriptionFormProps) => {
  const { formatMessage } = useIntl();
  const {
    register,
    reset,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<SubscriptionFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (input) => {
    try {
      const { data } = await axios.post("/api/newsletter", {
        email: input.email,
      });

      if (typeof window !== "undefined") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "signup",
          type: "newsletter",
          location: "newsletter form",
        });
      }

      const message = formatMessage(messages[data?.status]);

      showToast(message, data?.status);
      reset({});
    } catch (err) {
      return { status: "error", message: formatMessage(messages.error) };
    }
  };

  return (
    <Box
      as="form"
      display="flex"
      width="100%"
      flexBasis="100%"
      flexDirection="column"
      alignItems={["flex-start", "center"]}
      textAlign={["left", "center"]}
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="500px"
    >
      <Heading as="h3" variant="h3" mb={[2, 3]}>
        {title}
      </Heading>
      <Text variant="large" color="grey200">
        {subtitle}
      </Text>
      <Input
        mt={[3, 4]}
        mb={[2, 3]}
        width="100%"
        placeholder={placeholder}
        error={errors?.email?.message}
        css={css({
          borderRadius: "full",
          borderColor: "grey100",
          color: "grey100",
          "&:hover, &:active, &:focus": {
            borderColor: "white",
          },
        })}
        aria-label="email"
        title="email"
        {...register("email")}
      />
      <LegacyButton
        isLoading={isSubmitting}
        variant="invertedOutline"
        type="submit"
        aria-label="submit button"
      >
        {button}
      </LegacyButton>
    </Box>
  );
};
