import { useEffect, useCallback, useState } from "react";
import { debounce, omit } from "lodash";
import { useRouter } from "next/router";
import qs from "qs";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Input, Button, Container } from "~components";
import { ProductColorsProvider } from "~context";
import { Search as SearchIcon } from "~components/common/Icons";
import { ProductsGrid } from "~components/products/ProductsGrid";
import { ProductTagsQuery, ProductTypesQuery } from "~lib/shopify/sdk";
import { ColorsMappingQuery } from "~lib/storyblok/hooks";
import { FiltersMappingQuery } from "~lib/storyblok/sdk";
import styles from "./Search.module.scss";

interface SearchProps {
  filtersMapping?: FiltersMappingQuery["FiltersItem"]["content"];
  quickLinks?: FiltersMappingQuery["FiltersItem"]["quick_links"];
  tags: ProductTagsQuery["productTags"];
  productTypes: ProductTypesQuery["productTypes"];
  path?: string;
  productColors: ColorsMappingQuery["VariantcolorsItem"];
  content?: any[];
}

const messages = defineMessages({
  searchPlaceholder: "Search for products...",
  clear: "Clear",
});

export const Search = ({
  filtersMapping,
  quickLinks,
  productColors,
  path,
  ...props
}: SearchProps) => {
  const intl = useIntl();
  const { replace, query, isReady } = useRouter();
  const [searchInput, setSearchInput] = useState(
    (query?.search as string) || ""
  );

  useEffect(() => {
    if (isReady && searchInput !== query?.search) {
      setSearchInput(query.search as string);
    }
  }, [query?.search]);

  const updateSearch = (searchTerm: string) => {
    delete query?.after;
    delete query?.before;
    const stringify = !searchTerm
      ? { ...omit(query, ["search"]) }
      : { ...query, search: searchTerm };

    replace(
      {
        ...(path ? { pathname: path } : {}),
        query: qs.stringify(stringify),
      },
      undefined,
      {
        shallow: true,
      }
    );

    if (typeof window === "undefined") return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "search",
      search_term: searchTerm,
    });
  };

  const debouncedUpdateSearch = useCallback(debounce(updateSearch, 1000), [
    replace,
  ]);

  useEffect(() => {
    if (
      query?.search !== searchInput &&
      !(query?.search === undefined && searchInput === "")
    ) {
      debouncedUpdateSearch(searchInput as string);
    }
  }, [searchInput]);

  return (
      <Container>
        <div className={styles.searchWrapper}>
          <Input
            width="100%"
            placeholder={intl.formatMessage(messages.searchPlaceholder)}
            icon={<SearchIcon />}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className={styles.searchInput}
          />
          <Button
            as="button"
            color="unstyled"
            className={styles.searchButton}
            disabled={!searchInput}
            onClick={() => setSearchInput("")}
          >
            <FormattedMessage {...messages.clear} />
          </Button>
        </div>
        {Boolean(query?.search || query?.filters) && (
          <ProductColorsProvider colorMapping={productColors?.content}>
            <ProductsGrid
              {...props}
              filtersMapping={filtersMapping}
              quickLinks={quickLinks}
              withFilters
            />
          </ProductColorsProvider>
        )}
      </Container>
  );
};
