import { storyblokEditable } from "@storyblok/react";
import { LegacyButton, Container, Grid, Image, RichText } from "~components";
import styles from "./TextWithImage.module.scss";
import { ArrowRight } from "~components/common/Icons";
import clsx from "clsx";

type TextWithImageProps = {
  content: any;
};

export const TextWithImage = ({ content }: TextWithImageProps) => {
  const {
    text,
    title,
    image,
    call_to_action: [button],
    layout,
    wide_image,
    background,
    force_aspect_ratio,
  } = content;
  return (
    <Container
      {...storyblokEditable(content as any)}
      className={clsx(styles.container, {
        [styles[background]]: background !== "none",
      })}
    >
      <Grid
        className={clsx(styles.grid, {
          [styles.reverse]: layout === "image-text",
          [styles.wideImage]: wide_image,
        })}
      >
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>{title}</h2>
          <RichText text={text} className={styles.text} color={"grey900"} />
          {button && (
            <LegacyButton
              href={button.link}
              variant="primary"
              className={styles.button}
            >
              {button.text}
              <ArrowRight />
            </LegacyButton>
          )}
        </div>
        <div
          className={clsx(styles.image, {
            [styles.forceAspectRatio]: force_aspect_ratio,
          })}
        >
          <Image
            customLoader="storyblok"
            alt={image.alt}
            src={image.filename}
            layout="fill"
            {...(force_aspect_ratio
              ? { objectFit: "contain", objectPosition: "0% 25%" }
              : { objectFit: "cover" })}
          />
        </div>
      </Grid>
    </Container>
  );
};
