import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { FilterTab } from "~components/molecules";
import { useFilters } from "~hooks/useFilters";
import styles from "./Filter.module.scss";

const messages = defineMessages({
  filter: "Filter",
  resetFilters: "Clear all filters"
})

export type FiltersProps = {
  filters: { [index: string]: any }[]
}

export const Filter = ({ filters }: FiltersProps) => {
  const { onChange, activeFilters, onReset } = useFilters(filters)
  const intl = useIntl()
  const parsedFilters = useMemo(
    () => Object.values(activeFilters).flat(),
    [activeFilters]
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <h1 className={styles.title}>{intl.formatMessage(messages.filter)}</h1>
        {Object.keys(activeFilters).length > 0 && (
          <button onClick={onReset} className={styles.resetButton}>
            {intl.formatMessage(messages.resetFilters)}
          </button>
        )}
      </div>

      <div className={styles.filters}>
        {filters.map((filter, idx) => {
          return (
            <FilterTab
              defaultOpen={filter.defaultOpen}
              value={filter.value}
              title={filter.title}
              type={filter.type}
              parsedFilters={parsedFilters}
              onChange={onChange}
              {...(filter.type === "color" || filter.type === "select"
                ? {
                    options: filter.options
                  }
                : {
                    min: filter.min,
                    max: filter.max,
                    unit: filter.unit
                  })}
              key={idx}
            />
          )
        })}
      </div>
    </div>
  );
};
