import { MoneyV2 } from "../sdk";

export const shopifyToIntlPrice = (moneyV2: MoneyV2) => {
  if (moneyV2?.amount && moneyV2?.currencyCode) {
    return Intl.NumberFormat("nl-NL", {
      currency: moneyV2.currencyCode,
      currencyDisplay: "narrowSymbol",
      style: "currency",
    }).format(moneyV2.amount);
  }
  return "";
};
