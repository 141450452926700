import { NextSeo, NextSeoProps } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";

import { getDescription, getLocalizedUrl } from "~lib";

interface SeoProps extends NextSeoProps {
  story?: any;
  image?: string;
  ogType?: "website" | "article";
}

export const Seo = ({
  story,
  title: titleProp,
  description: descriptionProp,
  image: imageProp,
  ogType,
  ...props
}: SeoProps) => {
  const router = useRouter();
  const { defaultLocale, pathname, locale, locales } = router;

  let title = titleProp;
  let description = descriptionProp;
  let image = { url: imageProp };

  if (story?.content) {
    if (!title) {
      title = story?.content.seo?.title || story?.content.title;
    }
    if (!description) {
      description = getDescription(story?.content);
    }
    if (!imageProp) {
      image = {
        url: story?.content?.seo?.og_image || story?.content?.image?.filename,
      };
    }
  }

  const isHome = locales
    .map((l) => `/${defaultLocale !== l ? `${l}/` : ""}`)
    .includes(pathname);

  if (title && isHome) {
    title = `Philips MyCreation - ${title}`;
  } else {
    title = title
      ? `${title} - Philips MyCreation`
      : "Philips MyCreation - Duurzame lampen voor een duurzaam interieur";
  }

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={getLocalizedUrl(router, locale)}
        openGraph={{
          images: [image],
          type: ogType || "website",
          locale,
          site_name: "Philips MyCreation",
        }}
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: getLocalizedUrl(router, defaultLocale),
          },
          ...locales.map((hrefLang) => ({
            hrefLang,
            href: getLocalizedUrl(router, hrefLang),
          })),
        ]}
        {...props}
      />
      <Head>
        {locales
          ?.filter((l) => l !== defaultLocale)
          ?.map((content) => (
            <meta
              key={content}
              property="og:locale:alternate"
              content={content}
            />
          ))}
      </Head>
    </>
  );
};
