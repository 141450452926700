import { Button, Container, StyledImage, Grid } from "~components";
import storyblokEditable from "~lib/storyblokEditable";
import { defineMessages, useIntl } from "react-intl";
import { useGetHref } from "~lib";
import { InviewLoader } from "~components/common/InviewLoader";
import { useState } from "react";
import styles from "./SpecialEditions.module.scss";

const messages = defineMessages({
  shopTheseCollections: "Shop this collection",
});

export const SpecialEditions = ({ content }) => {
  if (!content.editions.length) {
    return null;
  }
  return (
    <Container
      {...storyblokEditable(content)}
      className={styles.specialEditions}
    >
      <Grid>
        <div className={styles.column}>
          <h2>{content?.title}</h2>
          <InviewLoader>
            <SpecialEdition {...content?.editions?.[0]} />
          </InviewLoader>
        </div>
        <div className={styles.column}>
          <InviewLoader>
            <SpecialEdition {...content?.editions?.[1]} />
          </InviewLoader>
        </div>
      </Grid>
    </Container>
  );
};

const SpecialEdition = ({ image, title, collections }) => {
  const intl = useIntl();
  const [isHovering, setIsHovering] = useState(false);
  const { getHref } = useGetHref();
  return (
    <div className={styles.wrapper}>
      <StyledImage
        src={image.filename}
        customLoader={"storyblok"}
        objectFit="cover"
        layout="fill"
        sizes="(max-width: 768px) 100vw, 50vw"
        isHovering={isHovering}
      />
      <div className={styles.cta}>
        <h3>{title}</h3>
        <Button
          href={getHref(collections?.full_slug)}
          color="invertedOutline"
          as="link"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {intl.formatMessage(messages.shopTheseCollections)}
        </Button>
      </div>
    </div>
  );
};
