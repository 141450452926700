import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import { Box, css, Icon, SystemProps } from "@storyofams/react-ui";
import styled from "styled-components";

import { ChevronDown } from "~components/common/Icons";
import { Link } from "~components/common/Link";
import { useGetHref } from "~lib";

import { NavigationTitle } from "./NavigationTitle";

export const StyledMenuList = styled.div`
  && {
    display: flex;
    gap: 1.2rem;
    flex-flow: column nowrap;
    align-items: flex-start;
    width: 100%;
    background: ${(p) => p.theme.colors.white};
    padding: 0;
    border: none;
    box-sizing: border-box;
    box-shadow: none;

    @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
      padding: 3rem 5.2rem;
    }

    @media (min-width: ${(p) => p.theme.breakpoints.md}) {
      padding: 3rem 2.4rem;
      gap: 0;
      width: 100%;
      flex-direction: row;
      box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.04),
        0rem 0.2rem 0.6rem rgba(0, 0, 0, 0.04),
        0rem 0rem 0.1rem rgba(0, 0, 0, 0.04);
    }
  }

  && > * {
    position: relative;
    display: block;
    width: 100%;
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 1.8rem;
    line-height: ${(p) => p.theme.lineHeights.high};
    color: ${(p) => p.theme.colors.grey900};
    transition: 0.18s color ease-in-out;

    [data-reach-menu-item] {
      padding: 0.8rem 0;
    }

    .subnav {
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }

    .navLink {
      padding-left: 8px;
      padding-right: 8px;

      &:hover {
        background-color: ${(p) => p.theme.colors.primary200};
        color: ${(p) => p.theme.colors.success800};
        border-radius: 0.5rem;
      }
    }

    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
      line-height: ${(p) => p.theme.lineHeights.normal};

      a {
        padding: 0.6rem 0;

        &[data-selected],
        &:hover {
          background-color: ${(p) => p.theme.colors.transparent};
          color: ${(p) => p.theme.colors.primary};
        }

        & .subnav {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: 0rem;
            left: 0;
            width: 100%;
            height: 0.2rem;
            background-color: ${(p) => p.theme.colors.primary};
            border-radius: 0.25rem;
          }
        }

        & .nestedSiblings {
          font-weight: ${(p) => p.theme.fontWeights.bold};
        }
      }
    }
  }
`;

const StyledMenuButton = styled.div`
  && {
    display: none;

    @media (min-width: ${(p) => p.theme.breakpoints.md}) {
      display: block;
    }
  }
`;

interface NavigationDropdownProps extends SystemProps {
  isNested?: boolean;
  text?: string;
  sublinks?: any;
  _uid: string;
}

export const NavigationDropdown = ({
  text,
  sublinks,
}: NavigationDropdownProps) => {
  // Use a reduce function to split the array by whether it has sublinks or not
  // This is used to render the nested menu items
  const { nested, noNested } = sublinks.reduce(
    (acc, curr) => {
      const hasNested = curr.sublinks.length > 0;
      return {
        ...acc,
        nested: hasNested ? [...acc.nested, curr] : acc.nested,
        noNested: hasNested ? acc.noNested : [...acc.noNested, curr],
      };
    },
    { nested: [], noNested: [] }
  );

  const { getHref } = useGetHref();

  return (
    <Box
      width={["100%", "100%", "100%", "auto"]}
      css={css({
        "& > div": {
          top: "8rem",
          left: "23rem",
          right: "23rem",
        },
        "[data-reach-menu-popover][hidden]": {
          display: "flex !important",
        },
        "@media (max-width: 1280px)": {
          "& > div": {
            left: "20rem",
            right: "20rem",
          },
        },
        "@media (min-width: 1024px)": {
          "[data-reach-menu-popover][hidden]": {
            display: "none !important",
          },
        },
      })}
    >
      <Menu>
        {({ isExpanded }) => (
          <>
            <MenuButton as={StyledMenuButton}>
              <NavigationTitle className={isExpanded ? "active" : ""}>
                {text}
                <Icon
                  icon={ChevronDown}
                  fontSize={2.5}
                  mt={["-2px", "-2px", "-3px"]}
                  ml={["8px"]}
                  className={"chevron-down"}
                />
              </NavigationTitle>
            </MenuButton>

            <MenuList portal={false} as={StyledMenuList}>
              {nested.map((value, i) => {
                return (
                  <Box key={i}>
                    {[value, ...value.sublinks].map((subValue, j) => {
                      return (
                        <Link
                          key={subValue?._uid}
                          href={getHref(subValue?.link)}
                          passHref
                        >
                          <MenuItem
                            key={subValue?._uid}
                            onSelect={() => {
                              // do nothing
                            }}
                            className={j === 0 ? "subnav navLink" : "navLink"}
                          >
                            {subValue?.text}
                          </MenuItem>
                        </Link>
                      );
                    })}
                  </Box>
                );
              })}
              {noNested && (
                <Box>
                  {noNested.map((value) => {
                    return (
                      <Link
                        key={value?._uid}
                        href={getHref(value?.link)}
                        passHref
                      >
                        <MenuItem
                          key={value?._uid}
                          onSelect={() => {
                            // do nothing
                          }}
                          className={
                            nested?.length > 0
                              ? "nestedSiblings navLink"
                              : "navLink"
                          }
                        >
                          {value?.text}
                        </MenuItem>
                      </Link>
                    );
                  })}
                </Box>
              )}
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};
