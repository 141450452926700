const siteTitle = "Philips MyCreation";

export const seo = {
  openGraph: {
    type: "website",
    url: "https://www.mycreationshop.nl/",
    site_name: siteTitle,
  },
  twitter: {
    handle: "@Philips:MyCreation",
    cardType: "summary_large_image",
  },
  titleTemplate: process.env.NODE_ENV === "development" ? "DEV: %s" : "%s",
};
