import { Box } from "@storyofams/react-ui";
import styled, { css } from "styled-components";
import { GenericOptionProps } from "./OptionSelector";

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const Label = styled.label`
  cursor: pointer;
`;

export type ColorOptionProps = {
  color: string;
  accent?: string;
  isFilter?: boolean;
  id?: string;
  type: "radio" | "checkbox";
  label?: string;
  count?: number;
} & GenericOptionProps;

export const ColorOption = ({
  value,
  color,
  accent,
  isFilter,
  isSelected,
  onChange: handleChange,
  id,
  type,
  label,
  count,
}: ColorOptionProps) => (
  <>
    <Box
      key={value}
      ml="-4px"
      border="2px"
      borderRadius="full"
      position={"relative"}
      css={css({
        borderColor: isSelected
          ? color === "#ffffff"
            ? "grey300"
            : "grey900"
          : "transparent",
      })}
    >
      <StyledInput
        type={type}
        id={id}
        name={"color"}
        value={value}
        checked={isSelected}
        onChange={handleChange}
      />
      <Box
        border={isFilter ? "0px" : "2px"}
        borderRadius="full"
        css={css({
          borderColor: "transparent",
        })}
        pointerEvents="none"
      >
        <Box
          aria-label={value}
          borderRadius="full"
          borderColor="#000"
          width={isFilter ? "20px" : ["40px", "32px"]}
          height={isFilter ? "20px" : ["40px", "32px"]}
          border={
            isFilter ? "1px solid #ffffff" : color === "#ffffff" ? "2px" : "0px"
          }
          backgroundColor={color}
          overflow={"hidden"}
        >
          {accent && (
            <Box
              border={`${
                color === "#ffffff" ? "14px" : "16px"
              } ${accent} solid`}
              borderBottomColor={"transparent"}
              borderRightColor={"transparent"}
            />
          )}
        </Box>
      </Box>
    </Box>
    {label && (
      <Label htmlFor={id}>
        {label} ({count})
      </Label>
    )}
  </>
);
