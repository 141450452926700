import storyblokEditable from "~lib/storyblokEditable";
import { Image } from "~components";

import { Text } from "~components/common/Text";
import styles from "./ImageGrid.module.scss";
import { useMemo } from "react";
import { Container, Grid } from "~components/atoms";
import ConditionalWrapper from "~lib/conditionalWrapper";
import clsx from "clsx";
import { useImageRatio } from "~lib/useImageRatio";

type ImageGridProps = {
  content: {
    images?: any;
    variant: "left" | "right" | "center" | "fullWidth";
  };
};

export const ImageGrid = ({ content }: ImageGridProps) => {
  const { images, variant } = content;
  const { image: ref, imageRatio: aspectRatio } = useImageRatio();

  const variantClass = useMemo(() => {
    if (images.length === 1 && variant === "fullWidth")
      return styles.oneImageWide;
    if (images.length === 1) return styles.oneImage;
    if (images.length === 2) return styles.twoImages;
    if (variant === "left") return styles.leftVariant;
    if (variant === "right") return styles.rightVariant;
    if (variant === "center") return styles.centerVariant;
  }, []);

  return (
    <div {...storyblokEditable(content as any)}>
      <ConditionalWrapper
        condition={variant !== "fullWidth"}
        wrapper={(children) => {
          return <Container>{children}</Container>;
        }}
      >
        <Grid className={clsx(variantClass, styles.grid)}>
          {images.map(({ image, label }) => (
            <div
              key={image.id}
              className={styles.imageGridImage}
              style={{ aspectRatio: `${aspectRatio}` }}
              ref={(el) => {
                if (images.length === 1) ref.current = el?.querySelector("img");
              }}
            >
              <Image
                src={image.filename}
                customLoader="storyblok"
                layout="fill"
                objectFit="cover"
                sizes={images.length === 1 ? "100vw" : "50vw"}
                style={{ aspectRatio: `${aspectRatio}` }}
              />

              {label && (
                <Text
                  mt={[1.5, 2]}
                  fontWeight="bold"
                  color="grey900"
                  variant="medium"
                >
                  {label}
                </Text>
              )}
            </div>
          ))}
        </Grid>
      </ConditionalWrapper>
    </div>
  );
};
