import { ReactNode } from "react";
import { Flex, Icon, SystemProps } from "@storyofams/react-ui";
import { BreadcrumbJsonLd } from "next-seo";
import styled from "styled-components";

import { LegacyButton } from "../Button";
import { ChevronRight } from "../Icons";
import { Text } from "../Text";

export interface BreadcrumbItem {
  content?: ReactNode;
  link: string;
  name: string;
}

type CustomProps = SystemProps & {
  hideLastItem?: boolean;
  items: BreadcrumbItem[];
  className?: string;
};

const Wrapper = styled(Flex).attrs((attrs) => ({
  color: "grey700",
  ...attrs,
}))`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes[2]}px;
  line-height: 130%;
  border-radius: 99px;
`;

export const Breadcrumb = ({ hideLastItem, items, ...props }: CustomProps) => {
  return (
    <Wrapper {...props}>
      {items.map(({ content, link, name }, idx) => {
        if (hideLastItem && idx === items.length - 1) {
          return null;
        }

        const item =
          idx === items.length - 1 ? (
            <Text
              key={link}
              mr={0.5}
              fontSize={1.5}
              lineHeight="high"
              color={props.color || "grey700"}
              aria-label={content ? name : undefined}
            >
              {content || name}
            </Text>
          ) : (
            <LegacyButton
              variant="link"
              to={link}
              key={link}
              mr={0.5}
              fontWeight="regular"
              color={props.color || "grey700"}
              aria-label={content ? name : undefined}
              letterSpacing="-0.01em"
            >
              {content || name}
            </LegacyButton>
          );

        if (idx < items.length - (hideLastItem ? 2 : 1)) {
          return [
            item,
            <Icon key={`arrow-${idx}`} icon={ChevronRight} mr={0.5} />,
          ];
        }

        return [item];
      })}
      <BreadcrumbJsonLd
        itemListElements={items.map(({ link, name }, idx) => ({
          item: link,
          name,
          position: idx + 1,
        }))}
      />
    </Wrapper>
  );
};
