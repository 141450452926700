import qs from "qs";

export const formatCheckoutUrl = (url: string, locale?: string) => {
  const webUrl = process.env.NEXT_PUBLIC_PRIMARY_DOMAIN
    ? url?.replace(
        process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ORIGIN,
        process.env.NEXT_PUBLIC_PRIMARY_DOMAIN
      )
    : url;

  if (locale) {
    if (webUrl?.split("?")?.length > 1) {
      return `${webUrl}&${qs.stringify({ locale })}`;
    }

    return `${webUrl}?locale=${locale}`;
  }

  return webUrl;
};
