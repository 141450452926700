import { first } from "lodash";
import { useRouter } from "next/router";
import { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { DiscountContext } from "~context/Discounts";

import { handlePromise } from "~lib/handlePromise";
import { getClient } from "~lib/shopify/client";
import {
  CheckoutLineItemInput,
  useCheckoutCreateMutation,
  useCheckoutLineItemsReplaceMutation,
  useCheckoutDiscountCodeApplyV2Mutation,
  useCheckoutQuery,
  CheckoutQuery,
} from "~lib/shopify/hooks";

import { useShopify } from "./index";

const trackRemoveFromCart = (item, customQuantity?: number) => {
  if (typeof window === "undefined") return;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      currency: item.variant.priceV2.currencyCode,
      value: item.variant.priceV2.amount * item.quantity,
      items: [
        {
          item_id: item.variant.sku,
          item_name: item.title,
          item_category: item.variant.product.productType,
          price: item.variant.priceV2.amount,
          quantity: customQuantity || item.quantity,
        },
      ],
    },
  });
};

const trackAddToCart = (
  lineItems: CheckoutLineItemInput[],
  checkoutData,
  pushToDataLayer,
  quantity?: number
) => {
  let addedItem = {
    title: "",
    variant: {
      id: "",
      sku: "",
      priceV2: {
        amount: "",
        currencyCode: "",
      },
    },
  };
  lineItems.forEach(({ variantId }) => {
    const item = checkoutData?.lineItems?.edges?.find(
      ({ node }) => variantId === node.variant.id
    );

    addedItem = item.node;
  });

  if (typeof window !== "undefined" && pushToDataLayer === true) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [
          {
            item_id: addedItem?.variant?.sku,
            item_name: addedItem?.title,
            price: addedItem?.variant?.priceV2?.amount,
            currency: addedItem?.variant?.priceV2?.currencyCode,
            quantity: quantity || lineItems?.[0]?.quantity,
          },
        ],
      },
    });
  }
};

export const useCheckout = () => {
  const router = useRouter();
  const shopifyState = useShopify();
  const queryClient = useQueryClient();
  const client = getClient(router?.locale);
  const { checkout, checkoutId, setCheckoutId, toggleCartModal } = shopifyState;
  const discountContext = useContext(DiscountContext);

  const create = useCheckoutCreateMutation(client, {
    onSuccess: async (_data) => {
      setCheckoutId(_data.checkoutCreate.checkout.id);

      await queryClient.cancelQueries(
        useCheckoutQuery.getKey({
          id: _data.checkoutCreate.checkout.id,
        })
      );

      queryClient.setQueryData<CheckoutQuery>(
        useCheckoutQuery.getKey({
          id: _data.checkoutCreate.checkout.id,
        }),
        { node: _data.checkoutCreate.checkout }
      );
    },
    onError: () => {
      setCheckoutId("");
    },
    onSettled: (_data) => {
      toggleCartModal(true); // show cart overlay

      queryClient.invalidateQueries(
        useCheckoutQuery.getKey({
          id: _data.checkoutCreate.checkout.id,
        })
      );
    },
  });
  const replaceLineItems = useCheckoutLineItemsReplaceMutation(client, {
    onSuccess: async (_data) => {
      // update the queryClient with the response
      await queryClient.setQueryData<CheckoutQuery>(
        useCheckoutQuery.getKey({
          id: _data.checkoutLineItemsReplace.checkout.id,
        }),
        { node: _data.checkoutLineItemsReplace.checkout }
      );
    },
    onSettled: () => {
      // invalidate the queries
      queryClient.invalidateQueries(
        useCheckoutQuery.getKey({
          id: checkoutId,
        })
      );
    },
  });

  const addDiscountMutation = useCheckoutDiscountCodeApplyV2Mutation(client, {
    onSuccess: async (_data) => {
      // update the queryClient with the response
      await queryClient.setQueryData<CheckoutQuery>(
        useCheckoutQuery.getKey({
          id: _data.checkoutDiscountCodeApplyV2.checkout.id,
        }),
        { node: _data.checkoutDiscountCodeApplyV2.checkout }
      );
    },
    onSettled: () => {
      // invalidate the queries
      queryClient.invalidateQueries(
        useCheckoutQuery.getKey({
          id: checkoutId,
        })
      );
    },
  });

  // *************************
  // Checkout actions
  // *************************
  const addOrCreate = async (lineItems: CheckoutLineItemInput[]) => {
    const updatedLineItems: CheckoutLineItemInput[] =
      checkout?.lineItems?.edges?.reduce((acc, { node }) => {
        const changedItem = lineItems.find(
          (input) => input.variantId === node.variant.id
        );
        if (changedItem) {
          return [
            ...acc.filter((e) => e.variantId !== changedItem.variantId),
            {
              variantId: changedItem.variantId,
              quantity: node.quantity + changedItem.quantity,
            },
          ];
        }
        return [
          ...acc,
          { variantId: node.variant.id, quantity: node.quantity },
        ];
      }, lineItems);

    const [data, error] = await handlePromise(
      replaceLineItems.mutateAsync({
        checkoutId,
        lineItems: updatedLineItems || [],
      })
    );

    if (error) {
      if (data?.checkoutLineItemsReplace === undefined) {
        const [{ checkoutCreate }, error] = await handlePromise(
          create.mutateAsync({
            input: {
              lineItems,
            },
          })
        );
        if (error) {
          alert("Something went wrong, please try again");
        } else {
          if (discountContext?.discount?.title) {
            addDiscountCode({
              checkoutId: checkoutCreate.checkout.id,
              discountCode: discountContext.discount.title,
            });
          }
        }
      }
    } else {
      trackAddToCart(lineItems, data?.checkoutLineItemsReplace?.checkout, true);
      toggleCartModal(true);
    }
  };

  const updateQuantity = async (lineItem: CheckoutLineItemInput) => {
    if (!lineItem.quantity) {
      return removeItem(lineItem.variantId);
    }

    const updatedItem = checkout.lineItems.edges.find(
      ({ node }) => node.variant.id === lineItem.variantId
    );

    const [{ checkoutLineItemsReplace = undefined }, error] =
      await handlePromise(
        replaceLineItems.mutateAsync({
          checkoutId,
          lineItems: checkout?.lineItems.edges.map(({ node }) => {
            return node.variant?.id === lineItem.variantId
              ? lineItem
              : { variantId: node.variant.id, quantity: node.quantity };
          }),
        })
      );

    if (lineItem.quantity < updatedItem.node.quantity) {
      trackRemoveFromCart(
        updatedItem.node,
        updatedItem.node.quantity - lineItem.quantity
      );
    }

    if (error) {
      throw new Error(error);
    }

    const updatedQuantity =
      lineItem.quantity -
        checkout?.lineItems?.edges?.find(
          (item) => item?.node?.variant?.id === lineItem?.variantId
        )?.node?.quantity || 0;

    if (updatedQuantity > 0) {
      trackAddToCart(
        [lineItem],
        checkoutLineItemsReplace?.checkout,
        true,
        updatedQuantity
      );
    }
  };

  const removeItem = async (id: string) => {
    const lineItems: CheckoutLineItemInput[] = checkout?.lineItems.edges
      ?.filter(({ node }) => node.variant.id !== id)
      .map(({ node }) => ({
        variantId: node.variant.id,
        quantity: node.quantity,
      }));

    const [{ checkoutLineItemsReplace = undefined }, error] =
      await handlePromise(
        replaceLineItems.mutateAsync({
          checkoutId,
          lineItems: lineItems,
        })
      );

    const removedItem = checkout.lineItems.edges.filter(
      ({ node }) => node.variant.id === id
    );
    trackRemoveFromCart(removedItem[0].node);

    if (error) {
      throw new Error(error);
    }

    trackAddToCart(lineItems, checkoutLineItemsReplace?.checkout, false);
  };

  const addDiscountCode = async ({ discountCode, checkoutId }) => {
    const [data, error] = await handlePromise(
      addDiscountMutation.mutateAsync({
        checkoutId,
        discountCode,
      })
    );

    if (error) {
      throw new Error(error);
    }
    if (data.checkoutDiscountCodeApplyV2.checkoutUserErrors.length) {
      throw first(data.checkoutDiscountCodeApplyV2.checkoutUserErrors);
    }
  };

  return {
    ...shopifyState,
    // actions
    isUpdatingCart: create.isLoading || replaceLineItems.isLoading,
    adjustLineItemQuantity: updateQuantity,
    addItemToCart: addOrCreate,
    removeLineItem: removeItem,
    addDiscountCode,
  };
};
