import React from "react";
import { Box } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import styled from "styled-components";

import { LegacyButton } from "~components/common/Button";
import { Heading } from "~components/common/Heading/heading";
import { Text } from "~components/common/Text";

interface ImageShape {
  filename: string;
  alt: string;
}

export type LatestCollectionSlideProps = {
  title: string;
  subtitle: string;
  image: ImageShape;
  height: any;
};

export const LatestCollectionSlide = ({
  title,
  image,
  subtitle,
  ...props
}: LatestCollectionSlideProps) => {
  return (
    <Container {...props} variant="unstyled" width="100%">
      {!!image && (
        <Box bg="primary600" borderRadius="md" width="100%">
          <Image src={image?.filename} alt={image?.alt} fluid={[290, 275]} />
        </Box>
      )}
      <div>
        <Heading
          variant="h4"
          as="h4"
          mt={2}
          fontWeight="medium"
          color="grey700"
        >
          {title}
        </Heading>
        <Subtitle variant="small" color="grey700">
          {subtitle}
        </Subtitle>
      </div>
    </Container>
  );
};

const Container = styled(LegacyButton)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  border-radius: 8px;

  & .storyblok-image-wrapper {
    height: 275px;
    width: 100%;
    border-radius: 8px;
  }
`;

const Subtitle = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  margin-top: 4px;
`;
