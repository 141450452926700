import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BlockScalar: any;
  ISO8601DateTime: any;
  JsonScalar: any;
};

export type Alternate = {
  __typename?: 'Alternate';
  fullSlug: Scalars['String'];
  id: Scalars['Int'];
  isFolder?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
};

export type Asset = {
  __typename?: 'Asset';
  alt?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  focus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AuthorComponent = {
  __typename?: 'AuthorComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_image?: Maybe<Asset>;
};

export type AuthorFilterQuery = {
  name?: InputMaybe<FilterQueryOperations>;
};

export type AuthorItem = {
  __typename?: 'AuthorItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<AuthorComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type AuthorItems = {
  __typename?: 'AuthorItems';
  items?: Maybe<Array<Maybe<AuthorItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BlogcategoryComponent = {
  __typename?: 'BlogcategoryComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
};

export type BlogcategoryItem = {
  __typename?: 'BlogcategoryItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<BlogcategoryComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type BlogcategoryItems = {
  __typename?: 'BlogcategoryItems';
  items?: Maybe<Array<Maybe<BlogcategoryItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BlogoverviewComponent = {
  __typename?: 'BlogoverviewComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  featured_article?: Maybe<Story>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};


export type BlogoverviewComponentFeatured_ArticleArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};

export type BlogoverviewFilterQuery = {
  featured_article?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type BlogoverviewItem = {
  __typename?: 'BlogoverviewItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<BlogoverviewComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type BlogoverviewItems = {
  __typename?: 'BlogoverviewItems';
  items?: Maybe<Array<Maybe<BlogoverviewItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BlogpostComponent = {
  __typename?: 'BlogpostComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  author?: Maybe<Story>;
  banner?: Maybe<Asset>;
  banner_mobile?: Maybe<Asset>;
  body?: Maybe<Scalars['BlockScalar']>;
  category?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  new_design?: Maybe<Scalars['Boolean']>;
  read_duration?: Maybe<Scalars['String']>;
  related_articles?: Maybe<Array<Maybe<Story>>>;
  seo?: Maybe<Scalars['JsonScalar']>;
  synopsis?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Asset>;
  title?: Maybe<Scalars['String']>;
};


export type BlogpostComponentAuthorArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type BlogpostComponentCategoryArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type BlogpostComponentRelated_ArticlesArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
};

export type BlogpostFilterQuery = {
  author?: InputMaybe<FilterQueryOperations>;
  category?: InputMaybe<FilterQueryOperations>;
  new_design?: InputMaybe<FilterQueryOperations>;
  read_duration?: InputMaybe<FilterQueryOperations>;
  related_articles?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type BlogpostItem = {
  __typename?: 'BlogpostItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<BlogpostComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type BlogpostItems = {
  __typename?: 'BlogpostItems';
  items?: Maybe<Array<Maybe<BlogpostItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CollectionComponent = {
  __typename?: 'CollectionComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  banner_image?: Maybe<Asset>;
  body?: Maybe<Scalars['BlockScalar']>;
  collection?: Maybe<Scalars['JsonScalar']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exclude_from_other_collections?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Asset>;
  seo?: Maybe<Scalars['JsonScalar']>;
  seo_content?: Maybe<Scalars['BlockScalar']>;
  show_filters?: Maybe<Scalars['Boolean']>;
  style?: Maybe<Story>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type CollectionComponentStyleArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};

export type CollectionFilterQuery = {
  exclude_from_other_collections?: InputMaybe<FilterQueryOperations>;
  show_filters?: InputMaybe<FilterQueryOperations>;
  style?: InputMaybe<FilterQueryOperations>;
  subtitle?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type CollectionItem = {
  __typename?: 'CollectionItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CollectionComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CollectionItems = {
  __typename?: 'CollectionItems';
  items?: Maybe<Array<Maybe<CollectionItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CollectionoverviewComponent = {
  __typename?: 'CollectionoverviewComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type CollectionoverviewFilterQuery = {
  title?: InputMaybe<FilterQueryOperations>;
};

export type CollectionoverviewItem = {
  __typename?: 'CollectionoverviewItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CollectionoverviewComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CollectionoverviewItems = {
  __typename?: 'CollectionoverviewItems';
  items?: Maybe<Array<Maybe<CollectionoverviewItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ContentItem = {
  __typename?: 'ContentItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars['JsonScalar']>;
  content_string?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ContentItems = {
  __typename?: 'ContentItems';
  items?: Maybe<Array<Maybe<ContentItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Datasource = {
  __typename?: 'Datasource';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type DatasourceEntries = {
  __typename?: 'DatasourceEntries';
  items: Array<DatasourceEntry>;
  total: Scalars['Int'];
};

export type DatasourceEntry = {
  __typename?: 'DatasourceEntry';
  dimensionValue?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Datasources = {
  __typename?: 'Datasources';
  items: Array<Datasource>;
};

export type FilterQueryOperations = {
  /** Must match all values of given array */
  all_in_array?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than date (Exmples: 2019-03-03 or 2020-03-03T03:03:03) */
  gt_date?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Greater than float value */
  gt_float?: InputMaybe<Scalars['Float']>;
  /** Greater than integer value */
  gt_int?: InputMaybe<Scalars['Int']>;
  /** Matches exactly one value */
  in?: InputMaybe<Scalars['String']>;
  /** Matches any value of given array */
  in_array?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Matches exactly one integer value */
  in_int?: InputMaybe<Scalars['Int']>;
  /** Matches exactly one value with a wildcard search using * */
  like?: InputMaybe<Scalars['String']>;
  /** Less than date (Format: 2019-03-03 or 2020-03-03T03:03:03) */
  lt_date?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Less than float value */
  lt_float?: InputMaybe<Scalars['Float']>;
  /** Less than integer value */
  lt_int?: InputMaybe<Scalars['Int']>;
  /** Matches all without the given value */
  not_in?: InputMaybe<Scalars['String']>;
  /** Matches all without the given value */
  not_like?: InputMaybe<Scalars['String']>;
};

export type FiltersComponent = {
  __typename?: 'FiltersComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  awards?: Maybe<Scalars['BlockScalar']>;
  color?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  quick_links?: Maybe<Scalars['BlockScalar']>;
  range?: Maybe<Scalars['BlockScalar']>;
  style?: Maybe<Scalars['BlockScalar']>;
  type?: Maybe<Scalars['BlockScalar']>;
};

export type FiltersItem = {
  __typename?: 'FiltersItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FiltersComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FiltersItems = {
  __typename?: 'FiltersItems';
  items?: Maybe<Array<Maybe<FiltersItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FooterComponent = {
  __typename?: 'FooterComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  legal_links?: Maybe<Scalars['BlockScalar']>;
  linkedin?: Maybe<Scalars['String']>;
  newsletter_button_label?: Maybe<Scalars['String']>;
  newsletter_input_placeholder?: Maybe<Scalars['String']>;
  newsletter_subtitle?: Maybe<Scalars['String']>;
  newsletter_title?: Maybe<Scalars['String']>;
  payment_options?: Maybe<Array<Maybe<Scalars['String']>>>;
  pinterest?: Maybe<Scalars['String']>;
  social_media_title?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

export type FooterFilterQuery = {
  facebook?: InputMaybe<FilterQueryOperations>;
  instagram?: InputMaybe<FilterQueryOperations>;
  linkedin?: InputMaybe<FilterQueryOperations>;
  newsletter_button_label?: InputMaybe<FilterQueryOperations>;
  newsletter_input_placeholder?: InputMaybe<FilterQueryOperations>;
  newsletter_subtitle?: InputMaybe<FilterQueryOperations>;
  newsletter_title?: InputMaybe<FilterQueryOperations>;
  payment_options?: InputMaybe<FilterQueryOperations>;
  pinterest?: InputMaybe<FilterQueryOperations>;
  social_media_title?: InputMaybe<FilterQueryOperations>;
  twitter?: InputMaybe<FilterQueryOperations>;
  youtube?: InputMaybe<FilterQueryOperations>;
};

export type FooterItem = {
  __typename?: 'FooterItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FooterComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FooterItems = {
  __typename?: 'FooterItems';
  items?: Maybe<Array<Maybe<FooterItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type HomeComponent = {
  __typename?: 'HomeComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
};

export type HomeItem = {
  __typename?: 'HomeItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<HomeComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type HomeItems = {
  __typename?: 'HomeItems';
  items?: Maybe<Array<Maybe<HomeItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type LinkEntries = {
  __typename?: 'LinkEntries';
  items: Array<LinkEntry>;
};

export type LinkEntry = {
  __typename?: 'LinkEntry';
  id?: Maybe<Scalars['Int']>;
  isFolder?: Maybe<Scalars['Boolean']>;
  isStartpage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type NavigationComponent = {
  __typename?: 'NavigationComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  links?: Maybe<Scalars['BlockScalar']>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<NavigationComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type NavigationItems = {
  __typename?: 'NavigationItems';
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PageComponent = {
  __typename?: 'PageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  synopsis?: Maybe<Scalars['String']>;
  thumbnail_image?: Maybe<Asset>;
  title?: Maybe<Scalars['String']>;
};

export type PageFilterQuery = {
  title?: InputMaybe<FilterQueryOperations>;
};

export type PageItem = {
  __typename?: 'PageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<PageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type PageItems = {
  __typename?: 'PageItems';
  items?: Maybe<Array<Maybe<PageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PlpComponent = {
  __typename?: 'PlpComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  header_image?: Maybe<Asset>;
  pendant_filter_text?: Maybe<Scalars['String']>;
  pendant_filter_title?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  table_filter_text?: Maybe<Scalars['String']>;
  table_filter_title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PlpFilterQuery = {
  pendant_filter_text?: InputMaybe<FilterQueryOperations>;
  pendant_filter_title?: InputMaybe<FilterQueryOperations>;
  table_filter_text?: InputMaybe<FilterQueryOperations>;
  table_filter_title?: InputMaybe<FilterQueryOperations>;
  text?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type PlpItem = {
  __typename?: 'PlpItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<PlpComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type PlpItems = {
  __typename?: 'PlpItems';
  items?: Maybe<Array<Maybe<PlpItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductComponent = {
  __typename?: 'ProductComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['JsonScalar']>;
  product_contents?: Maybe<Scalars['BlockScalar']>;
  product_faq?: Maybe<Scalars['BlockScalar']>;
  product_setup?: Maybe<Scalars['BlockScalar']>;
};

export type ProductItem = {
  __typename?: 'ProductItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductItems = {
  __typename?: 'ProductItems';
  items?: Maybe<Array<Maybe<ProductItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type QueryType = {
  __typename?: 'QueryType';
  AuthorItem?: Maybe<AuthorItem>;
  AuthorItems?: Maybe<AuthorItems>;
  BlogcategoryItem?: Maybe<BlogcategoryItem>;
  BlogcategoryItems?: Maybe<BlogcategoryItems>;
  BlogoverviewItem?: Maybe<BlogoverviewItem>;
  BlogoverviewItems?: Maybe<BlogoverviewItems>;
  BlogpostItem?: Maybe<BlogpostItem>;
  BlogpostItems?: Maybe<BlogpostItems>;
  CollectionItem?: Maybe<CollectionItem>;
  CollectionItems?: Maybe<CollectionItems>;
  CollectionoverviewItem?: Maybe<CollectionoverviewItem>;
  CollectionoverviewItems?: Maybe<CollectionoverviewItems>;
  ContentNode?: Maybe<ContentItem>;
  ContentNodes?: Maybe<ContentItems>;
  DatasourceEntries?: Maybe<DatasourceEntries>;
  Datasources?: Maybe<Datasources>;
  FiltersItem?: Maybe<FiltersItem>;
  FiltersItems?: Maybe<FiltersItems>;
  FooterItem?: Maybe<FooterItem>;
  FooterItems?: Maybe<FooterItems>;
  HomeItem?: Maybe<HomeItem>;
  HomeItems?: Maybe<HomeItems>;
  Links?: Maybe<LinkEntries>;
  NavigationItem?: Maybe<NavigationItem>;
  NavigationItems?: Maybe<NavigationItems>;
  PageItem?: Maybe<PageItem>;
  PageItems?: Maybe<PageItems>;
  PlpItem?: Maybe<PlpItem>;
  PlpItems?: Maybe<PlpItems>;
  ProductItem?: Maybe<ProductItem>;
  ProductItems?: Maybe<ProductItems>;
  RateLimit?: Maybe<RateLimit>;
  SearchItem?: Maybe<SearchItem>;
  SearchItems?: Maybe<SearchItems>;
  Space?: Maybe<Space>;
  StyleItem?: Maybe<StyleItem>;
  StyleItems?: Maybe<StyleItems>;
  Tags?: Maybe<Tags>;
  TermsandconditionsItem?: Maybe<TermsandconditionsItem>;
  TermsandconditionsItems?: Maybe<TermsandconditionsItems>;
  UspsItem?: Maybe<UspsItem>;
  UspsItems?: Maybe<UspsItems>;
  VariantcolorsItem?: Maybe<VariantcolorsItem>;
  VariantcolorsItems?: Maybe<VariantcolorsItems>;
};


export type QueryTypeAuthorItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeAuthorItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<AuthorFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeBlogcategoryItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeBlogcategoryItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeBlogoverviewItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeBlogoverviewItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<BlogoverviewFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeBlogpostItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeBlogpostItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<BlogpostFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCollectionItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCollectionItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<CollectionFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCollectionoverviewItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCollectionoverviewItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<CollectionoverviewFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeContentNodeArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeContentNodesArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeDatasourceEntriesArgs = {
  datasource?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
};


export type QueryTypeDatasourcesArgs = {
  by_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFiltersItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFiltersItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFooterItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFooterItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<FooterFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeHomeItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeHomeItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeLinksArgs = {
  paginated?: InputMaybe<Scalars['Boolean']>;
  starts_with?: InputMaybe<Scalars['String']>;
};


export type QueryTypeNavigationItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeNavigationItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypePageItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypePageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<PageFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypePlpItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypePlpItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<PlpFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeSearchItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeSearchItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeStyleItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeStyleItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<StyleFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeTagsArgs = {
  starts_with?: InputMaybe<Scalars['String']>;
};


export type QueryTypeTermsandconditionsItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeTermsandconditionsItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeUspsItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeUspsItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeVariantcolorsItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeVariantcolorsItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};

export type RateLimit = {
  __typename?: 'RateLimit';
  maxCost: Scalars['Int'];
};

export type SearchComponent = {
  __typename?: 'SearchComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
};

export type SearchItem = {
  __typename?: 'SearchItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<SearchComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type SearchItems = {
  __typename?: 'SearchItems';
  items?: Maybe<Array<Maybe<SearchItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Space = {
  __typename?: 'Space';
  domain: Scalars['String'];
  id: Scalars['Int'];
  languageCodes: Array<Maybe<Scalars['String']>>;
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type Story = {
  __typename?: 'Story';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars['JsonScalar']>;
  createdAt?: Maybe<Scalars['String']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  fullSlug?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isStartpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  metaData?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['String']>;
  releaseId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sortByDate?: Maybe<Scalars['String']>;
  tagList?: Maybe<Array<Maybe<Scalars['String']>>>;
  translatedSlugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type StyleComponent = {
  __typename?: 'StyleComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  banner_image?: Maybe<Asset>;
  body?: Maybe<Scalars['BlockScalar']>;
  collection?: Maybe<Scalars['JsonScalar']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  info_text?: Maybe<Scalars['JsonScalar']>;
  info_title?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  seo_content?: Maybe<Scalars['BlockScalar']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type StyleFilterQuery = {
  description?: InputMaybe<FilterQueryOperations>;
  info_title?: InputMaybe<FilterQueryOperations>;
  subtitle?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type StyleItem = {
  __typename?: 'StyleItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<StyleComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type StyleItems = {
  __typename?: 'StyleItems';
  items?: Maybe<Array<Maybe<StyleItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  taggingsCount: Scalars['Int'];
};

export type Tags = {
  __typename?: 'Tags';
  items: Array<Tag>;
};

export type TermsandconditionsComponent = {
  __typename?: 'TermsandconditionsComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['JsonScalar']>;
};

export type TermsandconditionsItem = {
  __typename?: 'TermsandconditionsItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<TermsandconditionsComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type TermsandconditionsItems = {
  __typename?: 'TermsandconditionsItems';
  items?: Maybe<Array<Maybe<TermsandconditionsItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TranslatedSlug = {
  __typename?: 'TranslatedSlug';
  lang: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type UspsComponent = {
  __typename?: 'UspsComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  items?: Maybe<Scalars['BlockScalar']>;
};

export type UspsItem = {
  __typename?: 'UspsItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<UspsComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type UspsItems = {
  __typename?: 'UspsItems';
  items?: Maybe<Array<Maybe<UspsItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type VariantcolorsComponent = {
  __typename?: 'VariantcolorsComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  accent_colors?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  variant_colors?: Maybe<Scalars['BlockScalar']>;
};

export type VariantcolorsItem = {
  __typename?: 'VariantcolorsItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<VariantcolorsComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type VariantcolorsItems = {
  __typename?: 'VariantcolorsItems';
  items?: Maybe<Array<Maybe<VariantcolorsItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type BlogFragmentFragment = { __typename?: 'BlogpostComponent', body?: any | null, title?: string | null, synopsis?: string | null, read_duration?: string | null, new_design?: boolean | null, thumbnail?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, banner?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, banner_mobile?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, author?: { __typename?: 'Story', content?: any | null } | null, category?: { __typename?: 'Story', name?: string | null, slug?: string | null } | null };

export type CollectionFragmentFragment = { __typename?: 'CollectionComponent', collection?: any | null, title?: string | null, exclude_from_other_collections?: boolean | null, image?: { __typename?: 'Asset', filename: string, alt?: string | null } | null };

export type ImageFragmentFragment = { __typename?: 'Asset', alt?: string | null, filename: string };

export type BlogCategoryItemsQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type BlogCategoryItemsQuery = { __typename?: 'QueryType', BlogcategoryItems?: { __typename?: 'BlogcategoryItems', total?: number | null, items?: Array<{ __typename?: 'BlogcategoryItem', name?: string | null, uuid?: string | null, slug?: string | null } | null> | null } | null };

export type BlogOverviewQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
}>;


export type BlogOverviewQuery = { __typename?: 'QueryType', BlogoverviewItem?: { __typename?: 'BlogoverviewItem', slug?: string | null, uuid?: string | null, content?: { __typename?: 'BlogoverviewComponent', title?: string | null, _editable?: string | null, seo?: any | null, featured_article?: { __typename?: 'Story', name?: string | null, parentId?: number | null, content?: any | null, slug?: string | null, publishedAt?: string | null, path?: string | null, fullSlug?: string | null } | null } | null } | null };

export type BlogpostItemQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
}>;


export type BlogpostItemQuery = { __typename?: 'QueryType', BlogpostItem?: { __typename?: 'BlogpostItem', full_slug?: string | null, published_at?: string | null, uuid?: string | null, content?: { __typename?: 'BlogpostComponent', seo?: any | null, body?: any | null, title?: string | null, synopsis?: string | null, read_duration?: string | null, new_design?: boolean | null, related_articles?: Array<{ __typename?: 'Story', name?: string | null, id?: number | null, slug?: string | null, fullSlug?: string | null, uuid?: string | null, path?: string | null, content?: any | null, publishedAt?: string | null } | null> | null, thumbnail?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, banner?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, banner_mobile?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, author?: { __typename?: 'Story', content?: any | null } | null, category?: { __typename?: 'Story', name?: string | null, slug?: string | null } | null } | null } | null };

export type BlogpostItemsQueryVariables = Exact<{
  starts_with?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  per_page?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
}>;


export type BlogpostItemsQuery = { __typename?: 'QueryType', BlogpostItems?: { __typename?: 'BlogpostItems', total?: number | null, items?: Array<{ __typename?: 'BlogpostItem', name?: string | null, id?: number | null, slug?: string | null, full_slug?: string | null, uuid?: string | null, path?: string | null, published_at?: string | null, content?: { __typename?: 'BlogpostComponent', body?: any | null, title?: string | null, synopsis?: string | null, read_duration?: string | null, new_design?: boolean | null, related_articles?: Array<{ __typename?: 'Story', name?: string | null, id?: number | null, slug?: string | null, fullSlug?: string | null, uuid?: string | null, path?: string | null, content?: any | null, publishedAt?: string | null } | null> | null, thumbnail?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, banner?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, banner_mobile?: { __typename?: 'Asset', filename: string, alt?: string | null } | null, author?: { __typename?: 'Story', content?: any | null } | null, category?: { __typename?: 'Story', name?: string | null, slug?: string | null } | null } | null } | null> | null } | null };

export type CollectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CollectionQuery = { __typename?: 'QueryType', CollectionItem?: { __typename?: 'CollectionItem', uuid?: string | null, content?: { __typename?: 'CollectionComponent', title?: string | null, subtitle?: string | null, description?: string | null, show_filters?: boolean | null, body?: any | null, collection?: any | null, seo_content?: any | null, _editable?: string | null, seo?: any | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null, banner_image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null, style?: { __typename?: 'Story', name?: string | null, slug?: string | null } | null } | null } | null };

export type CollectionByShopifyIdQueryVariables = Exact<{
  id: Scalars['String'];
  language: Scalars['String'];
  locale: Scalars['String'];
}>;


export type CollectionByShopifyIdQuery = { __typename?: 'QueryType', CollectionItems?: { __typename?: 'CollectionItems', items?: Array<{ __typename?: 'CollectionItem', slug?: string | null, uuid?: string | null, content?: { __typename?: 'CollectionComponent', title?: string | null, _editable?: string | null, style?: { __typename?: 'Story', content?: any | null, name?: string | null, slug?: string | null } | null } | null } | null> | null } | null };

export type CollectionOverviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CollectionOverviewQuery = { __typename?: 'QueryType', CollectionoverviewItem?: { __typename?: 'CollectionoverviewItem', slug?: string | null, uuid?: string | null, content?: { __typename?: 'CollectionoverviewComponent', title?: string | null, description?: string | null, _editable?: string | null } | null } | null };

export type CollectionSlideQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
}>;


export type CollectionSlideQuery = { __typename?: 'QueryType', CollectionItem?: { __typename?: 'CollectionItem', slug?: string | null, uuid?: string | null, content?: { __typename?: 'CollectionComponent', title?: string | null, _editable?: string | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null, style?: { __typename?: 'Story', content?: any | null } | null } | null } | null };

export type CollectionsQueryVariables = Exact<{
  prefix?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
}>;


export type CollectionsQuery = { __typename?: 'QueryType', CollectionItems?: { __typename?: 'CollectionItems', total?: number | null, items?: Array<{ __typename?: 'CollectionItem', published_at?: string | null, slug?: string | null, full_slug?: string | null, uuid?: string | null, content?: { __typename?: 'CollectionComponent', collection?: any | null, title?: string | null, exclude_from_other_collections?: boolean | null, style?: { __typename?: 'Story', content?: any | null, slug?: string | null } | null, image?: { __typename?: 'Asset', filename: string, alt?: string | null } | null } | null } | null> | null } | null };

export type CollectionsByStyleQueryVariables = Exact<{
  style: Scalars['String'];
  prefix?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
}>;


export type CollectionsByStyleQuery = { __typename?: 'QueryType', CollectionItems?: { __typename?: 'CollectionItems', total?: number | null, items?: Array<{ __typename?: 'CollectionItem', slug?: string | null, uuid?: string | null, content?: { __typename?: 'CollectionComponent', collection?: any | null, title?: string | null, exclude_from_other_collections?: boolean | null, style?: { __typename?: 'Story', content?: any | null } | null, image?: { __typename?: 'Asset', filename: string, alt?: string | null } | null } | null } | null> | null } | null };

export type ColorsMappingQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ColorsMappingQuery = { __typename?: 'QueryType', VariantcolorsItem?: { __typename?: 'VariantcolorsItem', uuid?: string | null, content?: { __typename?: 'VariantcolorsComponent', variant_colors?: any | null, accent_colors?: any | null } | null } | null };

export type FiltersMappingQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type FiltersMappingQuery = { __typename?: 'QueryType', FiltersItem?: { __typename?: 'FiltersItem', uuid?: string | null, content?: { __typename?: 'FiltersComponent', style?: any | null, range?: any | null, color?: any | null, type?: any | null, awards?: any | null } | null, quick_links?: { __typename?: 'FiltersComponent', quick_links?: any | null } | null } | null };

export type FooterItemQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type FooterItemQuery = { __typename?: 'QueryType', FooterItem?: { __typename?: 'FooterItem', uuid?: string | null, content?: { __typename?: 'FooterComponent', columns?: any | null, newsletter_title?: string | null, newsletter_subtitle?: string | null, newsletter_button_label?: string | null, newsletter_input_placeholder?: string | null, component?: string | null, payment_options?: Array<string | null> | null, legal_links?: any | null, facebook?: string | null, instagram?: string | null, pinterest?: string | null, twitter?: string | null, youtube?: string | null, linkedin?: string | null, social_media_title?: string | null, _editable?: string | null } | null } | null };

export type HomeQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type HomeQuery = { __typename?: 'QueryType', HomeItem?: { __typename?: 'HomeItem', uuid?: string | null, content?: { __typename?: 'HomeComponent', body?: any | null, seo?: any | null, _editable?: string | null } | null } | null, BlogcategoryItems?: { __typename?: 'BlogcategoryItems', items?: Array<{ __typename?: 'BlogcategoryItem', name?: string | null, uuid?: string | null } | null> | null } | null };

export type NavigationItemQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type NavigationItemQuery = { __typename?: 'QueryType', NavigationItem?: { __typename?: 'NavigationItem', uuid?: string | null, content?: { __typename?: 'NavigationComponent', component?: string | null, _editable?: string | null, links?: any | null } | null } | null };

export type PageByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PageByIdQuery = { __typename?: 'QueryType', PageItem?: { __typename?: 'PageItem', uuid?: string | null, content?: { __typename?: 'PageComponent', seo?: any | null, body?: any | null, title?: string | null, _editable?: string | null } | null } | null };

export type PagesQueryVariables = Exact<{ [key: string]: never; }>;


export type PagesQuery = { __typename?: 'QueryType', PageItems?: { __typename?: 'PageItems', items?: Array<{ __typename?: 'PageItem', slug?: string | null, published_at?: string | null, uuid?: string | null } | null> | null } | null };

export type ProductQueryVariables = Exact<{
  id: Scalars['String'];
  language: Scalars['String'];
}>;


export type ProductQuery = { __typename?: 'QueryType', ProductItems?: { __typename?: 'ProductItems', items?: Array<{ __typename?: 'ProductItem', uuid?: string | null, content?: { __typename?: 'ProductComponent', product_contents?: any | null, product_faq?: any | null, product_setup?: any | null, body?: any | null, _editable?: string | null } | null } | null> | null } | null };

export type ProductByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductByIdQuery = { __typename?: 'QueryType', ProductItem?: { __typename?: 'ProductItem', uuid?: string | null, content?: { __typename?: 'ProductComponent', body?: any | null, product?: any | null, product_contents?: any | null, product_faq?: any | null, product_setup?: any | null, _editable?: string | null } | null } | null };

export type ShopAllQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShopAllQuery = { __typename?: 'QueryType', PlpItem?: { __typename?: 'PlpItem', uuid?: string | null, content?: { __typename?: 'PlpComponent', title?: string | null, text?: string | null, pendant_filter_title?: string | null, pendant_filter_text?: string | null, table_filter_title?: string | null, table_filter_text?: string | null, _editable?: string | null, seo?: any | null, header_image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null } | null } | null };

export type RoomQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RoomQuery = { __typename?: 'QueryType', CollectionItem?: { __typename?: 'CollectionItem', id?: number | null, uuid?: string | null, content?: { __typename?: 'CollectionComponent', title?: string | null, subtitle?: string | null, description?: string | null, show_filters?: boolean | null, body?: any | null, collection?: any | null, seo_content?: any | null, _editable?: string | null, seo?: any | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null, banner_image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null, style?: { __typename?: 'Story', name?: string | null, slug?: string | null } | null } | null } | null };

export type RoomOverviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RoomOverviewQuery = { __typename?: 'QueryType', CollectionoverviewItem?: { __typename?: 'CollectionoverviewItem', slug?: string | null, uuid?: string | null, content?: { __typename?: 'CollectionoverviewComponent', title?: string | null, description?: string | null, seo?: any | null, _editable?: string | null } | null } | null };

export type RoomsQueryVariables = Exact<{
  prefix?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
}>;


export type RoomsQuery = { __typename?: 'QueryType', CollectionItems?: { __typename?: 'CollectionItems', total?: number | null, items?: Array<{ __typename?: 'CollectionItem', published_at?: string | null, slug?: string | null, full_slug?: string | null, uuid?: string | null, content?: { __typename?: 'CollectionComponent', collection?: any | null, title?: string | null, exclude_from_other_collections?: boolean | null, style?: { __typename?: 'Story', content?: any | null, slug?: string | null } | null, image?: { __typename?: 'Asset', filename: string, alt?: string | null } | null } | null } | null> | null } | null };

export type SearchQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type SearchQuery = { __typename?: 'QueryType', PageItem?: { __typename?: 'PageItem', id?: number | null, uuid?: string | null, name?: string | null, default_full_slug?: string | null, content?: { __typename?: 'PageComponent', title?: string | null, _uid?: string | null, _editable?: string | null } | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, path?: string | null } | null> | null } | null };

export type SearchPageQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type SearchPageQuery = { __typename?: 'QueryType', SearchItem?: { __typename?: 'SearchItem', uuid?: string | null, content?: { __typename?: 'SearchComponent', body?: any | null } | null } | null };

export type StyleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StyleQuery = { __typename?: 'QueryType', StyleItem?: { __typename?: 'StyleItem', id?: number | null, slug?: string | null, uuid?: string | null, content?: { __typename?: 'StyleComponent', title?: string | null, subtitle?: string | null, description?: string | null, body?: any | null, info_title?: string | null, info_text?: any | null, _editable?: string | null, collection?: any | null, seo_content?: any | null, seo?: any | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null, banner_image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null } | null } | null };

export type StyleByShopifyNameQueryVariables = Exact<{
  id: Scalars['String'];
  language: Scalars['String'];
}>;


export type StyleByShopifyNameQuery = { __typename?: 'QueryType', StyleItems?: { __typename?: 'StyleItems', items?: Array<{ __typename?: 'StyleItem', slug?: string | null, full_slug?: string | null, uuid?: string | null, content?: { __typename?: 'StyleComponent', title?: string | null, _editable?: string | null, info_title?: string | null, info_text?: any | null, collection?: any | null, seo?: any | null, body?: any | null, _uid?: string | null, image?: { __typename?: 'Asset', filename: string, alt?: string | null, focus?: string | null } | null } | null } | null> | null } | null };

export type StylesQueryVariables = Exact<{
  prefix?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
}>;


export type StylesQuery = { __typename?: 'QueryType', StyleItems?: { __typename?: 'StyleItems', total?: number | null, items?: Array<{ __typename?: 'StyleItem', published_at?: string | null, slug?: string | null, full_slug?: string | null, uuid?: string | null, content?: { __typename?: 'StyleComponent', title?: string | null, image?: { __typename?: 'Asset', filename: string, alt?: string | null } | null } | null } | null> | null } | null };

export type TermsAndConditionsItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsAndConditionsItemsQuery = { __typename?: 'QueryType', TermsandconditionsItems?: { __typename?: 'TermsandconditionsItems', items?: Array<{ __typename?: 'TermsandconditionsItem', content?: { __typename?: 'TermsandconditionsComponent', termsAndConditions?: any | null, _uid?: string | null, _editable?: string | null } | null } | null> | null } | null };

export type UspsQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type UspsQuery = { __typename?: 'QueryType', UspsItem?: { __typename?: 'UspsItem', content?: { __typename?: 'UspsComponent', items?: any | null, component?: string | null, _editable?: string | null } | null } | null };

export const BlogFragmentFragmentDoc = gql`
    fragment BlogFragment on BlogpostComponent {
  body
  title
  synopsis
  read_duration
  thumbnail {
    filename
    alt
  }
  banner {
    filename
    alt
  }
  banner_mobile {
    filename
    alt
  }
  author {
    content
  }
  category {
    name
    slug
  }
  new_design
}
    `;
export const CollectionFragmentFragmentDoc = gql`
    fragment CollectionFragment on CollectionComponent {
  collection
  image {
    filename
    alt
  }
  title
  exclude_from_other_collections
}
    `;
export const ImageFragmentFragmentDoc = gql`
    fragment ImageFragment on Asset {
  alt
  filename
}
    `;
export const BlogCategoryItemsDocument = gql`
    query BlogCategoryItems($lang: String = "default") {
  BlogcategoryItems(starts_with: $lang) {
    items {
      name
      uuid
      slug
    }
    total
  }
}
    `;
export const BlogOverviewDocument = gql`
    query blogOverview($id: ID!, $lang: String = "default") {
  BlogoverviewItem(id: $id) {
    content {
      title
      _editable
      featured_article(language: $lang) {
        name
        parentId
        content
        slug
        publishedAt
        path
        fullSlug
      }
      seo
    }
    slug
    uuid
  }
}
    `;
export const BlogpostItemDocument = gql`
    query BlogpostItem($id: ID!, $lang: String = "default") {
  BlogpostItem(
    id: $id
    resolve_relations: "pages.pages,blog-posts.pages,special-editions.collections,our-collections.collections,collections.style,our-rooms.rooms"
  ) {
    content {
      ...BlogFragment
      related_articles(language: $lang) {
        name
        id
        slug
        fullSlug
        uuid
        path
        content
        publishedAt
      }
      seo
    }
    full_slug
    published_at
    uuid
  }
}
    ${BlogFragmentFragmentDoc}`;
export const BlogpostItemsDocument = gql`
    query BlogpostItems($starts_with: String, $excluding_slugs: String, $per_page: Int, $sort_by: String, $lang: String = "default") {
  BlogpostItems(
    starts_with: $starts_with
    excluding_slugs: $excluding_slugs
    per_page: $per_page
    sort_by: $sort_by
  ) {
    items {
      name
      id
      slug
      full_slug
      uuid
      path
      content {
        ...BlogFragment
        related_articles(language: $lang) {
          name
          id
          slug
          fullSlug
          uuid
          path
          content
          publishedAt
        }
      }
      published_at
    }
    total
  }
}
    ${BlogFragmentFragmentDoc}`;
export const CollectionDocument = gql`
    query collection($id: ID!) {
  CollectionItem(id: $id, resolve_relations: "blog-posts.pages") {
    content {
      title
      image {
        ...ImageFragment
      }
      banner_image {
        ...ImageFragment
      }
      subtitle
      description
      style {
        name
        slug
      }
      show_filters
      body
      collection
      seo_content
      _editable
      seo
    }
    uuid
  }
}
    ${ImageFragmentFragmentDoc}`;
export const CollectionByShopifyIdDocument = gql`
    query collectionByShopifyId($id: String!, $language: String!, $locale: String!) {
  CollectionItems(search_term: $id, starts_with: $language, per_page: 1) {
    items {
      content {
        title
        style(language: $locale) {
          content
          name
          slug
        }
        _editable
      }
      slug
      uuid
    }
  }
}
    `;
export const CollectionOverviewDocument = gql`
    query collectionOverview($id: ID!) {
  CollectionoverviewItem(id: $id) {
    content {
      title
      description
      _editable
    }
    slug
    uuid
  }
}
    `;
export const CollectionSlideDocument = gql`
    query collectionSlide($id: ID!, $lang: String = "default") {
  CollectionItem(id: $id, find_by: "uuid", language: $lang) {
    content {
      title
      image {
        ...ImageFragment
      }
      style(language: $lang) {
        content
      }
      _editable
    }
    slug
    uuid
  }
}
    ${ImageFragmentFragmentDoc}`;
export const CollectionsDocument = gql`
    query collections($prefix: String = "", $lang: String = "default", $excluding_ids: String = "") {
  CollectionItems(starts_with: $prefix, excluding_ids: $excluding_ids) {
    total
    items {
      content {
        ...CollectionFragment
        style(language: $lang) {
          content
          slug
        }
      }
      published_at
      slug
      full_slug
      uuid
    }
  }
}
    ${CollectionFragmentFragmentDoc}`;
export const CollectionsByStyleDocument = gql`
    query collectionsByStyle($style: String!, $prefix: String = "", $lang: String = "default") {
  CollectionItems(
    filter_query_v2: {style: {in: $style}}
    starts_with: $prefix
    language: $lang
  ) {
    total
    items {
      content {
        ...CollectionFragment
        style(language: $lang) {
          content
        }
      }
      slug
      uuid
    }
  }
}
    ${CollectionFragmentFragmentDoc}`;
export const ColorsMappingDocument = gql`
    query colorsMapping($slug: ID!) {
  VariantcolorsItem(id: $slug) {
    content {
      variant_colors
      accent_colors
    }
    uuid
  }
}
    `;
export const FiltersMappingDocument = gql`
    query filtersMapping($slug: ID!) {
  FiltersItem(id: $slug) {
    content {
      style
      range
      color
      type
      awards
    }
    quick_links: content {
      quick_links
    }
    uuid
  }
}
    `;
export const FooterItemDocument = gql`
    query footerItem($slug: ID!) {
  FooterItem(id: $slug, resolve_links: "url") {
    uuid
    content {
      columns
      newsletter_title
      newsletter_subtitle
      newsletter_button_label
      newsletter_input_placeholder
      component
      payment_options
      legal_links
      facebook
      instagram
      pinterest
      twitter
      youtube
      linkedin
      social_media_title
      _editable
    }
  }
}
    `;
export const HomeDocument = gql`
    query home($slug: ID!) {
  HomeItem(
    id: $slug
    resolve_links: "url"
    resolve_relations: "pages.pages,blog-posts.pages,special-editions.collections,our-collections.collections,collections.style,our-rooms.rooms"
  ) {
    content {
      body
      seo
      _editable
    }
    uuid
  }
  BlogcategoryItems {
    items {
      name
      uuid
    }
  }
}
    `;
export const NavigationItemDocument = gql`
    query navigationItem($slug: ID!) {
  NavigationItem(id: $slug, resolve_links: "url") {
    uuid
    content {
      component
      _editable
      links
    }
  }
}
    `;
export const PageByIdDocument = gql`
    query pageById($id: ID!) {
  PageItem(
    id: $id
    resolve_links: "url"
    resolve_relations: "pages.pages,blog-posts.pages,special-editions.collections,our-collections.collections,collections.style,our-rooms.rooms"
  ) {
    content {
      seo
      body
      title
      _editable
    }
    uuid
  }
}
    `;
export const PagesDocument = gql`
    query pages {
  PageItems {
    items {
      slug
      published_at
      uuid
    }
  }
}
    `;
export const ProductDocument = gql`
    query product($id: String!, $language: String!) {
  ProductItems(search_term: $id, starts_with: $language, per_page: 1) {
    items {
      content {
        product_contents
        product_faq
        product_setup
        body
        _editable
      }
      uuid
    }
  }
}
    `;
export const ProductByIdDocument = gql`
    query productById($id: ID!) {
  ProductItem(id: $id) {
    content {
      body
      product
      product_contents
      product_faq
      product_setup
      _editable
    }
    uuid
  }
}
    `;
export const ShopAllDocument = gql`
    query shopAll($id: ID!) {
  PlpItem(id: $id) {
    content {
      title
      text
      pendant_filter_title
      pendant_filter_text
      table_filter_title
      table_filter_text
      header_image {
        ...ImageFragment
      }
      _editable
      seo
    }
    uuid
  }
}
    ${ImageFragmentFragmentDoc}`;
export const RoomDocument = gql`
    query room($id: ID!) {
  CollectionItem(id: $id) {
    content {
      title
      image {
        ...ImageFragment
      }
      banner_image {
        ...ImageFragment
      }
      subtitle
      description
      style {
        name
        slug
      }
      show_filters
      body
      collection
      seo_content
      _editable
      seo
    }
    id
    uuid
  }
}
    ${ImageFragmentFragmentDoc}`;
export const RoomOverviewDocument = gql`
    query roomOverview($id: ID!) {
  CollectionoverviewItem(id: $id) {
    content {
      title
      description
      seo
      _editable
    }
    slug
    uuid
  }
}
    `;
export const RoomsDocument = gql`
    query rooms($prefix: String = "", $lang: String = "default", $excluding_ids: String = "") {
  CollectionItems(starts_with: $prefix, excluding_ids: $excluding_ids) {
    total
    items {
      content {
        ...CollectionFragment
        style(language: $lang) {
          content
          slug
        }
      }
      published_at
      slug
      full_slug
      uuid
    }
  }
}
    ${CollectionFragmentFragmentDoc}`;
export const SearchDocument = gql`
    query search($slug: ID!) {
  PageItem(id: $slug) {
    id
    uuid
    name
    content {
      title
      _uid
      _editable
    }
    translated_slugs {
      lang
      path
    }
    default_full_slug
  }
}
    `;
export const SearchPageDocument = gql`
    query searchPage($slug: ID!) {
  SearchItem(id: $slug) {
    uuid
    content {
      body
    }
  }
}
    `;
export const StyleDocument = gql`
    query style($id: ID!) {
  StyleItem(id: $id) {
    id
    content {
      title
      image {
        ...ImageFragment
      }
      banner_image {
        ...ImageFragment
      }
      subtitle
      description
      body
      info_title
      info_text
      _editable
      collection
      seo_content
      seo
    }
    slug
    uuid
  }
}
    ${ImageFragmentFragmentDoc}`;
export const StyleByShopifyNameDocument = gql`
    query styleByShopifyName($id: String!, $language: String!) {
  StyleItems(search_term: $id, starts_with: $language, per_page: 1) {
    items {
      content {
        title
        _editable
        info_title
        info_text
        collection
        image {
          filename
          alt
          focus
        }
        seo
        body
        _uid
      }
      slug
      full_slug
      uuid
    }
  }
}
    `;
export const StylesDocument = gql`
    query styles($prefix: String = "", $excluding_ids: String = "") {
  StyleItems(starts_with: $prefix, excluding_ids: $excluding_ids) {
    total
    items {
      content {
        title
        image {
          filename
          alt
        }
      }
      published_at
      slug
      full_slug
      uuid
    }
  }
}
    `;
export const TermsAndConditionsItemsDocument = gql`
    query termsAndConditionsItems {
  TermsandconditionsItems {
    items {
      content {
        termsAndConditions
        _uid
        _editable
      }
    }
  }
}
    `;
export const UspsDocument = gql`
    query usps($slug: ID!) {
  UspsItem(id: $slug, resolve_links: "url") {
    content {
      items
      component
      _editable
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    BlogCategoryItems(variables?: BlogCategoryItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BlogCategoryItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BlogCategoryItemsQuery>(BlogCategoryItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BlogCategoryItems', 'query');
    },
    blogOverview(variables: BlogOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BlogOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BlogOverviewQuery>(BlogOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'blogOverview', 'query');
    },
    BlogpostItem(variables: BlogpostItemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BlogpostItemQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BlogpostItemQuery>(BlogpostItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BlogpostItem', 'query');
    },
    BlogpostItems(variables?: BlogpostItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BlogpostItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BlogpostItemsQuery>(BlogpostItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BlogpostItems', 'query');
    },
    collection(variables: CollectionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CollectionQuery>(CollectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'collection', 'query');
    },
    collectionByShopifyId(variables: CollectionByShopifyIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionByShopifyIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CollectionByShopifyIdQuery>(CollectionByShopifyIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'collectionByShopifyId', 'query');
    },
    collectionOverview(variables: CollectionOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CollectionOverviewQuery>(CollectionOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'collectionOverview', 'query');
    },
    collectionSlide(variables: CollectionSlideQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionSlideQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CollectionSlideQuery>(CollectionSlideDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'collectionSlide', 'query');
    },
    collections(variables?: CollectionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CollectionsQuery>(CollectionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'collections', 'query');
    },
    collectionsByStyle(variables: CollectionsByStyleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CollectionsByStyleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CollectionsByStyleQuery>(CollectionsByStyleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'collectionsByStyle', 'query');
    },
    colorsMapping(variables: ColorsMappingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ColorsMappingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ColorsMappingQuery>(ColorsMappingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'colorsMapping', 'query');
    },
    filtersMapping(variables: FiltersMappingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FiltersMappingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FiltersMappingQuery>(FiltersMappingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'filtersMapping', 'query');
    },
    footerItem(variables: FooterItemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FooterItemQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FooterItemQuery>(FooterItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'footerItem', 'query');
    },
    home(variables: HomeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<HomeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HomeQuery>(HomeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'home', 'query');
    },
    navigationItem(variables: NavigationItemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NavigationItemQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NavigationItemQuery>(NavigationItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'navigationItem', 'query');
    },
    pageById(variables: PageByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PageByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PageByIdQuery>(PageByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'pageById', 'query');
    },
    pages(variables?: PagesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PagesQuery>(PagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'pages', 'query');
    },
    product(variables: ProductQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductQuery>(ProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'product', 'query');
    },
    productById(variables: ProductByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductByIdQuery>(ProductByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'productById', 'query');
    },
    shopAll(variables: ShopAllQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShopAllQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShopAllQuery>(ShopAllDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shopAll', 'query');
    },
    room(variables: RoomQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RoomQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RoomQuery>(RoomDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'room', 'query');
    },
    roomOverview(variables: RoomOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RoomOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RoomOverviewQuery>(RoomOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'roomOverview', 'query');
    },
    rooms(variables?: RoomsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RoomsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RoomsQuery>(RoomsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'rooms', 'query');
    },
    search(variables: SearchQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchQuery>(SearchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search', 'query');
    },
    searchPage(variables: SearchPageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchPageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchPageQuery>(SearchPageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'searchPage', 'query');
    },
    style(variables: StyleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StyleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StyleQuery>(StyleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'style', 'query');
    },
    styleByShopifyName(variables: StyleByShopifyNameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StyleByShopifyNameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StyleByShopifyNameQuery>(StyleByShopifyNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'styleByShopifyName', 'query');
    },
    styles(variables?: StylesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StylesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StylesQuery>(StylesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'styles', 'query');
    },
    termsAndConditionsItems(variables?: TermsAndConditionsItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TermsAndConditionsItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TermsAndConditionsItemsQuery>(TermsAndConditionsItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'termsAndConditionsItems', 'query');
    },
    usps(variables: UspsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UspsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UspsQuery>(UspsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'usps', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;