import { Box } from "@storyofams/react-ui";
import styled from "styled-components";

export const NavigationTitle = styled(Box).attrs({
  display: "flex",
  alignItems: "center",
  justifyContent: ["space-between", "space-between"],
  height: ["auto", "auto", "100%"],
  fontWeight: "semibold",
  width: ["100%", "100%", "100%"],
  fontSize: "1.8rem",
  lineHeight: "high",
  color: "grey900",
  transition: "0.18s color ease-in-out",
  position: "relative",
  cursor: "pointer",
})`
  &:hover {
    color: ${(p) => p.theme.colors.primary};
  }

  & svg {
    transition: transform 0.2s ease-in-out;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -1.1rem;
    left: 0;
    width: 0;
    height: 0.5rem;
    background-color: ${(p) => p.theme.colors.primary};
    border-radius: 0.25rem;

    transition: width 0.2s ease-in-out;
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }

    &::after {
      width: 100%;
    }
  }
`;
