import { FC } from "react";
import { Box, css, SystemProps } from "@storyofams/react-ui";
import {
  render,
  MARK_BOLD,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_OL,
  NODE_UL,
} from "storyblok-rich-text-react-renderer";

import { Heading } from "../Heading/heading";
import { Text } from "../Text";

interface RichTextProps extends SystemProps {
  text: any;
  markResolvers?: any;
  nodeResolvers?: any;
  className?: string;
}

export const RichText: FC<RichTextProps> = ({
  markResolvers = {},
  nodeResolvers = {},
  text,
  ...props
}) => (
  <Box
    fontSize={2}
    // color="grey700"
    css={css({
      "> *:last-child": { pb: 0 },
      "&& li": { p: { pb: [1, 1] }, "&:last-child": { p: { pb: [0, 0] } } },
    })}
    {...props}
  >
    {render(text, {
      markResolvers: {
        [MARK_BOLD]: (children) => <b>{children}</b>,
        [MARK_LINK]: (children, props) => {
          const { href } = props;
          return (
            <Box textDecoration="underline" as="span">
              <a
                href={href}
                // eslint-disable-next-line
                {...(href?.startsWith("javascript:")
                  ? {}
                  : {
                      target: "_blank",
                      rel: "noopener noreferrer",
                    })}
              >
                {children}
              </a>
            </Box>
          );
        },
        ...markResolvers,
      },
      nodeResolvers: {
        [NODE_HEADING]: (children, { level }) => (
          <Heading
            as={`h${level}` as any}
            variant={`h${level}` as any}
            pb={[3, 4]}
            color="grey900"
          >
            {children}
          </Heading>
        ),
        [NODE_PARAGRAPH]: (children) => (
          <Text pb={[3, 4]} as="p" variant="medium" className={"paragraph"}>
            {children}
          </Text>
        ),
        [NODE_OL]: (children) => (
          <Box as="ol" pl={2} pb={[3, 4]}>
            {children}
          </Box>
        ),
        [NODE_UL]: (children) => (
          <Box as="ul" pl={2} pb={[3, 4]}>
            {children}
          </Box>
        ),
        ...nodeResolvers,
      },
    })}
  </Box>
);
