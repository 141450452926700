import { Flex, Icon } from "@storyofams/react-ui";

import { Attention, Checkmark, XCircle } from "~components/common/Icons";
import { Text } from "~components/common/Text";

type ToastProps = {
  msg: string;
  type: "info" | "success" | "error" | "alert";
};

const content = {
  info: {
    icon: Attention,
    color: "grey700",
  },
  success: {
    icon: Checkmark,
    color: "success300",
  },
  error: {
    icon: XCircle,
    color: "error300",
  },
  alert: {
    icon: Attention,
    color: "error300",
  },
};

export const Persistent = ({ msg, type }: ToastProps) => (
  <Flex
    p={2}
    pl={1.5}
    width="100%"
    borderRadius="xs"
    backgroundColor="white"
    position="relative"
    alignItems="center"
    bg="grey300"
  >
    <Icon fontSize={3} icon={content[type].icon} color={content[type].color} />
    <Text ml={1.5} variant={["small", "medium"]} maxWidth="100%">
      {msg}
    </Text>
  </Flex>
);
