import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { LegacyButton } from "../../common/Button";
import { Heading } from "../../common/Heading/heading";
import { Flex, Icon, Select, css } from "@storyofams/react-ui";
import styles from "./RedirectPopup.module.scss";
import { Modal } from "~components/Modal";
import { Philips } from "~components/common/Icons";

const COUNTRIES = {
  be: "België",
  dk: "Danmark",
  es: "España",
  fr: "France",
  nl: "Nederland",
  se: "Sverige",
};

type RedirectPopupProps = {
  close: () => void;
};

export const RedirectPopup = ({ close }: RedirectPopupProps) => {
  const [selectedWebsite, setSelectedWebsite] =
    useState<{ label: string; value: string }>(null);
  const [websites, setWebsites] = useState<
    Array<{ label: string; value: string }>
  >([]);
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_AVAILABLE_WEBSITES === undefined) return;
    if (typeof window === "undefined") return;
    const websites = process.env.NEXT_PUBLIC_AVAILABLE_WEBSITES.split(",")
      .map((country) => {
        const label = COUNTRIES[country.toLowerCase()];
        const host = document.location.host;
        const value = !host.startsWith("localhost")
          ? `${country}.${host.substring(host.indexOf(".") + 1)}`
          : host;

        return {
          label,
          value,
        };
      })
      .sort((a, b) => {
        // sort alphabetically
        if (a.label < b.label) {
          return -1;
        }
      });
    setWebsites(websites);
    if (selectedWebsite === null) {
      setSelectedWebsite(
        websites.find((website) => {
          return website.value === document.location.host;
        })
      );
    }
  }, [process.env.NEXT_PUBLIC_AVAILABLE_WEBSITES]);

  useEffect(() => {
    const onMouseDown = (event) => {
      if (event.target === ref.current) {
        close();
      }
    };
    document.addEventListener("mousedown", onMouseDown);
    return () => {
      document.removeEventListener("mousedown", onMouseDown);
    };
  });

  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Modal p={0} isOpen={true} close={close}>
      <Flex
        height={"160px"}
        css={{
          background: "linear-gradient(93.67deg, #729186 0%, #556E65 100%)",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          icon={Philips}
          height="54px"
          css={css({ svg: { height: "100%", width: "100%" } })}
          display={["none !important", "none !important", "flex !important"]}
        />
      </Flex>
      <div className={styles.flex}>
        <div className={styles.box}>
          <Heading
            as="h2"
            variant="h4"
            mb={[1.5, 1.5, 2]}
            fontWeight="bold"
            textAlign="center"
            fontSize={["1.6rem", "2.3rem"]}
            color={"grey900"}
          >
            We have redirected you
          </Heading>

          <p className={styles.text}>
            You have been redirected to the website for your country. If you
            would like to change this, please select your country below.
          </p>

          <Select
            options={websites}
            value={selectedWebsite}
            onChange={(value: { label: string; value: string }) => {
              setSelectedWebsite(value);
            }}
          />

          <LegacyButton
            width="100%"
            variant="primary"
            mt="2rem"
            onClick={() => {
              close();
              router.push(`https://${selectedWebsite.value}`);
            }}
          >
            Apply
          </LegacyButton>
        </div>
      </div>
    </Modal>
  );
};

export default RedirectPopup;
