import { useEffect } from "react";

export const useDetectKeyboard = () => {
  useEffect(() => {
    let isRemoved = false;

    const handleFirstTab = (e: KeyboardEvent) => {
      if (e.keyCode === 9) {
        document.documentElement.classList.add("user-is-tabbing");
        isRemoved = true;
        window.removeEventListener("keydown", handleFirstTab);
      }
    };

    window.addEventListener("keydown", handleFirstTab);

    return () => {
      if (!isRemoved) {
        window.removeEventListener("keydown", handleFirstTab);
      }
    };
  }, []);
};
