import { isSameDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const getOrderIsCancelable = (order) => {
  if (
    order?.successfulFulfillments?.length ||
    !order?.processedAt ||
    order?.canceledAt
  ) {
    return false;
  }

  // Shopify time is already UTC
  const utcNow = zonedTimeToUtc(new Date(), process.env.NEXT_PUBLIC_TIMEZONE);
  const processedAt = new Date(order.processedAt);

  return isSameDay(utcNow, processedAt);
};
