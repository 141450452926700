import { Icon } from "@storyofams/react-ui";
import { useMemo } from "react";
import styled from "styled-components";
import { FilterCross } from "~components/common/Icons";

const StyledActiveFilter = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  border-radius: 2.5rem;
  border: 0.2rem solid;
`;

type FilterButtonProps = {
  value: string;
  filter: { [index: string]: any };
  onChange: (name: string, value: string, replace?: boolean) => void;
};

export const FilterButton = ({
  value,
  filter,
  onChange,
}: FilterButtonProps) => {
  const options = useMemo(() => {
    if (filter.type === "slider") {
      return {
        title: `${filter.title}: ${value}`,
        value,
      };
    } else if (filter.type === "color" || filter.type === "select") {
      return filter.options.filter((option) => {
        return (option?.value || option?.tag?.item) === value;
      })[0];
    }
  }, [filter]);

  const title = useMemo(() => {
    if (filter.type === "slider") {
      return `${filter.title}: ${value
        .split("-")
        .map((value) => {
          if (filter.unit === "€") {
            return `${filter.unit} ${value}`;
          } else if (filter.unit === "cm") {
            return `${value} ${filter.unit}`;
          }
        })
        .join(" - ")}`;
    } else if (filter.type === "color" || filter.type === "select") {
      return options.title;
    }
  }, [filter, options]);

  return (
    <StyledActiveFilter
      onClick={() => onChange(filter.value, options.value || options.tag.item)}
    >
      {title}
      <Icon icon={FilterCross} fontSize={3} />
    </StyledActiveFilter>
  );
};
