import { Box, Flex, SystemProps } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { ImageProps } from "next/image";
import { HeightProps, WidthProps } from "styled-system";

export const getHighestValue = (value: any): number | string => {
  switch (typeof value) {
    case "number":
      return value;
    case "object":
      if (Array.isArray(value))
        return value
          .map(getHighestValue)
          .sort((a, b) => Number(b) - Number(a))[0];
      return Object.values(value)
        .map(getHighestValue)
        .sort((a, b) => Number(b) - Number(a))[0];
    case "string":
      if (value.includes("%")) return value;
      return parseInt(value);
  }
};

type FillLayout = {
  layout: "fill";
  width?: WidthProps["width"];
  height?: HeightProps["height"];
};

type VariableLayout = {
  width: WidthProps["width"];
  height: HeightProps["height"];
  layout?: "fixed" | "intrinsic" | "responsive";
};

type LayoutType = FillLayout | VariableLayout;

export type LegacyImageProps = Omit<ImageProps, "layout" | "width" | "height"> &
  Omit<SystemProps, "width" | "height"> &
  LayoutType;

/**
 * @deprecated
 * Use the Next Image component instead
 * @description Image component which uses Rebass as Next's Image component. When you use this component and you're not certain of the source
 * domain of the image (i.e. user input) use, make sure to use the `unoptimized` prop. Otherwise declare the domain of the image in the `next.config.js`
 */

export const LegacyImage = (props: LegacyImageProps) => {
  const nextImageProps = omit(props);

  if (props.layout === "fill") {
    return (
      <Flex
        {...pick(props)}
        position="relative"
        data-testid="img"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          as="img"
          css={{ objectFit: "cover", objectPosition: "center" }}
          minHeight="100%"
          maxHeight="100%"
          position="absolute"
          top={0}
          {...nextImageProps}
        />
      </Flex>
    );
  }

  return (
    <Box {...pick(props)} data-testid="img">
      <Box
        as="img"
        {...nextImageProps}
        css={{
          objectFit: "contain",
          objectPosition: "center",
        }}
        maxHeight="100%"
        width={getHighestValue(props.width)}
        height={getHighestValue(props.height)}
      />
    </Box>
  );
};