import { useRouter } from "next/router"
import { useMemo } from "react"

export const useFilters = (filters: { [index: string]: any }[] = []) => {
  const { query, pathname, ...router } = useRouter()

  const onChange = (key: string, value: string, replace = false) => {
    const newQuery = { ...query }
    delete newQuery.page

    if (value === "all") delete newQuery[key]
    else if (replace) newQuery[key] = value
    else {
      let currentFilters = newQuery[key] || []

      if (!Array.isArray(currentFilters))
        currentFilters = currentFilters.split(",")

      if (currentFilters.includes(value)) {
        currentFilters = currentFilters.filter((item) => item !== value)
      } else {
        currentFilters.push(value)
      }

      const options = filters.find((filter) => filter.key === key)?.options
      const newFilters = []
      if (options) {
      options.forEach((option) => {
        const value = option?.tag?.item || option?.value
        if (currentFilters.includes(value)) newFilters.push(value)
      })
    }

      if (newFilters.length === 0) {
        delete newQuery[key]
      } else {
        newQuery[key] = newFilters.join(",")
      }
    }

    router.push(
      {
        pathname,
        query: newQuery
      },
      undefined,
      { shallow: true }
    )
  }

  const activeFilters = useMemo(() => {
    const filters = { ...query }

    const filterQueryObject = {
      type: filters.type,
      color: filters.color,
      price: filters.price,
      width: filters.width,
      height: filters.height,
      style: filters.style,
      range: filters.range,
      awards: filters.awards
    }
    const activeFilters: { [index: string]: string[] } = {}
    Object.keys(filterQueryObject).forEach((key) => {
      const value = filterQueryObject[key]
      if (!value) return
      activeFilters[key] = Array.isArray(value) ? [value] : value.split(",")
    })
    return activeFilters
  }, [query])

  const onReset = () => {
    const newQueryParams = { ...query }
    delete newQueryParams.page
    delete newQueryParams.search
    delete newQueryParams.sortKey
    delete newQueryParams.sortOrder
    delete newQueryParams.type
    delete newQueryParams.color
    delete newQueryParams.price
    delete newQueryParams.width
    delete newQueryParams.height
    delete newQueryParams.style
    delete newQueryParams.range
    delete newQueryParams.awards

    router.push(
      {
        pathname,
        query: newQueryParams
      },
      undefined,
      { shallow: true }
    )
  }

  return {
    onChange,
    activeFilters,
    onReset
  }
}
