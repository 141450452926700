import { GraphQLClient } from "graphql-request";
import { getSdk } from "./sdk";

const getClient = () =>
  new GraphQLClient(
    `${process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ORIGIN}/admin/api/2023-01/graphql.json`,
    {
      headers: {
        "X-Shopify-Access-Token": process.env.SHOPIFY_ADMIN_TOKEN,
      },
    }
  );

export const shopifyAdminSdk = getSdk(getClient());
