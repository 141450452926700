import { format } from "date-fns";
import { enGB, de, fr, nl, da, es } from "date-fns/locale";
import { useRouter } from "next/router";

const locales = { enGB, de, fr, nl, da, es };

export const useDate = () => {
  const { locale } = useRouter();

  const formatDate = (date, formatStr = "dd MM yyyy") =>
    !!date && format(new Date(date), formatStr, { locale: locales[locale] });

  return {
    formatDate,
  };
};
