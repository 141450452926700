import { ReactNode, useEffect, useState } from "react";
import { Box, Flex, Stack } from "@storyofams/react-ui";
import { sumBy } from "lodash";
import { useRouter } from "next/router";

import { useCustomer } from "~hooks";
import { getLocales } from "~lib";
import { Footer } from "~components/common/Footer";
import { Navigation } from "~components/common/Navigation";
import { NavigationDropdown } from "~components/common/Navigation/components/NavigationDropdown";
import { NavigationLink } from "~components/common/Navigation/components/NavigationLink";
import { NavigationPrimary } from "~components/common/Navigation/components/NavigationPrimary";

import { FooterItem, NavigationItem } from "~lib/storyblok/sdk";

import { LanguageSwitch } from "../LanguageSwitch";

export const Layout = ({
  children,
  navigation,
  footer,
}: {
  children: ReactNode;
  footer: FooterItem;
  navigation: NavigationItem;
}) => {
  const { checkout } = useCustomer();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const router = useRouter();

  useEffect(() => {
    const q = sumBy(checkout?.lineItems?.edges, function (item) {
      return item?.node?.quantity;
    });

    setQuantity(q);
  }, [checkout]);

  return (
    <>
      <Navigation>
        <NavigationPrimary
          badge={quantity}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        >
          <Stack
            space={[2, 2, 2, 5]}
            flex={1}
            display="flex"
            alignItems={["flex-start", "flex-start", "flex-start", "center"]}
            flexDirection={["column", "column", "column", "row"]}
            px={[2, 2, 2, 4]}
            py={[4, 4, 4, 0]}
          >
            {getLocales()?.length > 1 && (
              <Box
                alignItems="center"
                justifyContent="center"
                alignSelf={"flex-end"}
                display={["flex", "flex", "flex", "none"]}
              >
                <LanguageSwitch />
              </Box>
            )}
            {navigation?.content?.links?.map((props) =>
              props?.sublinks?.length ? (
                <NavigationDropdown key={props?._uid} {...props} />
              ) : (
                <NavigationLink key={props?._uid} {...props} />
              )
            )}
          </Stack>
        </NavigationPrimary>
      </Navigation>

      <Box paddingBottom={["56px", "56px", "80px"]} />

      <Flex
        width="100%"
        as="main"
        flexDirection="column"
        flex="1"
        bg={router?.asPath?.includes("/blog") ? "grey200" : "transparent"}
      >
        {children}
      </Flex>

      <Footer footer={footer} />
    </>
  );
};
