import { useCallback, Fragment, MutableRefObject } from "react";
import { Stack, Flex, Box, Icon, css, SystemProps } from "@storyofams/react-ui";
import { debounce } from "lodash";

import { useCheckout } from "~hooks";
import { shopifyToIntlPrice } from "~lib";
import { LegacyButton } from "~components/common/Button";
import { Divider } from "~components/common/Divider";
import { Heading } from "~components/common/Heading/heading";
import { Cross } from "~components/common/Icons";
import { LegacyImage } from "~components/common/Image";
import { NumberInput } from "~components/common/NumberInput";
import { Text } from "~components/common/Text";
import { CheckoutFragmentFragment } from "~lib/shopify/sdk";

type CheckoutLineItemProps = {
  sidebar?: boolean;
  saveButtonRef?: MutableRefObject<any>;
} & CheckoutFragmentFragment["lineItems"]["edges"][number]["node"];

const CheckoutLineItem = ({
  sidebar = false,
  saveButtonRef,
  ...item
}: CheckoutLineItemProps) => {
  const {
    adjustLineItemQuantity,
    checkout,
    isUpdatingCart,
    removeLineItem,
    toggleCartModal,
  } = useCheckout();

  const debouncedAdjustQuantity = useCallback(
    debounce(adjustLineItemQuantity, 100),
    [checkout]
  );

  return (
    <Flex
      flexDirection="column"
      width="100%"
      css={css({
        color: "grey900",
        "& > div": {
          flexWrap: "wrap",
          justifyContent: ["initial", "space-between"],
        },
      })}
    >
      <Flex width="100%" alignItems="center">
        <LegacyButton
          to={`/products/${item?.variant?.product?.handle}${
            item?.variant?.sku ? `?variant=${item?.variant?.sku}` : ""
          }`}
          flex="2"
          onClick={() => {
            if (sidebar) {
              toggleCartModal(false);
            }
          }}
        >
          <Box
            minWidth="64px"
            overflow="hidden"
            borderRadius="sm"
            backgroundColor="grey200"
            alignSelf={!sidebar ? ["flex-start", "center"] : undefined}
          >
            {!!item?.variant?.image && (
              <LegacyImage
                height={sidebar ? 96 : [80, 128]}
                width={sidebar ? 96 : [80, 128]}
                objectFit="cover"
                src={item.variant.image.transformedSrc}
              />
            )}
          </Box>
          <Stack
            ml={sidebar ? 2 : [1.5, 3]}
            space={[0.5, 1]}
            flexDirection="column"
            flex="1"
            textAlign="left"
            maxWidth="100%"
            overflow="hidden"
          >
            <Heading as="h3" variant="h4" color="grey900">
              {item?.variant?.product?.title || item?.title}
            </Heading>
            {sidebar ? (
              <Text
                mt={1.5}
                color="grey700"
                variant="label"
                fontWeight="bold"
                fontSize={1.75}
                lineHeight="high"
              >
                {item?.quantity > 1 && `${item?.quantity} x`}{" "}
                {shopifyToIntlPrice({
                  ...item?.variant?.priceV2,
                  amount:
                    item?.variant?.priceV2.amount -
                    (item?.discountAllocations?.[0]?.allocatedAmount?.amount /
                      item.quantity || 0),
                })}
              </Text>
            ) : (
              <>
                {!!item?.variant?.selectedOptions?.filter(
                  ({ name }) => name !== "Title"
                ).length && (
                  <Box
                    flexDirection="column"
                    fontSize={1.75}
                    color="grey700"
                    lineHeight="medium"
                    display={["none", "flex"]}
                  >
                    {item?.variant?.selectedOptions
                      ?.filter(({ name }) => name !== "Title")
                      ?.map(({ name, value }, i) => (
                        <Text variant="medium" key={i} mt={i !== 0 ? 0.5 : 0}>
                          {name}: {value}
                        </Text>
                      ))}
                  </Box>
                )}
                <Text
                  pt={0}
                  mt="4px!important"
                  color="grey700"
                  variant="medium"
                >
                  SKU: {item?.variant?.sku ?? "–"}
                </Text>
                <Box
                  display={["flex", "none"]}
                  minWidth="100%"
                  justifyContent="space-between !important"
                  alignItems="center!important"
                  flex="1"
                  mt={1.25}
                >
                  <Flex
                    order={[2, 1]}
                    css={{
                      div: {
                        alignItems: "center",
                      },
                    }}
                  >
                    <NumberInput
                      saveButtonRef={saveButtonRef}
                      label={null}
                      value={`${item?.quantity}`}
                      required
                      onChange={(e) => {
                        debouncedAdjustQuantity({
                          variantId: item?.variant?.id,
                          quantity: e,
                        });
                      }}
                    />
                  </Flex>
                  <Flex order={[1, 2]}>
                    <Text>
                      {shopifyToIntlPrice({
                        ...item?.variant?.priceV2,
                        amount:
                          item?.variant?.priceV2.amount * item.quantity -
                          (item?.discountAllocations?.[0]?.allocatedAmount
                            ?.amount || 0),
                      })}
                    </Text>
                  </Flex>
                </Box>
              </>
            )}
          </Stack>
        </LegacyButton>

        {!sidebar && (
          <>
            <Flex
              display={["none !important", "flex !important"]}
              alignItems="center"
              ml={[0, 2]}
            >
              <Box>
                <NumberInput
                  saveButtonRef={saveButtonRef}
                  label={null}
                  aria-label="quantity"
                  value={`${item?.quantity}`}
                  required
                  cursor={isUpdatingCart ? "wait !important" : undefined}
                  onChange={(e) =>
                    debouncedAdjustQuantity({
                      variantId: item?.variant?.id,
                      quantity: e,
                    })
                  }
                />
              </Box>
              <Flex flexDirection={"column"} gap="0.8rem">
                {item?.discountAllocations?.[0]?.allocatedAmount?.amount >
                  0 && (
                  <Text
                    ml={[0, 3]}
                    color="grey500"
                    textDecoration="line-through"
                    fontWeight={["bold", "900"]}
                    fontSize={["12px", "16px"]}
                    lineHeight={["140%", "100%"]}
                  >
                    {shopifyToIntlPrice({
                      ...item?.variant?.priceV2,
                      amount: item?.variant?.priceV2.amount * item.quantity,
                    })}
                  </Text>
                )}
                <Text
                  ml={[0, 3]}
                  color="grey700"
                  fontWeight={["bold", "900"]}
                  fontSize={["12px", "16px"]}
                  lineHeight={["140%", "100%"]}
                >
                  {shopifyToIntlPrice({
                    ...item?.variant?.priceV2,
                    amount:
                      item?.variant?.priceV2.amount * item.quantity -
                      (item?.discountAllocations?.[0]?.allocatedAmount
                        ?.amount || 0),
                  })}
                </Text>
              </Flex>
            </Flex>
            <LegacyButton
              variant="unstyled"
              data-testid="cart-delete-button"
              alignSelf={["flex-start", "center"]}
              ml={[0, 2]}
              onClick={(e) => {
                e.preventDefault();
                removeLineItem(item?.variant?.id);
              }}
            >
              <Icon fontSize={3} icon={Cross} />
            </LegacyButton>
          </>
        )}
      </Flex>
    </Flex>
  );
};

type ListProps = {
  saveButtonRef?: MutableRefObject<any>;
  sidebar?: boolean;
} & SystemProps;

export const List = ({ sidebar, saveButtonRef, ...props }: ListProps) => {
  const { checkout } = useCheckout();

  return (
    <Stack
      flex={1}
      space={2}
      mt={6}
      //@ts-expect-error
      flexDirection="column"
      {...props}
    >
      {checkout?.lineItems?.edges?.map(({ node }) => (
        <Fragment key={node.id}>
          <CheckoutLineItem
            {...node}
            saveButtonRef={saveButtonRef}
            sidebar={sidebar}
          />
          {!sidebar && <Divider mt={2} />}
        </Fragment>
      ))}
    </Stack>
  );
};
