import { useRef } from "react";
import { Box, css, SystemProps } from "@storyofams/react-ui";
import dynamic from "next/dynamic";

type VideoProps = {
  video: any;
} & SystemProps;

/**
 * @deprecated
 * Use the ReactPlayer component directly instead
 */
export const Video = ({
  video,
  minWidth = "100vw",
  maxWidth = "maxWidth",
  minHeight = "calc(100vw * 9/16)",
  height = ["480px", "660px", "100vh"],
  width = ["calc(480px * 16/9)", "calc(660px * 16/9)", "calc(100vh * 16/9)"],
}: VideoProps) => {
  const wrapperRef = useRef();
  const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

  return (
    <Box
      width="100%"
      maxWidth={maxWidth}
      height={"100%"}
      ref={wrapperRef}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        // mx="auto"
        bg="grey300"
        width={width}
        display="flex"
        height={height}
        minWidth={minWidth}
        minHeight={minHeight}
        justifyContent="center"
        css={css({
          position: "relative",
          "> div": {
            position: "absolute",
            left: 0,
            top: 0,
            width: "100% !important",
            height: "100% !important",
          },
          iframe: {
            width: "100%",
            height: "100%",
          },
        })}
      >
        {video && (
          <ReactPlayer
            loop
            muted={true}
            url={video}
            config={{
              vimeo: {
                playerOptions: {
                  // see https://developer.vimeo.com/player/sdk/embed
                  loop: true,
                  autoplay: true,
                  muted: true,
                  playsinline: true,
                  responsive: true,
                  background: true,
                },
              },
              youtube: {
                playerVars: {
                  modestbranding: 1,
                  controls: true,
                  muted: true,
                },
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};
