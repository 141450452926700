import React, { ComponentProps, ReactNode, useRef } from "react";
import { Box, css } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import styled from "styled-components";
import SwiperCore, { Scrollbar, A11y, Pagination, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Slide } from "./Slide";

SwiperCore.use([Scrollbar, A11y, Pagination, Mousewheel]);

export type SliderProps = {
  children: ReactNode[];
  hideScrollBar?: boolean;
  navigationElements?: ReactNode;
  slideWidth?: string[] | string;
  spaceBetween?: any;
  usePagination?: boolean;
} & ComponentProps<typeof Swiper>;

interface Position {
  x: number;
  y: number;
}

const CLICK_SLOP = 5;

export const Slider = ({
  children,
  hideScrollBar,
  navigationElements,
  slideWidth,
  usePagination,
  className,
  ...props
}: SliderProps) => {
  const touchStartPosition = useRef<Position | null>(null);
  const router = useRouter();

  return (
    <Container
      css={css({
        ".swiper-slide": {
          mb: [0, hideScrollBar ? 0 : 3],
          width: slideWidth ? slideWidth : undefined,
          "&:last-child": {
            mr: 0,
          },
        },
      })}
      className={className}
    >
      <Swiper
        slidesPerView="auto"
        scrollbar={
          hideScrollBar
            ? false
            : {
                el: ".swiper-scrollbar",
                hide: false,
              }
        }
        watchOverflow
        freeMode
        onTouchStart={(_, e: any) => {
          touchStartPosition.current = { x: e.pageX, y: e.pageY };
        }}
        onTouchEnd={(_, e: any) => {
          if (
            touchStartPosition.current &&
            Math.hypot(
              e.pageX - touchStartPosition.current.x,
              e.pageY - touchStartPosition.current.y
            ) < CLICK_SLOP
          ) {
            const link = e.target.closest("a")?.getAttribute("href");

            if (link) {
              router.push(link);
            }
          }
        }}
        pagination={usePagination ? true : false}
        {...props}
      >
        {children.map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
        {navigationElements}
        {!hideScrollBar && (
          <ScrollContent className="swiper-scrollbar">
            <Scroll />
          </ScrollContent>
        )}
      </Swiper>
    </Container>
  );
};

Slider.Slide = Slide;

const Container = styled(Box)`
  width: 100%;

  & .swiper-container {
    overflow: inherit;
    width: 100%;
  }

  & .swiper-wrapper {
    display: flex;
    flex-direction: row;
  }

  & .swiper-pagination {
    bottom: -42px;

    &-bullets .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background: ${(p) => p.theme.colors.primary};
      margin: 0 3px;
      opacity: 0.5;

      &-active {
        opacity: 1;
      }
    }
  }
`;

const ScrollContent = styled(Box)`
  display: flex;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  position: absolute;
  left: 0 !important;
  bottom: 0 !important;
  z-index: 50;
  height: 4px !important;
  width: 100% !important;

  & div {
    background-color: #575b5b;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
    display: none;
  }
`;

const Scroll = styled.hr`
  margin: 0;
  width: 100%;
  max-width: 100%;
  border-top: 4px solid #d0d3d2;
  position: absolute;
  top: 0;

  @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
    display: none;
  }
`;
