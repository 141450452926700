import { Grid, Container, Button } from "~components";
import { RichTitle } from "~components/common/RichTitle";
import { useGetHref } from "~lib";
import storyblokEditable from "~lib/storyblokEditable";
import Image from "next/image";
import storyblokImageLoader from "~lib/storyblok/imageLoader";
import { InviewLoader } from "~components/common/InviewLoader";
import styles from "./Intro.module.scss";

export const Intro = ({ content }) => {
  const { title, description, link, link_text, left_image, right_image } =
    content;
  const { getHref } = useGetHref();

  if (!title && !description && !left_image && !right_image) {
    return null;
  }

  return (
    <Container {...storyblokEditable(content as any)}>
      <Grid className={styles.intro}>
        <h2 className={styles.title}>
          <RichTitle title={title} />
        </h2>
        <div className={styles.textWrapper}>
          <p className={styles.description}>{description}</p>
          {link_text && link && (
            <Button
              href={getHref(link)}
              as="link"
              color="primary"
              className={styles.button}
            >
              {link_text}
            </Button>
          )}
        </div>
        <div className={styles.leftImage}>
          <InviewLoader>
            <Image
              src={left_image?.filename}
              loader={storyblokImageLoader}
              objectFit="cover"
              layout="fill"
            />
          </InviewLoader>
        </div>
        <div className={styles.rightImage}>
          <InviewLoader>
            <Image
              src={right_image?.filename}
              loader={storyblokImageLoader}
              objectFit="cover"
              layout="fill"
            />
          </InviewLoader>
        </div>
      </Grid>
    </Container>
  );
};
