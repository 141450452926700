import { Icon } from "@storyofams/react-ui";
import { defineMessages, useIntl } from "react-intl";
import { Breadcrumb, Container, Grid, StyledImage } from "~components";
import { Home } from "~components/common/Icons";
import styles from "./CollectionHeader.module.scss";
import { useRouter } from "next/router";
import { useMemo } from "react";
import storyblokEditable from "~lib/storyblokEditable";

const messages = defineMessages({
  home: "Home",
  rooms: "Rooms",
  collections: "Collections",
  styles: "Styles",
});

export const CollectionHeader = ({ story }) => {
  const { asPath } = useRouter();
  const collectionType = useMemo(() => asPath.split("/")[1], [asPath]);

  const intl = useIntl();
  const { title, subtitle, description, image } = story?.content || {};

  return (
    <div {...storyblokEditable(story?.content)}>
      <Container className={styles.header}>
        <Breadcrumb
          color={["grey700", "grey900"] as any}
          items={[
            {
              name: intl.formatMessage(messages.home),
              content: <Icon icon={Home} fontSize={2} />,
              link: "/",
            },
            {
              link: `/${collectionType}`,
              name: intl.formatMessage(messages[collectionType]),
            },
            {
              link: `/${collectionType}/${story?.slug}`,
              name: story?.content?.title,
            },
          ]}
          className={styles.breadcrumb}
        />
        <Grid className={styles.grid}>
          <div className={styles.titleColumn}>
            <hr className={styles.line} />
            <h1 className={styles.title}>{subtitle || title}</h1>
            <div className={styles.imageWrapper}>
              <StyledImage
                src={image.filename}
                alt={image.alt}
                customLoader="storyblok"
                layout="fill"
                objectFit="cover"
              />
            </div>
          </div>
          <div className={styles.textColumn}>
            {subtitle && <h2 className={styles.title}>{title}</h2>}
            {description && <p className={styles.description}>{description}</p>}
          </div>
        </Grid>
      </Container>
    </div>
  );
};
