import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { useCheckout, useIsMobile } from "~hooks";
import { shopifyToIntlPrice } from "~lib";
import { Spinner } from "@storyofams/react-ui";
import { useIntersectionObserver } from "usehooks-ts";

import { Product, ProductFullFragmentFragment } from "~lib/shopify/sdk";

import {
  Anchor,
  Cart,
  CartButton,
  Discount,
  Heading3,
  ImagePlaceholder,
  ImgWrapper,
  PlaceholderAnchor,
  Price,
  ProductInformation,
  TextPlaceholder,
} from "./StyledProductCard";
import Link from "next/link";
import { DiscountContext } from "~context/Discounts";
import { StyledImage } from "~components";
import { ViewedProductsContext } from "~context/ViewedProducts";
import styled from "styled-components";

const messages = defineMessages({
  discount: "discount",
});

const SubtitleParagraph = styled.p`
  font-size: 15px;
`;

type ProductCardProps =
  | {
      isEven?: boolean;
      showCardButton?: boolean;
    } & Pick<
      ProductFullFragmentFragment,
      | "title"
      | "handle"
      | "vendor"
      | "priceRange"
      | "subtitle"
      | "availableForSale"
      | "compareAtPriceRange"
    > &
      Product;

export const ProductCard = (product: ProductCardProps) => {
  const [visibleVariant] = useState(product?.variants?.edges?.[0]?.node?.sku);
  const [isHovering, setIsHovering] = useState(false);
  const { addItemToCart, isUpdatingCart } = useCheckout();
  const intl = useIntl();
  const { getProductPrice } = useContext(DiscountContext);
  const { original, discounted } = getProductPrice(product);
  const isMobile = useIsMobile();

  const imageSrc = useMemo(
    () =>
      (visibleVariant
        ? product?.variants?.edges?.find(
            ({ node }) => node?.sku === visibleVariant
          )?.node?.image?.transformedSrc
        : undefined) || product?.images?.edges[0]?.node?.transformedSrc,
    [product, visibleVariant]
  );
  const imageSrcHover = useMemo(
    () =>
      visibleVariant
        ? product?.images?.edges[1]?.node?.transformedSrc
        : product?.variants?.edges?.find(
            ({ node }) => node?.sku === visibleVariant
          )?.node?.image?.transformedSrc || undefined,
    [product, visibleVariant]
  );

  const handleHover = useCallback(
    (hovering: boolean) => () => {
      setIsHovering(hovering);
    },
    []
  );

  const productCardRef = useRef<HTMLAnchorElement>(null);
  const observedProductCard = useIntersectionObserver(productCardRef, {
    threshold: 1,
  });

  const { viewedProducts, setViewedProducts } = useContext(
    ViewedProductsContext
  );

  useEffect(() => {
    if (!observedProductCard?.isIntersecting) return;
    const price =
      product.priceRange.minVariantPrice || product.priceRange.maxVariantPrice;
    const productTrackingData = {
      item_id: product.variants.edges[0].node.sku,
      item_name: product.title,
      currency: price.currencyCode,
      price: price.amount,
    };
    if (
      viewedProducts?.find(
        (p) => p?.item_id === product?.variants.edges[0].node.sku
      )
    )
      return;

    setViewedProducts([...viewedProducts, productTrackingData]);
  }, [observedProductCard?.isIntersecting]);

  const productPrice = useMemo(() => {
    if (discounted) {
      return shopifyToIntlPrice(discounted);
    } else {
      return shopifyToIntlPrice(original);
    }
  }, [discounted, original]);

  return (
    <Link href={`/products/${product.handle}`}>
      <Anchor
        ref={productCardRef}
        onMouseEnter={handleHover(true)}
        onMouseLeave={handleHover(false)}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
      >
        <ImgWrapper>
          <StyledImage
            src={imageSrc}
            customLoader={"shopify"}
            objectFit="cover"
            layout="fill"
            sizes="(max-width: 480px) 50vw, (max-width: 768px) 30vw, 280px"
            isHovering={isHovering}
          />
          <StyledImage
            src={imageSrcHover}
            customLoader={"shopify"}
            objectFit="cover"
            layout="fill"
            sizes="(max-width: 480px) 50vw, (max-width: 768px) 30vw, 280px"
            initial={{
              opacity: 0,
              scale: 1,
            }}
            animate={{
              opacity: isHovering ? 1 : 0,
              scale: isHovering ? 1.1 : 1,
            }}
          />
          {(discounted?.percentage || discounted?.discount) && (
            <Discount>
              <p>
                {discounted?.percentage ? (
                  <span>{discounted?.percentage}%</span>
                ) : (
                  <span>
                    {shopifyToIntlPrice({
                      amount: discounted?.discount,
                      currencyCode: discounted?.currencyCode,
                    })}
                  </span>
                )}
                &nbsp;
                {intl.formatMessage(messages.discount)}
              </p>
            </Discount>
          )}
          {(product?.showCardButton ||
            product?.showCardButton === undefined) && (
            <CartButton
              onClick={(e) => {
                e.preventDefault();
                addItemToCart([
                  {
                    variantId: product?.variants?.edges?.[0]?.node?.id,
                    quantity: parseInt("1"),
                  },
                ]);
              }}
              animate={{
                opacity: isHovering || isMobile ? 1 : 0,
              }}
              initial={{
                opacity: isMobile ? 1 : 0,
              }}
              className="cart-button"
            >
              {isUpdatingCart ? <Spinner /> : <Cart />}
            </CartButton>
          )}
        </ImgWrapper>
        <ProductInformation>
          <Heading3>{product?.title?.split(" - ")[0]}</Heading3>
          <SubtitleParagraph>{product.subtitle?.value}</SubtitleParagraph>
          <Price isDiscounted={!!discounted} data-testinfo={productPrice}>
            <span>{shopifyToIntlPrice(original)}</span>
            {!!discounted && <span>{shopifyToIntlPrice(discounted)}</span>}
          </Price>
        </ProductInformation>
      </Anchor>
    </Link>
  );
};

export const ProductCardPlaceholder = () => {
  return (
    <PlaceholderAnchor>
      <ImagePlaceholder />
      <TextPlaceholder />
      <TextPlaceholder />
      <TextPlaceholder />
    </PlaceholderAnchor>
  );
};
