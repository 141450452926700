import { GraphQLClient } from "graphql-request";

import { getSdk } from "./sdk";

export const getClient = (locale?: string) =>
  new GraphQLClient(
    `${process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ORIGIN}/api/2023-01/graphql.json`,
    {
      headers: {
        "X-Shopify-Storefront-Access-Token":
          process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        "Accept-Language": locale ? locale : process.env.DEFAULT_LOCALE ?? "en",
      },
    }
  );

export const shopifySdk = getSdk(getClient());

export const getShopifySdk = (locale?: string) => getSdk(getClient(locale));
