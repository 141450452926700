import React, { FC } from "react";
import { Box, Flex, SystemProps } from "@storyofams/react-ui";

export const Divider: FC<SystemProps> = ({
  children,
  color = "grey300",
  ...props
}) => {
  return (
    <Flex width="100%" alignItems="center" {...props}>
      <Box
        width="100%"
        minHeight="1px"
        maxHeight="1px"
        backgroundColor={color}
      />
      {children && (
        <Flex flex={1} mx={2}>
          {children}
        </Flex>
      )}
      <Box
        width="100%"
        minHeight="1px"
        maxHeight="1px"
        backgroundColor={color}
      />
    </Flex>
  );
};
