import { Flex, Icon, Close } from "@storyofams/react-ui";

import { LegacyButton } from "~components/common/Button";
import { Attention, Checkmark, XCircle } from "~components/common/Icons";
import { Text } from "~components/common/Text";

type ToastProps = {
  msg: string;
  type: "info" | "success" | "error" | "alert";
  onClose: () => void;
};

const content = {
  info: {
    icon: Attention,
    color: "grey700",
  },
  success: {
    icon: Checkmark,
    color: "success300",
  },
  error: {
    icon: XCircle,
    color: "error300",
  },
  alert: {
    icon: Attention,
    color: "error300",
  },
};

export const Toast = ({ msg, type }: ToastProps) => (
  <Flex
    p={4}
    width="100%"
    boxShadow="toast"
    borderRadius="md"
    position="relative"
    backgroundColor="grey100"
    alignItems="center"
  >
    <Icon fontSize={3} icon={content[type].icon} color={content[type].color} />
    <Text ml={2} variant="medium" color="grey700" maxWidth="100%">
      {msg}
    </Text>
    <LegacyButton
      variant="unstyled"
      position="absolute"
      top="12px"
      right="12px"
    >
      <Icon icon={Close} color="grey700" fontSize={1.5} />
    </LegacyButton>
  </Flex>
);
