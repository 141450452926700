import { ImageLoaderProps } from "next/image";

const shopifyImageLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps): string => {
  return `${src.replace("x700", width + "x")}?quality=${quality ?? 75})}`;
};

export default shopifyImageLoader;
