import { FC, useEffect, useMemo, createContext, useState } from "react";
import { useRouter } from "next/router";
import { useToggle } from "react-use";

import { getClient } from "~lib/shopify/client";
import {
  CheckoutFragmentFragment,
  CustomerQuery,
  useAssociateCustomerWithCheckoutMutation,
  useCheckoutQuery,
  useCustomerQuery,
} from "~lib/shopify/hooks";

export interface ShopifyContextType {
  accessToken?: string;
  setAccessToken: (token: string) => void;
  checkout: CheckoutFragmentFragment;
  showCartOverlay: boolean;
  toggleCartModal: (nextValue?: boolean) => void;
  latestProduct?: Record<string, any>;
  setLatestProduct: (val: Record<string, any>) => void;
  checkoutId?: string;
  setCheckoutId: (val: string) => void;
  checkoutIsDisabled: boolean;
  checkoutIsLoading: boolean;
  customer?: CustomerQuery["customer"];
  customerIsLoading: boolean;
  refetchCustomer: () => void;
}

export const ShopifyContext = createContext<ShopifyContextType | undefined>(
  undefined
);

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = "accessToken";
const LOCAL_STORAGE_CHECKOUT_ID_KEY = "checkoutId";

export const ShopifyProvider: FC = ({ children }) => {
  const router = useRouter();
  const client = useMemo(() => getClient(router?.locale), [router?.locale]);

  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [, setTokenIsSet] = useState(false);
  const [checkoutId, setCheckoutId] = useState<string>("");
  const [checkoutIdInitialized, setCheckoutIdInitialized] = useState(false);
  const [showCartOverlay, toggleCartModal] = useToggle(false);
  const [latestProduct, setLatestProduct] = useState(null);

  const checkoutIsDisabled = !checkoutId;
  const {
    data: customer,
    status,
    refetch: refetchCustomer,
  } = useCustomerQuery(
    client,
    {
      token: accessToken,
    },
    {
      enabled: Boolean(accessToken),
    }
  );
  
  const { data: checkout, isLoading } = useCheckoutQuery(
    client,
    {
      id: checkoutId,
    },
    {
      enabled: Boolean(checkoutId),
    }
  );

  const associateCustomerMutation = useAssociateCustomerWithCheckoutMutation(
    client,
    {}
  );

  useEffect(() => {
    const id = localStorage.getItem(LOCAL_STORAGE_CHECKOUT_ID_KEY);

    if (id) setCheckoutId(id);

    setCheckoutIdInitialized(true);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

    if (token) setAccessToken(token);

    setTokenIsSet(true);
  }, []);

  // clean up checkout
  useEffect(() => {
    if (
      checkout === null ||
      (checkout?.node as CheckoutFragmentFragment)?.lineItems?.edges?.some(
        ({ node }) => !node.variant
      ) ||
      (checkout?.node as CheckoutFragmentFragment)?.completedAt
    ) {
      setCheckoutId("");
    }
  }, [checkout]);

  /**
   * If there is a logged in customer and a checkout, associate the checkout with the customer
   */
  useEffect(() => {
    if (
      customer &&
      checkout &&
      !(checkout.node as CheckoutFragmentFragment)?.completedAt &&
      customer?.customer?.lastIncompleteCheckout?.id !==
        (checkout.node as CheckoutFragmentFragment)?.id
    ) {
      associateCustomerMutation.mutate({
        checkoutId: (checkout.node as CheckoutFragmentFragment)?.id,
        customerAccessToken: accessToken,
      });
    }
  }, [customer, checkout]);

  /**
   * If there is a customer and no checkout, check if the customer has an incomplete checkout. If so, set it
   */
  useEffect(() => {
    if (customer && !checkoutId && customer?.customer?.lastIncompleteCheckout) {
      setCheckoutId(customer?.customer?.lastIncompleteCheckout?.id);
    }
  }, [customer, checkoutId]);

  const handleSetCheckoutId = (id: string) => {
    localStorage.setItem(LOCAL_STORAGE_CHECKOUT_ID_KEY, id);
    setCheckoutId(id);
  };

  const handleSetAccessToken = (token: string) => {
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, token);
    setAccessToken(token);
  };

  return (
    <ShopifyContext.Provider
      value={{
        checkout: checkout?.node as CheckoutFragmentFragment,
        showCartOverlay,
        toggleCartModal,
        latestProduct,
        setLatestProduct,
        checkoutId,
        setCheckoutId: handleSetCheckoutId,
        checkoutIsLoading: isLoading,
        checkoutIsDisabled: checkoutIdInitialized && checkoutIsDisabled,
        customer: customer?.customer,
        refetchCustomer,
        accessToken,
        setAccessToken: handleSetAccessToken,
        customerIsLoading: status === "loading",
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};
