import { FC, useEffect, useRef } from "react";
import { Icon, Flex, Box, SystemProps, css } from "@storyofams/react-ui";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { defineMessages, useIntl } from "react-intl";

import { useCustomer } from "~hooks";
import { getLocales } from "~lib";
import { CartOverlay } from "~components/cart/CartOverlay";
import { Button } from "~components";
import styles from "./NavigationPrimary.module.scss";
import { User, Search, Cart as CartIcon, Logo } from "~components/common/Icons";
import type { LanguageSwitch as LanguageSwitchType } from "../../LanguageSwitch";

import { Badge } from "./Badge";
import { Hamburger } from "./Hamburger";
import { NavigationAccountDropdown } from "./NavigationAccountDropdown";
import { NavigationButton } from "./NavigationButton";
import Link from "next/link";

const DynamicLanguageSwitch = dynamic(
  () =>
    import("~components/common/LanguageSwitch").then((m) => m.LanguageSwitch),
  { ssr: false }
) as typeof LanguageSwitchType;

const messages = defineMessages({
  account: "My account",
  login: "Login",
  cart: "Cart",
});

interface NavigationPrimaryProps extends SystemProps {
  badge?: number;
  hasSearchButton?: boolean;
  mobileMenuOpen: boolean;
  setMobileMenuOpen(boolean): void;
}

export const NavigationPrimary: FC<NavigationPrimaryProps> = ({
  badge,
  children,
  mobileMenuOpen,
  setMobileMenuOpen,
  ...props
}) => {
  const intl = useIntl();
  const { customer, showCartOverlay } = useCustomer();
  const { pathname, locale } = useRouter();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      ref.current.scrollTop = 0;
      document.body.style.overflow = "unset";
    }
  }, [mobileMenuOpen]);

  return (
    <Flex
      mx="auto"
      height={["56px", "56px", "80px"]}
      alignItems="center"
      pr={[1, 2, 5]}
      pl={[2, 2, 5]}
      {...props}
    >
      <Button className={styles.logo} as="link" color="unstyled" href="/">
        <Icon
          icon={Logo}
          width={["99px", "99px", "146px"]}
          height={["72px", "72px", "106px"]}
          css={css({ svg: { height: "100%", width: "100%" } })}
          zIndex={"popover"}
        />
      </Button>
      <Flex
        ref={ref}
        position={["fixed", "fixed", "fixed", "static"]}
        top={["5.6rem", "5.6rem", "5.6rem", "unset"]}
        left={[
          mobileMenuOpen ? 0 : "-100%",
          mobileMenuOpen ? 0 : "-100%",
          mobileMenuOpen ? 0 : "-100%",
          "unset",
        ]}
        overflow={["scroll", "scroll", "scroll", "unset"]}
        bottom={[0, 0, 0, "unset"]}
        backgroundColor="white"
        transition="left .24s ease-out"
        height={["calc(100% - 5.6rem)", "calc(100% - 5.6rem)", "100%"]}
        width={["100%", "100%", "100%", "auto"]}
        alignContent="center"
        flexDirection={["column", "column", "column", "row"]}
        as="nav"
        zIndex={["modal", "modal", "modal", "base"]}
      >
        {children}
      </Flex>
      <Flex
        ml={["auto", "auto", "auto", 0]}
        alignItems="center"
        position="relative"
        flex="1"
        justifyContent="flex-end"
      >
        <Box
          alignItems="center"
          justifyContent="center"
          display={["none", "none", "none", "flex"]}
          height={["auto", "auto", "32px"]}
          fontWeight="medium"
          fontSize={1.75}
          lineHeight="high"
          color="grey700"
          transition="0.18s color ease-in-out, 0.18s background-color ease-in-out"
          p={0.5}
          mr={2}
          borderBottom="1px"
          borderBottomWidth="3px"
          borderBottomColor="grey900"
        >
          shop
        </Box>
        <Box
          alignItems="center"
          justifyContent="center"
          display={["none", "none", "none", "flex"]}
          height={["auto", "auto", "32px"]}
          fontWeight="medium"
          fontSize={1.75}
          lineHeight="high"
          color="grey700"
          transition="0.18s color ease-in-out, 0.18s background-color ease-in-out"
          p={0.5}
        >
          <Link
            href={`https://pro.mycreation.lighting.philips.com/${locale}-eu`}
            target="blank"
          >
            <a>professional</a>
          </Link>
        </Box>
        <Box width="1px" height="32px" bg="grey300" ml={2} mr={2} />

        {getLocales()?.length > 1 && (
          <Box
            alignItems="center"
            justifyContent="center"
            display={["none", "none", "none", "flex"]}
          >
            <DynamicLanguageSwitch />

            <Box width="1px" height="32px" bg="grey300" ml={2} mr={2} />
          </Box>
        )}

        <NavigationButton
          mr={1.5}
          to="/search"
          className={pathname === "/search" ? "active" : undefined}
        >
          <Icon icon={Search} fontSize={3} />
        </NavigationButton>

        {customer ? (
          <>
            <Box mr={1.5} display={["none", "none", "none", "block"]}>
              <NavigationAccountDropdown />
            </Box>
            <NavigationButton
              mr={1.5}
              to={customer ? "/account" : "/login"}
              aria-label={intl.formatMessage(
                messages[customer ? "account" : "login"]
              )}
              display={["flex", "flex", "flex", "none"]}
            >
              <Icon icon={User} fontSize={3} />
            </NavigationButton>
          </>
        ) : (
          <NavigationButton
            mr={1.5}
            to={customer ? "/account" : "/login"}
            aria-label={intl.formatMessage(
              messages[customer ? "account" : "login"]
            )}
          >
            <Icon icon={User} fontSize={3} />
          </NavigationButton>
        )}

        <NavigationButton
          to="/cart"
          color={showCartOverlay ? "grey900" : "grey700"}
          aria-label={intl.formatMessage(messages.cart)}
        >
          <Icon icon={CartIcon} fontSize={3} />
          {!!badge && (
            <Badge
              position="absolute"
              top={["-4px", "-6px"]}
              right={["-4px", "-2px"]}
            >
              {badge}
            </Badge>
          )}
        </NavigationButton>

        <Hamburger onClick={() => setMobileMenuOpen(!mobileMenuOpen)} />

        <CartOverlay />
      </Flex>
    </Flex>
  );
};
