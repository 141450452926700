import React, { forwardRef, ComponentProps, ReactNode, Ref } from "react";
import {
  Flex,
  Icon,
  SystemProps,
  Box,
  Spinner,
  system,
  css,
} from "@storyofams/react-ui";
import { LinkProps } from "next/link";
import type { PolymorphicForwardRefExoticComponent } from "react-polymorphic-types";
import styled from "styled-components";
import { variant } from "styled-system";

import { NextLink as Link } from "../NextLink";
import { useGetHref } from "~lib/getLinkProps";

const _defaultElement = "button";

const sharedButton = {
  fontSize: [1.5, 1.75],
  px: 4,
  py: "1.6rem",
  lineHeight: "high",

  borderRadius: "full",
  borderWidth: "0.2rem",
  borderStyle: "solid",
  padding: ["0.8rem 1.6rem", "1.6rem 3.2rem"],
};

const variants = {
  primary: {
    bg: "primary",
    borderColor: "primary",
    color: "white",

    ...sharedButton,

    "&:hover, &:focus, &:active": {
      bg: "primary600",
      borderColor: "primary600",
    },
  },

  secondary: {
    bg: "secondary200",
    borderColor: "secondary200",
    color: "grey200",

    ...sharedButton,

    "&:hover, &:focus, &:active": {
      bg: "primary400",
      borderColor: "primary400",
    },
  },

  inverted: {
    bg: "grey100",
    borderColor: "grey100",
    color: "grey900",

    ...sharedButton,

    "&:hover, &:focus, &:active": {
      bg: "grey300",
      borderColor: "grey300",
    },
  },

  outline: {
    borderColor: "grey900",
    color: "grey900",

    ...sharedButton,

    "&:hover, &:focus, &:active": {
      bg: "grey300",
    },
  },

  invertedOutline: {
    borderColor: "grey100",
    color: "grey100",

    ...sharedButton,

    "&:hover, &:focus, &:active": {
      bg: "rgba(251, 251, 251, 0.175)",
    },
  },

  secondaryOutline: {
    borderColor: "primary",
    color: "white",
    bg: "primary",

    ...sharedButton,

    "&:hover, &:focus, &:active": {
      bg: "grey300",
    },
  },

  halfOutline: {
    borderColor: "grey700",
    color: "grey700",

    ...sharedButton,

    padding: ["0.6rem 2.4rem", "0.8rem 2.8rem"],
  },

  link: {
    fontSize: 1.5,
    display: "inline-flex",
    color: "grey900",
    letterSpacing: "0.05em",
  },

  "link-md": {
    fontSize: [1.5, 1.75],
    display: "inline-flex",
    color: "grey700",
    fontWeight: "medium",
  },

  unstyled: {},
};

export type ButtonProps = {
  isLoading?: boolean;
  icon?: ComponentProps<typeof Icon>["icon"];
  href?: string;
  target?: string;
  rel?: string;
  to?: LinkProps["href"];
  shallow?: boolean;
  variant?: keyof typeof variants;
  disabled?: boolean;
  children: ReactNode;
  onClick?(e?: any): void;
  className?: string;
};

const StyledButton = styled(Box).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<Pick<ButtonProps, "variant">>`
  position: relative;
  appearance: none;
  text-decoration: none;

  ${(p) =>
    p.variant !== "unstyled" &&
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",

      fontWeight: "medium",
      textAlign: "center",

      userSelect: "none",
      transition:
        "background-color 0.18s ease-in-out, box-shadow 0.18s, border-color 0.18s ease-in-out, color 0.18s ease-in-out, opacity 0.18s ease-in-out",

      "&:disabled:not([data-is-loading])": {
        opacity: 0.25,
        cursor: "not-allowed",
      },

      "&[data-is-loading]": { cursor: "wait", opacity: "1" },
      "&:active, &:focus": { boxShadow: "none", outline: "0.1rem" },
    })}

  ${variant({ variants })}
  ${system}
`;

export const LegacyButton: PolymorphicForwardRefExoticComponent<
  ButtonProps & SystemProps,
  typeof _defaultElement
> = forwardRef(
  (
    { children, icon, to, shallow, isLoading, ...props }: ButtonProps,
    ref: Ref<any>
  ) => {
    const { getHref } = useGetHref();
    const content = (
      <Flex
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {!!icon && <Icon icon={icon} fontSize="1.3rem" mr={1.25} />}
        {children}
      </Flex>
    );

    if (isLoading) {
      return (
        <StyledButton
          as={_defaultElement}
          variant="primary"
          {...props}
          ref={ref}
          position="relative"
          data-is-loading
          aria-disabled
          disabled
          className=""
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Spinner color="inherit" size={18 as any} />
          </Box>
          <Box display="flex" justifyContent="center" color="transparent">
            {content}
          </Box>
        </StyledButton>
      );
    }

    if (to) {
      return (
        <Link href={to} passHref shallow={shallow}>
          <StyledButton as="a" variant="link" {...props}>
            {content}
          </StyledButton>
        </Link>
      );
    }

    return (
      <StyledButton
        as={props?.href ? "a" : _defaultElement}
        variant={props?.href ? "link" : "primary"}
        {...props}
        href={getHref(props?.href)}
        ref={ref}
      >
        {content}
      </StyledButton>
    );
  }
);
