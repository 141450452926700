import React, { ReactNode } from "react";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import { Box, Icon, css } from "@storyofams/react-ui";
import { FR, NL, GB, SE, DK, BE, ES } from "country-flag-icons/react/3x2";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";

import "@reach/menu-button/styles.css";

import { getLocales } from "~lib";
import { ChevronDown } from "~components/common/Icons";
import { NavigationTitle } from "~components/common/Navigation/components/NavigationTitle";

const LANGUAGES = {
  en: { label: "English", icon: GB },
  fr: { label: "Français", icon: FR },
  nl: { label: "Nederlands", icon: NL },
  sv: { label: "Svenska", icon: SE },
  da: { label: "Dansk", icon: DK },
  es: { label: "España", icon: ES },
};

export const StyledMenuButton = styled.button`
  && {
    appearance: none;
    border: none;
    background-color: transparent;
    color: ${(p) => p.theme.colors.primary400};
    font-size: 9px;
    line-height: 25px;
    user-select: none;
    width: 100%;
    cursor: pointer;
    transition: color 0.2s ease-out;

    &.nested-item {
      padding: 12px;
      @media (max-width: ${(p) => p.theme.breakpoints.md}) {
        padding: initial;
      }
    }

    &[aria-expanded="true"] {
      color: ${(p) => p.theme.colors.primary600};
    }

    &.footer-switch {
      width: 190px;
    }
  }

  .chevron-down {
    transition: transform ease-in-out 0.2s;
  }

  &[aria-expanded="true"] .chevron-down {
    transform: rotate(180deg);
  }

  &[aria-expanded="true"] .chevron-down-nested {
    transform: rotate(-90deg);
  }
`;

export const StyledMenuList = styled.div`
  && {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 12px 12px;
    width: 100%;
    background: ${(p) => p.theme.colors.grey200};
    border: none;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

    &:not(.menu-as-button) {
      margin-top: 16px;
    }
  }

  > * {
    position: relative;
    display: block;
    width: 100%;

    font-weight: ${(p) => p.theme.fontWeights.medium};
    font-size: ${(p) => p.theme.fontSizes[1.75]}px;
    line-height: ${(p) => p.theme.lineHeights.high};
    color: ${(p) => p.theme.colors.grey700};
    transition: 0.18s color ease-in-out;

    &:hover {
      color: ${(p) => p.theme.colors.primary};
    }

    &:not(:first-child) {
      margin-top: ${(p) => p.theme.space[1]}px;
    }

    @media (max-width: ${(p) => p.theme.breakpoints.md}) {
      line-height: ${(p) => p.theme.lineHeights.normal};
    }
  }
`;

export const StyledItem = styled.a`
  && {
    display: inline-block;
    padding: 6px 12px;
    text-decoration: none;
    min-width: 100%;
    border-radius: ${(p) => p.theme.radii.md};
    transition: background-color 0.18s ease, color 0.18s ease;

    .nested-item {
      padding: 12px;
    }

    &[data-selected],
    &:hover {
      background-color: ${(p) => p.theme.colors.primary200};
      color: ${(p) => p.theme.colors.primary};
    }
  }
`;

export type LanguageSwitchProps = {
  button?: boolean;
  footer?: boolean;
  children?: ReactNode;
};

export const LanguageSwitch = ({
  button,
  footer = false,
  ...props
}: LanguageSwitchProps) => {
  const { pathname, locale: routerLocale, query } = useRouter();

  if (process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ORIGIN.includes("belgium")) {
    (LANGUAGES.nl.icon = BE), (LANGUAGES.fr.icon = BE);
  }
  const locale = (routerLocale || process.env.DEFAULT_LOCALE)?.toUpperCase();

  const content = (
    <NavigationTitle>
      <Box overflow="hidden" mr={1} mt="-3px">
        {React.createElement(LANGUAGES?.[locale.toLowerCase()]?.icon, {
          width: "22px",
          height: "16px",
        })}
      </Box>

      {locale}

      <Icon
        icon={ChevronDown}
        fontSize={2.5}
        className="chevron-down"
        mt={["-2px", "-2px", "-3px"]}
      />
    </NavigationTitle>
  );

  const buttonContent = (
    <Box
      css={css({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "grey100",
        color: "grey100",
        px: 4,
        py: [1.5, 1.75],
        borderRadius: "xs",
        fontSize: [1.5, 1.75],
        lineHeight: "normal",

        "> div": {
          color: "grey100",
        },
      })}
    >
      <Box overflow="hidden" mr={1}>
        {React.createElement(LANGUAGES?.[locale.toLowerCase()]?.icon, {
          width: "22px",
          height: "16px",
        })}
      </Box>

      {LANGUAGES?.[locale.toLowerCase()]?.label}

      <Icon icon={ChevronDown} fontSize={2} className="chevron-down" ml={0.5} />
    </Box>
  );

  return (
    <Menu {...props}>
      <MenuButton as={StyledMenuButton} className={footer && "footer-switch"}>
        {button ? buttonContent : content}
      </MenuButton>
      <MenuList className={button && "menu-as-button"} as={StyledMenuList}>
        {getLocales().map((value) => (
          <Link key={value} href={{ pathname, query }} passHref locale={value}>
            <MenuItem
              key={value}
              as={StyledItem}
              onSelect={() => {
                // do nothing
              }}
            >
              {LANGUAGES?.[value]?.label}
            </MenuItem>
          </Link>
        ))}
      </MenuList>
    </Menu>
  );
};
