import { StyleItem } from "~lib/storyblok/hooks";
import { Image } from "@storyofams/storyblok-toolkit";
import styles from "./FullWidthBannerWithImage.module.scss";
import { Container } from "~components/atoms";

type Props = {
  image: StyleItem["content"]["image"];
  title: StyleItem["content"]["title"];
  content: StyleItem["content"];
  className?: string;
};

export const FullWidthBannerWithImage = (props: Props) => {
  const { title, image } = props.content;

  return (
    <div className={styles.imageBannerWrapper}>
      <Image src={image.filename} className={styles.image} />
      <div className={styles.overlay}></div>
      <Container>
        <h1 className={styles.title}>{title}</h1>
      </Container>
    </div>
  );
};
