import { Spinner } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import { FormattedMessage, defineMessages } from "react-intl";
import styled from "styled-components";

import { LegacyButton, Heading, ProductCard } from "~components";
import { useFilters } from "~hooks/useFilters";

const ProductCardWrapper = styled("div")<{ withFilters: boolean }>`
  grid-column: ${({ withFilters }) => (withFilters ? "span 3" : "span 4")};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-column: span 4;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    grid-column: span 6;
  }
`;

const messages = defineMessages({
  error: "Error loading products",
  noResults: "No products found",
  reset: "Reset filters",
});

interface ProductsProps {
  products: "loading" | "error" | { [key: string]: any };
  withFilters?: boolean;
}

export const Products = ({ products, withFilters }: ProductsProps) => {
  const { query } = useRouter();
  const { onReset } = useFilters();

  if (products == "loading") {
    return <Spinner minHeight="400px" />;
  } else if (products == "error") {
    return (
      <Heading variant="h3" as="h3" gridColumn={"span 9"}>
        <FormattedMessage {...messages.error} />
      </Heading>
    );
  } else if (!products?.edges?.length) {
    return (
      <>
        <Heading variant="h3" as="h3" gridColumn={"span 9"}>
          <FormattedMessage {...messages.noResults} />
        </Heading>
        {!!query?.filters && (
          <LegacyButton
            variant="primary"
            mt={3}
            onClick={onReset}
            gridColumn={"span 3"}
            gridRow={2}
          >
            <FormattedMessage {...messages.reset} />
          </LegacyButton>
        )}
      </>
    );
  }

  return (
    <>
      {products?.edges?.map(({ node }) => {
        return (
          <ProductCardWrapper
            data-test={node?.title}
            key={node?.id}
            withFilters={withFilters}
          >
            <ProductCard {...node} />
          </ProductCardWrapper>
        );
      })}
    </>
  );
};
