import * as Slider from "@radix-ui/react-slider";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";

type SliderInputProps = {
  name: string;
  min: number;
  max: number;
  unit: string;
  onChange: (name: string, value: string, replace?: boolean) => void;
};

export const SliderInput = ({
  name,
  min,
  max,
  unit,
  onChange,
}: SliderInputProps) => {
  const [currentRange, setCurrentRange] = useState([min, max]);
  const { query } = useRouter();

  useEffect(() => {
    let queryValue = query[name];
    if (Array.isArray(queryValue)) queryValue = queryValue.join("-");
    const range = queryValue?.split("-").map((value) => parseInt(value));
    if (range) setCurrentRange(range.slice(0, 2));
    if (!range && (currentRange[0] !== min || currentRange[1] !== max))
      setCurrentRange([min, max]);
  }, [query]);

  const onBlur = useCallback(() => {
    let onChangeValue = currentRange;
    if (onChangeValue[0] > onChangeValue[1])
      onChangeValue = [onChangeValue[1], onChangeValue[0]];
    if (onChangeValue[0] === min && onChangeValue[1] === max)
      onChange(name, "all", true);
    if (onChangeValue[0] < min) onChangeValue = [min, currentRange[1]];
    if (onChangeValue[1] > max) onChangeValue = [currentRange[0], max];

    onChange(name, onChangeValue.join("-"), true);
  }, [currentRange]);

  return (
    <>
      <div className={styles["input-wrapper"]}>
        <div className={styles["input-group"]}>
          <input
            type={"number"}
            value={currentRange[0]}
            onChange={(e) => {
              setCurrentRange([parseInt(e.target.value), currentRange[1]]);
            }}
            onBlur={onBlur}
            className={styles["input"]}
            data-testid={"min-input-" + name}
          />
          <label className={styles["label"]}>{unit}</label>
        </div>
        <div className={styles["input-group"]}>
          <input
            type={"number"}
            value={currentRange[1]}
            onChange={(e) => {
              setCurrentRange([currentRange[0], parseInt(e.target.value)]);
            }}
            onBlur={onBlur}
            className={styles["input"]}
            data-testid={"max-input-" + name}
          />
          <label className={styles["label"]}>{unit}</label>
        </div>
      </div>
      <Slider.Root
        min={min}
        max={max}
        value={currentRange}
        onValueChange={(values) => setCurrentRange(values)}
        className={styles["slider-root"]}
        onPointerUp={(event) => {
          const target = event.target as HTMLElement;
          target.releasePointerCapture(event.pointerId);
          if (currentRange[0] === min && currentRange[1] === max)
            onChange(name, "all", true);
          else onChange(name, currentRange.join("-"), true);
        }}
      >
        <Slider.Track className={styles["slider-track"]}>
          <Slider.Range className={styles["slider-range"]} />
        </Slider.Track>
        <Slider.Thumb className={styles["slider-thumb"]} />
        <Slider.Thumb className={styles["slider-thumb"]} />
      </Slider.Root>
    </>
  );
};
