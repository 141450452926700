import React, { useState, ReactNode } from "react";
import { Stack, Box, SystemProps } from "@storyofams/react-ui";

import { LegacyButton } from "../Button";
import { Text } from "../Text";

interface Tab {
  label: string;
  slug: string;
}

export interface TabsProps extends SystemProps {
  selectedTab?: number;
  tabs: string[] | Tab[];
  children?: ReactNode;
  shallow?: boolean;
}

export const Tabs = ({
  selectedTab: selectedTabProp,
  tabs,
  children,
  px,
  shallow,
  ...props
}: TabsProps) => {
  const [selectedTabState, setSelectedTab] = useState(0);

  const selectedTab =
    selectedTabProp !== undefined ? selectedTabProp : selectedTabState;

  return (
    <Box {...props}>
      <Box
        width="100%"
        borderBottomStyle="solid"
        borderBottomWidth="1px"
        borderBottomColor="grey300"
        mb={3}
      >
        <Stack space={4} overflowX="auto" mb="-1px" pl={px}>
          {tabs.map((tab, idx) => (
            <Box
              position="relative"
              height="100%"
              key={tab?.slug || tab}
              whiteSpace="nowrap"
            >
              {tab?.slug ? (
                <LegacyButton
                  variant="link"
                  color={selectedTab === idx ? "primary" : "grey700"}
                  pb={[0.75, 1.5]}
                  to={tab?.slug}
                  shallow={shallow}
                  fontSize={"1.7rem"}
                  letterSpacing="-0.01em"
                >
                  {tab?.label}
                </LegacyButton>
              ) : (
                <Text
                  color={selectedTab === idx ? "primary" : "grey700"}
                  variant="medium"
                  pb={[0.75, 1.5]}
                  fontSize={"1.7rem"}
                  onClick={() => setSelectedTab(idx)}
                  cursor="pointer"
                >
                  {tab}
                </Text>
              )}
              <Box
                height="2px"
                bg="primary"
                width="100%"
                position="absolute"
                bottom={0}
                overflow="hidden"
                opacity={selectedTab === idx ? 1 : 0}
                transition="opacity .3s"
              />
            </Box>
          ))}
          {!!px && <Box pr={px} />}
        </Stack>
      </Box>
      {React.Children.map(children, (child, index) => (
        <div key={index}>{index === selectedTab && child}</div>
      ))}
    </Box>
  );
};
