
import Image from "next/image";

import { Button, Container, Grid } from "~components";
import { RichTitle } from "~components/common/RichTitle";
import { useGetHref } from "~lib";

import ReactPlayer from "react-player";
import storyblokImageLoader from "~lib/storyblok/imageLoader";
import { useIsMobile } from "~hooks";
import styles from "./HeaderFullscreen.module.scss"
import clsx from "clsx";
import { ArrowRight } from "~components/common/Icons";

type HeaderProps = {
  first?: boolean;
  content: {
    title?: string | any;
    subtitle?: string;
    visual?: (
      | {
          component: "full-width-video";
          video: string;
          mobile_video?: string;
        }
      | {
          component: "image";
          image: { filename: string; alt: string };
        }
    )[];
    button_link: {
      cached_url: string;
      linktype: "url" | "story" | "page" | "asset" | "email" | "tel";
      story: {
        full_slug: string;
      };
    };
    button_text: string;
  };
};

export const HeaderFullscreen = ({
  content,
  first = false,
  ...props
}: HeaderProps) => {
  const isMobile = useIsMobile();
  const { getHref } = useGetHref();
  if (!content) return null;
  const visual = content?.visual?.[0];

  return (
    <div
      {...props}
      className={clsx(styles.headerFullscreen, {
        [styles.first]: first,
      })}
    >
      <div className={clsx(styles.visualWrapper, {
        [styles.isVideo]: visual?.component === "full-width-video",
      })}>
        {visual?.component === "full-width-video" && visual?.video ? (
          <ReactPlayer
            url={
              isMobile && visual?.mobile_video
                ? visual?.mobile_video
                : visual?.video
            }
            width="100%"
            height="100%"
            playing={true}
            playsInline
            muted={true}
            controls={false}
            config={{
              youtube: {
                playerVars: {
                  loop: true,
                },
              },
              vimeo: {
                playerOptions: {
                  loop: true,
                  playsinline: 1,
                },
              },
            }}
            wrapper={(props) => <div className={styles.reactPlayerWrapper} {...props}/>}
          />
        ) : visual?.component === "image" && visual?.image?.filename ? (
          <Image
            src={visual?.image.filename}
            alt={visual?.image.alt}
            loader={storyblokImageLoader}
            layout={"fill"}
            objectFit={"cover"}
          />
        ) : null}
      </div>
      <div className={styles.cta}>
        <Container>
          <Grid>
            {first ? (
              <h1 className={styles.title}>
                <RichTitle title={content?.title} />
              </h1>
            ) : (
              <h2 className={styles.subtitle}>
                <RichTitle title={content?.title} />
              </h2>
            )}
            {content?.subtitle && <p className={styles.paragraph}>{content?.subtitle}</p>}
            {content?.button_link && content?.button_text && (
              <Button
                as="link"
                href={getHref(content?.button_link)}
                color={"primary"}
                className={styles.button}
              >
                {content?.button_text} <ArrowRight />
              </Button>
            )}
          </Grid>
        </Container>
      </div>
    </div>
  );
};
