import { motion } from "framer-motion";
import styled from "styled-components";

export const Placeholder = styled(motion.div)`
  background: linear-gradient(
    90deg,
    ${(p) => p.theme.colors.grey300} 40%,
    ${(p) => p.theme.colors.grey200} 50%,
    ${(p) => p.theme.colors.grey300} 60%
  );
  background-size: 100vw 100%;

  animation: Animation 2s linear infinite;
  @keyframes Animation {
    0% {
      background-position: 0%;
    }
    50% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }
`;
