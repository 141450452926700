import { GetStaticPropsContext, PreviewData } from "next";
import { ParsedUrlQuery } from "querystring";
import { loadMessages } from "./loadMessages";
import { shopifyAdminSdk } from "./shopify-admin/client";
import { getShopifySdk } from "./shopify/client";
import storyblokSDK from "./storyblok/client";

export const globalStaticProps =
  (getStaticProps) =>
  async (context: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => {
    const { locale } = context;
    const [
      { FooterItem: footer },
      { NavigationItem: navigation },
      { discountNodes: discounts },
      staticProps,
      messages,
    ] = await Promise.all([
      storyblokSDK(2).footerItem({
        slug: `${locale}/settings/footer`,
      }),
      storyblokSDK(2).navigationItem({
        slug: `${locale}/settings/navigation`,
      }),
      shopifyAdminSdk.discount().catch(() => ({ discountNodes: [] })),
      getStaticProps(context),
      loadMessages(context),
    ]);

    return {
      ...staticProps,
      props: {
        ...(staticProps as any).props,
        footer,
        navigation,
        discounts,
        messages,
      },
    };
  };

export const mapBody = async (story, locale) => {
  story.content.body = await Promise.all(
    story.content.body.map(async (item) => {
      switch (item.component) {
        case "our-products": {
          item.products.item = (
            await Promise.all(
              item.products.item.map(async (product) => {
                const returnProduct = await getShopifySdk(locale)
                  .productByHandle({ handle: product?.blob?.handle })
                  .then((product) => product?.productByHandle);
                return returnProduct;
              })
            )
          ).filter((item) => item);
          return item;
        }
        case "blog-posts": {
          item.pages = await Promise.all(
            item?.pages.map(async (page) => {
              const res = await storyblokSDK(3).home({
                slug: `${locale}/home`,
              });
              page.content.category = res.BlogcategoryItems.items.find(
                (cat) => cat.uuid === page.content.category
              )?.name;
              return page;
            })
          );
          return item;
        }
      }
      return item;
    })
  );
  return story;
};
