/* eslint-disable react-hooks/rules-of-hooks */
import { useRouter } from "next/router";
import { defineMessages, useIntl } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";

import { showToast } from "~lib";
import { errorHandler } from "~lib/handleError";
import { handlePromise } from "~lib/handlePromise";
import { getClient } from "~lib/shopify/client";
import {
  CustomerQuery,
  useCustomerQuery,
  useCustomerResetMutation,
  useCustomerCreateMutation,
  useCustomerUpdateMutation,
  useCustomerRecoverMutation,
  useCustomerActivateMutation,
  useCustomerAddressCreateMutation,
  useCustomerAddressDeleteMutation,
  useCustomerAddressUpdateMutation,
  useCustomerAccessTokenCreateMutation,
  useCustomerAccessTokenDeleteMutation,
  useCustomerDefaultAddressUpdateMutation,
} from "~lib/shopify/hooks";

import { useShopify } from "./index";
import { useCallback } from "react";

const messages = defineMessages({
  alertMessage: "Something went wrong",
  loginFail: "Invalid login credentials",
  passwordResetSuccess: "Password was reset",
  activateSuccess: "Account activated!",
});

export const useCustomer = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  const shopifyState = useShopify();
  const { accessToken, setAccessToken } = shopifyState;

  const client = getClient(router?.locale);

  const useCustomerPromise = useCallback((accessToken) => {
    return useCustomerQuery.fetcher(getClient(router.locale), {
      token: accessToken,
    })();
  }, []);

  const login = useCustomerAccessTokenCreateMutation(client, {
    onSuccess: (res) => {
      const accessToken =
        res.customerAccessTokenCreate?.customerAccessToken?.accessToken;
      const error =
        res.customerAccessTokenCreate?.customerUserErrors?.[0]?.message;

      if (error) throw new Error(error);

      setAccessToken(accessToken);

      router.push(
        "/account",
        router.query.as as string
      );

      useCustomerPromise(accessToken).then((res) => {
        if (typeof window === "undefined") return;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login",
          user_id: res.customer.id,
          type: "user account",
        });
      });
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  const accessTokenDelete = useCustomerAccessTokenDeleteMutation(client, {
    onSuccess: (res) => {
      if (res.customerAccessTokenDelete?.userErrors?.[0])
        throw new Error(res.customerAccessTokenDelete.userErrors[0]?.message);

      setAccessToken("");
      router.push("/");
    },
    onError: () => {
      showToast(formatMessage(messages.alertMessage), "error");
      setAccessToken("");
      router.push("/");
    },
  });

  const logout = async () => {
    if (!accessToken) {
      router.push("/");
      return;
    }

    accessTokenDelete.mutate({ token: accessToken });

    useCustomerPromise(accessToken).then((res) => {
      if (typeof window === "undefined") return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "logout",
        user_id: res.customer.id,
        type: "user account",
      });
    });
  };

  const signUp = useCustomerCreateMutation(client, {
    onSuccess: async (res, { input: { email, password } }) => {
      if (
        res.customerCreate?.customerUserErrors?.[0]?.code ===
        "CUSTOMER_DISABLED"
      ) {
        showToast(res.customerCreate.customerUserErrors[0].message, "success");
        return { disableForm: true };
      }

      if (res.customerCreate?.customerUserErrors?.[0]) {
        throw new Error(res.customerCreate.customerUserErrors[0]?.message);
      }

      await handlePromise(login.mutateAsync({ email, password }));

      if (typeof window === "undefined") return;
      if (res.customerCreate?.customer?.id) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "sign_up",
          user_id: res.customerCreate.customer.id,
          type: "user account",
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "signup",
          type: "newsletter",
          location: "account_creation",
        });
      }
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  const update = useCustomerUpdateMutation(client, {
    onMutate: async (update) => {
      // cancel queries if they are fired
      await queryClient.cancelQueries(
        useCustomerQuery.getKey({
          token: accessToken,
        })
      );

      // grab the current value
      const curCustomerQuery = queryClient.getQueryData<CustomerQuery>(
        useCustomerQuery.getKey({
          token: accessToken,
        })
      );

      // set the new query data
      queryClient.setQueryData<CustomerQuery>(
        useCustomerQuery.getKey({
          token: accessToken,
          // country: getCountryCode(shopifyState.country),
        }),
        { customer: { ...curCustomerQuery.customer, ...update } }
      );

      return { prev: curCustomerQuery };
    },
    onSuccess: (res) => {
      if (res.customerUpdate.customerUserErrors?.[0])
        throw new Error(res.customerUpdate.customerUserErrors[0]?.message);

      const resAccessToken =
        res.customerUpdate?.customerAccessToken?.accessToken;

      // set data on success with even more recent values
      queryClient.setQueryData<CustomerQuery>(
        useCustomerQuery.getKey({
          token: accessToken,
        }),
        { customer: res.customerUpdate.customer }
      );

      if (resAccessToken && resAccessToken !== accessToken) {
        setAccessToken(resAccessToken);
      }
    },
    onError: (error: any, _update, ctx: { prev: CustomerQuery }) => {
      showToast(
        error?.message ?? formatMessage(messages.alertMessage),
        "error"
      );
      // bring back prev value(s)
      queryClient.setQueryData<CustomerQuery>(
        useCustomerQuery.getKey({
          token: accessToken,
        }),
        { customer: ctx.prev.customer }
      );
    },
    onSettled: () => {
      // always invalidate queries when settled
      queryClient.invalidateQueries(
        useCustomerQuery.getKey({
          token: accessToken,
        })
      );
    },
  });

  const recover = useCustomerRecoverMutation(client, {
    onSuccess: (res) => {
      if (res.customerRecover?.customerUserErrors?.[0])
        throw new Error(res.customerRecover.customerUserErrors[0]?.message);
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  const reset = useCustomerResetMutation(client, {
    onSuccess: (res) => {
      if (res.customerReset?.customerUserErrors?.[0]?.message)
        throw res.customerReset.customerUserErrors[0].message;

      setAccessToken(res.customerReset?.customerAccessToken?.accessToken);

      showToast(formatMessage(messages.passwordResetSuccess), "success");
      router.push("/account");
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  const activate = useCustomerActivateMutation(client, {
    onSuccess: (res) => {
      if (res.customerActivate.customerUserErrors?.[0])
        throw res.customerActivate.customerUserErrors[0]?.message;

      queryClient.invalidateQueries(
        useCustomerQuery.getKey({
          token: accessToken,
        })
      );

      showToast(formatMessage(messages.activateSuccess), "success");
      router.push("/account");

      if (typeof window === "undefined") return;
      if (res.customerActivate?.customer?.id) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "activate_signup",
          user_id: res.customerActivate.customer.id,
          type: "user account",
        });
      }
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  const addressCreate = useCustomerAddressCreateMutation(client, {
    onSuccess: async (res, variables) => {
      if (res.customerAddressCreate.customerUserErrors?.[0])
        throw res.customerAddressCreate.customerUserErrors[0]?.message;

      if ((variables as any)?.isDefault) {
        setDefaultAddress.mutate({
          customerAccessToken: accessToken,
          addressId: res.customerAddressCreate.customerAddress.id,
        });
      }

      queryClient.invalidateQueries(
        useCustomerQuery.getKey({
          token: accessToken,
        })
      );
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  const addressUpdate = useCustomerAddressUpdateMutation(client, {
    onSuccess: (res, variables) => {
      if (res.customerAddressUpdate.customerUserErrors?.length)
        throw new Error(
          res.customerAddressUpdate.customerUserErrors
            .map((err) => err.message)
            .join(", ")
        );

      if ((variables as any)?.isDefault) {
        setDefaultAddress.mutate({
          customerAccessToken: accessToken,
          addressId: res.customerAddressUpdate.customerAddress.id,
        });
      }
      queryClient.invalidateQueries(
        useCustomerQuery.getKey({
          token: accessToken,
        })
      );
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  const addressDelete = useCustomerAddressDeleteMutation(client, {
    onSuccess: (res) => {
      if (res.customerAddressDelete.customerUserErrors?.length) {
        throw res.customerAddressDelete.customerUserErrors;
      }

      shopifyState.refetchCustomer();
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  const setDefaultAddress = useCustomerDefaultAddressUpdateMutation(client, {
    onSuccess: (res) => {
      if (res.customerDefaultAddressUpdate.customerUserErrors?.length) {
        throw res.customerDefaultAddressUpdate.customerUserErrors;
      }
    },
    onError: (error: Error) =>
      errorHandler.onError(error, {
        fallback: formatMessage(messages.alertMessage),
      }),
  });

  return {
    ...shopifyState,
    // auth actions
    login,
    logout,
    signUp,
    // customer actions
    update,
    recover,
    reset,
    activate,
    // address actions
    addressCreate,
    addressUpdate,
    addressDelete,
    setDefaultAddress,
  };
};
