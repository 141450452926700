import React, { forwardRef } from "react";
import { CustomCheckbox, CustomCheckboxProps } from "@reach/checkbox";
import { InputWrapper, InputWrapperProps } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { useId } from "react-id-generator";
import styled from "styled-components";

import { Text } from "~components/common/Text";

const StyledCheckbox = styled(CustomCheckbox)<{ css?: any }>`
  appearance: none;
  background-color: transparent;
  padding: 0;
  margin-right: 8px;
  border: none;

  position: relative;

  display: inline-flex;
  vertical-align: middle;

  width: 20px;
  min-width: 20px;
  height: 20px;

  &[data-reach-custom-checkbox] {
    input {
      position: relative;
      width: 100%;
      appearance: none;
      outline: none;
      background: ${({ theme }) => theme.colors.grey100};
      border-radius: ${({ theme }) => theme.radii.xs};
      border: 1px solid ${({ theme }) => theme.colors.grey900};
      transition: 0.18s box-shadow ease-in-out;

      &:before {
        content: "";

        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 3px);
        width: 5px;
        height: 12px;

        display: none;

        border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: ${(p) => p.theme.colors.white};

        transform: rotate(40deg);

        transition: border-color 0.15s, box-shadow 0.15s;
      }

      &:not([disabled]):hover,
      &:not([disabled]):focus {
        outline: none;
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.grey300};
      }
    }
  }

  &[data-reach-custom-checkbox][data-state="checked"] {
    input {
      background: ${({ theme }) => theme.colors.grey900};

      &:before {
        display: block;
      }
    }
  }

  ${(props) => props.css}
`;

type CheckboxProps = {
  id?: string;
  disabled?: boolean;
} & InputWrapperProps &
  Pick<CustomCheckboxProps, "checked" | "onChange">;

export const Checkbox = forwardRef<CustomCheckboxProps, CheckboxProps>(
  (
    {
      label,
      status = false,
      statusMessage = false,
      error,
      id: givenId,
      disabled,
      ...rest
    },
    ref
  ) => {
    const autoId = useId();
    const id = givenId || `checkbox-${autoId}`;

    return (
      <InputWrapper
        status={status}
        statusMessage={statusMessage}
        error={error}
        {...pick(rest)}
      >
        <Text
          as="label"
          htmlFor={id}
          variant="label"
          opacity={disabled ? 0.6 : 1}
          cursor={disabled ? "not-allowed" : "pointer"}
          fontWeight="regular"
          alignItems="center"
        >
          <StyledCheckbox
            id={id}
            ref={ref}
            mr={1}
            disabled={disabled}
            {...omit(rest)}
          />
          {label}
        </Text>
      </InputWrapper>
    );
  }
);
