import { Box } from "@storyofams/react-ui";
import { LinkProps as NextLinkProps } from "next/link";

import { NextLink } from "../NextLink";

interface LinkProps extends NextLinkProps {
  children: any;
  onClick?(e?: any): void;
  stylingProps?: any;
  href: string;
}

export const Link = ({ children, href, stylingProps, ...props }: LinkProps) => {
  const to = href || "/";
  const isExternal = /^https?:/.test(to);

  if (isExternal) {
    return <a {...stylingProps}>{children}</a>;
  }

  return (
    <Box {...stylingProps}>
      <NextLink href={to} passHref {...props}>
        <a>{children}</a>
      </NextLink>
    </Box>
  );
};
