import React, { FC } from "react";
import { Box, status, StatusMessage, SystemProps } from "@storyofams/react-ui";
import { pick } from "@styled-system/props";
import { useId } from "react-id-generator";

import { Text } from "../Text";

export interface InputWrapperProps extends SystemProps {
  statusMessage?: string;
  id?: string;
  label?: string;
  status?: status;
  error?: string;
}

export const InputWrapper: FC<InputWrapperProps & { id?: string }> = ({
  label,
  status,
  statusMessage,
  error,
  id: givenId,
  children,
  ...props
}) => {
  const autoId = useId();
  const id = givenId || `input-${autoId}`;

  return (
    <Box {...pick(props)}>
      {label && (
        <Text as="label" mb={1.5} htmlFor={id} variant="label">
          {label}
        </Text>
      )}

      {children}

      {(!!statusMessage || !!error) && (
        <StatusMessage
          fontWeight="regular"
          mt={1.5}
          {...(status === "default" ? { color: "grey700" } : {})}
          status={error ? "error" : status}
        >
          {error || statusMessage}
        </StatusMessage>
      )}
    </Box>
  );
};
