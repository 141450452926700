import { useEffect, useMemo, useState } from "react";
import { Icon } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import { defineMessages, useIntl } from "react-intl";

import { Pagination, LegacyButton } from "~components";
import { ProductTagsQuery, ProductTypesQuery } from "~lib/shopify/sdk";
import { CollectionsQuery, FiltersMappingQuery } from "~lib/storyblok/sdk";
import styles from "./ProductsGrid.module.scss";

import { Products } from "./Products"
import { SortBy } from "./SortBy"
import styled from "styled-components"
import { Cross, FilterSlider } from "~components/common/Icons"
import { ViewedProductsProvider } from "~context/ViewedProducts"
import { Filter } from "~components/organisms"
import { Grid, Container } from "~components/atoms"

const messages = defineMessages({
  filterAward: "Award",
  filterButton: "Filter",
  filterColor: "Color",
  filterHeight: "Height",
  filterPrice: "Price",
  filterRange: "Collection",
  filterStyle: "Style",
  filterType: "Categories",
  filterWidth: "Width",
  results: "results",
  result: "result",
  noResults: "No products found",
  filterCollection: "Product range",
  showResults: "Show results",
});

const allFilters = [
  {
    key: "type",
    type: "select",
    label: messages.filterType,
    defaultOpen: true,
  },
  {
    key: "color",
    type: "color",
    label: messages.filterColor,
    defaultOpen: true,
  },
  {
    key: "price",
    unit: "€",
    type: "slider",
    label: messages.filterPrice,
    defaultOpen: true,
  },
  {
    key: "style",
    type: "select",
    label: messages.filterStyle,
    defaultOpen: true,
  },
  {
    key: "width",
    unit: "cm",
    type: "slider",
    label: messages.filterWidth,
  },
  {
    key: "height",
    unit: "cm",
    type: "slider",
    label: messages.filterHeight,
  },
  {
    key: "range",
    type: "select",
    label: messages.filterRange,
  },
  {
    key: "awards",
    type: "select",
    label: messages.filterAward,
  },
];

interface ProductsProps {
  styles?: any[];
  collection?: any;
  filtersMapping: FiltersMappingQuery["FiltersItem"]["content"];
  quickLinks?: FiltersMappingQuery["FiltersItem"]["quick_links"];
  withFilters?: boolean;
  tags: ProductTagsQuery["productTags"];
  productTypes: ProductTypesQuery["productTypes"];
  collections?: CollectionsQuery["CollectionItems"];
}

const StyledGrid = styled(Grid)`
  grid-row-gap: 2.8rem;
`;

const ButtonsContainer = styled("div")<{ withFilters: boolean }>`
  grid-column: 9 / 13;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.4rem;
  margin-bottom: 2rem;
  width: 100%;
  gap: 2rem;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    justify-content: ${(props) =>
      props.withFilters ? "space-between" : "flex-end"};
    grid-column: 1 / span 12;
  }
`;

const OpenFilterButton = styled(LegacyButton)`
  display: none;
  font-size: 1.4rem;
  font-weight: bold;
  order: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;

const ApplyFiltersButton = styled(LegacyButton)`
  width: max-content;
  margin: 1.6rem 0;
`;

const ApplyFiltersWrapper = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.grey300};
`;

const FiltersModal = styled(Container)`
  z-index: ${(p) => p.theme.zIndices.overlay};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(p) => p.theme.colors.grey100};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 10rem;
`;

const FiltersContainer = styled.div`
  grid-column: 1 / 4;
  grid-row: 1 / 7;
  z-index: 1;
  display: block;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

const PaginationContainer = styled.div`
  padding-top: 6.8rem;
  grid-column: 4 / span 9;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: flex-end;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    grid-column: 1 / span 12;
    justify-content: center;
  }
`;

const ResultsSpan = styled.span`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.4;
  color: ${(p) => p.theme.colors.grey900};
  grid-column: 4 / 8;
  align-self: center;

  @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
    order: 1;
    width: 100%;
    grid-column: span 12;
  }
`;

export const ProductsGrid = ({
  tags,
  styles: allStyles,
  collection,
  collections,
  productTypes,
  filtersMapping,
  quickLinks,
  withFilters = false,
}: ProductsProps) => {
  const intl = useIntl();
  const { query, locale, ...router } = useRouter();
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const [filterCount, setFilterCount] = useState<
    { [index: string]: { [index: string]: number } } | "loading" | "error"
  >("loading");

  const [ranges, setRanges] = useState<
    | "loading"
    | "error"
    | {
        [key: string]: { min: number; max: number };
      }
  >({});

  const filterOptions = useMemo(() => {
    let filters: { [index: string]: any }[] = allFilters;
    if (filtersMapping) {
      filters = filters.map(({ key, label, type, ...rest }) => {
        const options =
          type !== "slider" &&
          filterCount !== "loading" &&
          filterCount !== "error"
            ? filtersMapping[key]?.map((option: any) => {
                if (key === "type" || key === "awards") {
                  return {
                    ...option,
                    count:
                      filterCount?.[key]?.[option.value.toLowerCase()] || 0,
                  };
                } else {
                  return {
                    ...option,
                    count:
                      filterCount?.[key]?.[option.tag.item.toLowerCase()] || 0,
                  };
                }
              })
            : filtersMapping?.[key];
        return {
          key,
          value: key,
          title: intl.formatMessage(label),
          type,
          tags,
          allStyles,
          collections,
          productTypes,
          defaultOpen: rest.defaultOpen,
          ...(type === "select" || type === "color"
            ? { options }
            : type === "slider"
            ? { ...ranges[key], unit: rest.unit }
            : {}),
        };
      });
    }

    return filters;
  }, [filterCount, ranges]);

  const [products, setProducts] = useState<
    "loading" | "error" | { [key: string]: any }
  >("loading");
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    let url = `/api/products?locale=${locale}&page=${query.page || 0}`;
    const params: string[] = [];

    if (collection) {
      params.push(`collection=${collection.handle}`);
    }

    const queryArray = [
      { key: "search", value: query.search },
      { key: "type", value: query.type },
      { key: "color", value: query.color },
      { key: "price", value: query.price },
      { key: "width", value: query.width },
      { key: "height", value: query.height },
      { key: "style", value: query.style },
      { key: "range", value: query.range },
      { key: "awards", value: query.awards },
      { key: "sortKey", value: query.sortKey, first: true },
      { key: "sortOrder", value: query.sortOrder, first: true },
    ];

    queryArray.forEach((q) => {
      if (q.value) {
        let value = q.value;
        if (Array.isArray(q.value))
          value = q.first ? q.value[0] : q.value.join(",");
        params.push(`${q.key}=${value}`);
      }
    });

    if (params.length > 0) url += `&${params.join("&")}`;

    setProducts("loading");
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res.ok) {
        setProducts("error");
        setTotal(0);
        return;
      }
      res.json().then((res) => {
        setProducts(res.products);
        setRanges({
          height: { ...res.heightRange },
          width: { ...res.widthRange },
          price: { ...res.priceRange },
        });
        setFilterCount(res.filterCount);
        setTotal(res.productCount);
      });
    });
  }, [query, locale, collection]);

  return (
    <Container>
      {quickLinks?.quick_links && (
        <Grid className={styles.quickLinksGrid}>
          <div className={styles.quickLinks}>
            {quickLinks.quick_links.map(({ title, url }) => {
              const searchParams = new URLSearchParams(url);
              const params = Object.fromEntries(searchParams.entries());
              const queryKeys = Object.keys(query);
              const disabled =
                queryKeys.length > 0 &&
                Object.keys(params).length === queryKeys.length &&
                queryKeys.every((key) => query[key] === params[key]);
              return (
                <LegacyButton
                  variant={disabled ? "primary" : "halfOutline"}
                  className={styles.quickLink}
                  onClick={(e) => {
                    e.preventDefault();
                    router.push(`?${url}`, undefined, { shallow: true });
                  }}
                  disabled={disabled}
                >
                  {title}
                </LegacyButton>
              );
            })}
          </div>
        </Grid>
      )}
      <Grid>
        <ResultsSpan>
          {total}{" "}
          {intl.formatMessage(messages[total === 1 ? "result" : "results"])}
        </ResultsSpan>
        <ButtonsContainer withFilters={withFilters}>
          {withFilters && (
            <OpenFilterButton
              variant={"outline"}
              onClick={() => {
                document.body.style.overflow = "hidden";
                setShowFiltersModal(true);
              }}
            >
              {intl.formatMessage(messages.filterButton)}
              <Icon
                icon={FilterSlider}
                fontSize={3}
                style={{ marginLeft: "0.8rem" }}
              />
            </OpenFilterButton>
          )}
          <SortBy />
        </ButtonsContainer>
      </Grid>
      <StyledGrid>
        {withFilters &&
          filtersMapping &&
          (showFiltersModal ? (
            <FiltersModal>
              <LegacyButton
                variant={"unstyled"}
                onClick={() => {
                  document.body.style.overflow = "auto";
                  setShowFiltersModal(false);
                }}
                style={{ position: "absolute", right: 0 }}
              >
                <Icon icon={Cross} fontSize={"5.2rem"} />
              </LegacyButton>
              <Filter filters={filterOptions} />
              <ApplyFiltersWrapper>
                <ApplyFiltersButton
                  onClick={() => {
                    document.body.style.overflow = "auto";
                    setShowFiltersModal(false);
                  }}
                >
                  {intl.formatMessage(messages.showResults)}
                </ApplyFiltersButton>
              </ApplyFiltersWrapper>
            </FiltersModal>
          ) : (
            <FiltersContainer>
              <Filter filters={filterOptions} />
            </FiltersContainer>
          ))}
        <ViewedProductsProvider eventIdentifier="Products Grid">
          <Products products={products} withFilters={withFilters} />
        </ViewedProductsProvider>
        {products !== "loading" && products !== "error" && (
          <PaginationContainer>
            <Pagination
              currentPage={+query?.page || 0}
              totalPages={products?.pageInfo?.totalPages}
            />
          </PaginationContainer>
        )}
      </StyledGrid>
    </Container>
  );
};
