import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import { Icon, Box, css } from "@storyofams/react-ui";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { User } from "~components/common/Icons";
import {
  StyledMenuButton,
  StyledMenuList,
  StyledItem,
} from "~components/common/LanguageSwitch";
import { Link } from "~components/common/Link";

const messages = defineMessages({
  myAccount: "My account",
  myOrders: "My orders",
  myProfile: "My profile",
  myAddresses: "My addresses",
  help: "Help & support",
  signOut: "Sign out",
  login: "Login",
  cart: "Shopping cart",
  logout: "Sign out",
});

const dropdownItems = [
  { label: "myAccount", path: "/account" },
  { label: "myOrders", path: "/account/orders" },
  { label: "myProfile", path: "/account/profile" },
  { label: "myAddresses", path: "/account/addresses" },
  { label: "help", path: "/pages/support" },
];

export const NavigationAccountDropdown = () => {
  const intl = useIntl();

  return (
    <Menu>
      <MenuButton as={StyledMenuButton}>
        <Box
          mr={0.5}
          aria-label={intl.formatMessage(messages.myAccount)}
          height={["auto", "auto", "32px"]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="medium"
          fontSize={1.75}
          lineHeight="high"
          color="grey700"
          transition="0.18s color ease-in-out, 0.18s background-color ease-in-out"
          p={0.5}
          borderRadius="full"
          css={css({
            "&:hover": { bg: ["transparent", "transparent", "grey200"] },
          })}
        >
          <Icon icon={User} fontSize={3} />
        </Box>
      </MenuButton>

      <MenuList as={StyledMenuList}>
        {dropdownItems.map(({ label, path }) => (
          <Link key={label} href={path} passHref>
            <MenuItem
              as={StyledItem}
              key={label}
              onSelect={() => {
                // do nothing
              }}
            >
              <FormattedMessage {...messages[label]} />
            </MenuItem>
          </Link>
        ))}
      </MenuList>
    </Menu>
  );
};
