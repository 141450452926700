import { defineMessages, useIntl } from "react-intl";
import storyblokEditable from "~lib/storyblokEditable";

import { Button, Container, Grid, ProductsSlider, RichText } from "~components";


import styles from "./OurProducts.module.scss";

const messages = defineMessages({
  allProducts: "All products",
});

export const OurProducts = ({ content }) => {
  const { formatMessage } = useIntl();
  const {
    title,
    call_to_action_title,
    call_to_action_link,
    products: { item: products },
    description,
    usp,
  } = content;

  if (!products || products.every((product) => product === null)) {
    return null;
  }

  return (
    <Container {...storyblokEditable(content)} className={styles.container}>
      <Grid>
        <div className={styles.informationWrapper}>
          <div className={styles.informationButtonWrapper}>
            {usp && <RichText text={usp} className={styles.usp} />}
            {call_to_action_link && (
              <Button as="link" color="primary" href={call_to_action_link} className={styles.desktopButton}>
                {call_to_action_title
                  ? call_to_action_title
                  : formatMessage(messages.allProducts)}
              </Button>
            )}
          </div>
          <div className={styles.informationTextWrapper}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{description}</p>
          </div>
        </div>
        <div className={styles.productWrapper}>
          <ProductsSlider products={products} />
          <Button as="link" color="primary" href={call_to_action_link} className={styles.mobileButton}>
            {call_to_action_title
              ? call_to_action_title
              : formatMessage(messages.allProducts)}
          </Button>
        </div>
      </Grid>
    </Container>
  );
};
