import React from "react";
import { Stack, Box, Icon, SystemProps } from "@storyofams/react-ui";
import { ReviewStarFull, ReviewStarHalf } from "../Icons";

export interface StarProps extends SystemProps {
  rating: number;
}
export const Stars = ({ rating, ...props }: StarProps) => {
  const roundedRating = Math.round(rating * 2) / 2;

  return (
    <Box {...props}>
      <Stack space={0.25}>
        {[1, 2, 3, 4, 5].map((i) => {
          switch (true) {
            case i <= roundedRating:
              return (
                <Icon
                  key={i}
                  fontSize={2}
                  color="grey300"
                  icon={<ReviewStarFull />}
                />
              );
            case i - 0.5 === roundedRating: //checks for half values
              return (
                <Icon
                  key={i}
                  color="grey300"
                  fontSize={2}
                  icon={<ReviewStarHalf />}
                />
              );
            default:
              return (
                <Icon
                  key={i}
                  fontSize={2}
                  color="grey200"
                  icon={<ReviewStarFull />}
                />
              );
          }
        })}
      </Stack>
    </Box>
  );
};

export default Stars;
