import { Button, Container, StyledImage } from "~components";
import { InviewLoader } from "~components/common/InviewLoader";
import storyblokEditable from "~lib/storyblokEditable";
import { useState } from "react";
import styles from "./FeaturedCollection.module.scss";

export const OurCollections = ({ content }) => {
  const collection = Array.isArray(content?.collections)
    ? content.collections[0]?.content
    : content.collections.content;
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Container
      {...storyblokEditable(content)}
      className={styles.featuredCollection}
    >
      <InviewLoader>
        <div className={styles.imageWrapper}>
          <StyledImage
            src={collection?.image?.filename}
            customLoader={"storyblok"}
            objectFit="cover"
            layout="fill"
            isHovering={isHovering}
          />
        </div>
        <div className={styles.overlay}>
          <h2 className={styles.title}>{content.title.toUpperCase()}</h2>
          <h3 className={styles.subtitle}>{collection.title}</h3>
          <Button
            className={styles.action_text}
            href={content.call_to_action_link}
            color="invertedOutline"
            as="link"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {content.call_to_action_text}
          </Button>
        </div>
      </InviewLoader>
    </Container>
  );
};
