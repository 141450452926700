import { SystemProps } from "@storyofams/react-ui";
import Link from "next/link";
import { useState } from "react";

import { StyledImage, Heading } from "~components";
import { useGetHref } from "~lib";
import { StyleItem } from "~lib/storyblok/hooks";
import styles from "./CollectionCard.module.scss";
import { clsx } from "clsx";

type CollectionCardProps = {
  image: StyleItem["content"]["image"];
  title: StyleItem["content"]["title"];
  to: StyleItem["full_slug"];
  className?: string;
} & SystemProps;

export const CollectionCard = (props: CollectionCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { title, image, to } = props;
  const { getHref } = useGetHref();
  return (
    <Link href={getHref(to)}>
      <a
        className={clsx(styles["card"], props.className)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <StyledImage
          src={image.filename}
          alt={image.alt}
          customLoader="storyblok"
          layout="fill"
          sizes={"(max-width: 1024px) 50vw, 33vw"}
          objectFit={"cover"}
          isHovering={isHovered}
        />
        <div className={styles["content-wrapper"]}>
          <Heading as="h2" variant="h4" className={styles["title"]}>
            {title}
          </Heading>
        </div>
      </a>
    </Link>
  );
};
