import { ReactNode } from "react";
import { clsx } from "clsx";
import { Box, SystemProps } from "@storyofams/react-ui";
import styles from "./Container.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
};

export const Container = ({ children, className }: Props) => {
  return <div className={clsx(styles.section, className)}>{children}</div>;
};

/**
 * @deprecated
 */
type LegacyContainerProps = {
  children: ReactNode;
} & SystemProps;

/**
 * @deprecated
 * Use the Container component instead
 */
export const LegacyContainer = ({
  children,
  ...props
}: LegacyContainerProps) => (
  <Box maxWidth="maxWidth" px={2} mx="auto" width="100%" {...props}>
    {children}
  </Box>
);
