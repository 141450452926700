const neutrals = {
  white: "#ffffff",
  grey100: "#FBFBFB",
  grey200: "#F5F6F6",
  grey300: "#E5E6E6",
  grey500: "#BDBFBF",
  grey700: "#6C6F6F",
  grey800: "#323434",
  grey900: "#090A0A",
  warning50: "#FEFCE8",
  warning100: "#FEF08A",
  warning300: "#FACC15",
  warning600: "#CA8A04",
  warning800: "#713F12",
  success50: "#F0FDF4",
  success100: "#BBF7D0",
  success300: "#6AD975",
  success600: "#16A34A",
  success800: "#14532D",
  error50: "#FEF2F2",
  error100: "#FEE2E2",
  error300: "#E82525",
  error600: "#DC2626",
  error800: "#7F1D1D",
  transparent: "rgba(255, 255, 255, 0);",
};

const primary = {
  primary200: "#CCD7D3",
  primary: "#729186",
  primary400: "#AABDB6",
  primary600: "#556E65",
};

const secondary = {
  secondary: "#A36931",
  secondary100: "#F6F0EA",
  secondary600: "#D89966",
};

const red = {
  redLight: "#A06571",
  redDark: "#782434",
};

const variants = {
  ...primary,
  ...secondary,
  ...red,
};

const colors = {
  ...variants,
  ...neutrals,
};

export default colors;
