import storyblokEditable from "~lib/storyblokEditable";
import { Button, Container, Grid, CollectionCard } from "~components";

import { useGetHref } from "~lib";
import { RichTitle } from "~components/common/RichTitle";
import { InviewLoader } from "~components/common/InviewLoader";
import styles from "./OurRooms.module.scss";

export const OurRooms = ({ content }) => {
  const { title, call_to_action_title, call_to_action_link, rooms } = content;
  const { getHref } = useGetHref();
  return (
    <Container {...storyblokEditable(content)} className={styles.container}>
      <Grid className={styles.grid}>
        <h2 className={styles.title}>
          <RichTitle title={title} />
        </h2>
        {rooms.map(({ content, full_slug }, idx) => {
          return (
            <InviewLoader key={idx} className={styles.cardWrapper}>
              <CollectionCard
                title={content.title}
                image={content.image}
                to={full_slug}
              />
            </InviewLoader>
          );
        })}
        <div className={styles.buttonWrapper}>
          {call_to_action_link && call_to_action_title && (
            <Button
              href={getHref(call_to_action_link)}
              color="primary"
              as="link"
            >
              {call_to_action_title}
            </Button>
          )}
        </div>
      </Grid>
    </Container>
  );
};
