import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Discount, DiscountCodeBasic, MoneyV2 } from "~lib/shopify-admin/sdk";

type DiscountContextType = {
  discount: Discount | undefined;
  getProductPrice: (product: any) => {
    original: MoneyV2;
    discounted?: MoneyV2 & { percentage?: number; discount?: number };
  };
};

type DiscountProviderProps = {
  children: ReactNode;
  value: DiscountContextType["discount"];
};

export const DiscountContext = createContext<DiscountContextType | undefined>(
  undefined
);

export const DiscountProvider = ({
  children,
  value,
}: DiscountProviderProps) => {
  const [discount, setDiscount] = useState<Discount | undefined>(
    value?.endsAt ? value : undefined
  );

  useEffect(() => {
    if (discount?.endsAt && new Date(discount?.endsAt) < new Date()) {
      setDiscount(undefined);
    }
  }, [discount?.endsAt && new Date(discount?.endsAt) < new Date()]);

  const getProductPrice = useCallback(
    (
      product: any
    ): {
      original: MoneyV2;
      discounted?: MoneyV2 & { percentage?: number; discount?: number };
    } => {
      const original =
        product?.priceRange?.minVariantPrice || product?.variant?.priceV2;
      let discounted: MoneyV2 & { percentage?: number; discount?: number };

      if (!discount?.endsAt) return { original };

      if (!original) {
        console.warn("No price found for product", product);
        return { original };
      }

      if (discount?.__typename === "DiscountCodeBasic") {
        if (DiscountApplies(discount, product)) {
          if (discount.customerGets.value.__typename === "DiscountPercentage") {
            discounted = {
              ...original,
              amount:
                Math.ceil(
                  original?.amount *
                    (1 - discount.customerGets.value.percentage) *
                    100
                ) / 100,
              percentage: discount.customerGets.value.percentage,
            };
          } else if (
            discount.customerGets.value.__typename === "DiscountAmount"
          ) {
            discounted = {
              ...original,
              amount:
                original?.amount - discount.customerGets.value.amount.amount,
              discount: discount.customerGets.value.amount.amount,
            };
          } else {
            console.warn(
              `Discounts of customerGets.value.type "${discount.customerGets.value.__typename}" is not supported yet`
            );
          }
        } else {
          console.warn(
            `Discounts of customerGets.type "${discount?.customerGets?.items?.__typename}" is not supported yet`
          );
        }
      } else if (discount) {
        console.warn(
          `Discounts of type "${discount?.__typename}" is not supported yet`
        );
      }

      return { original, discounted };
    },
    [discount]
  );

  return (
    <DiscountContext.Provider
      value={{
        discount: discount?.endsAt ? discount : undefined,
        getProductPrice,
      }}
    >
      {children}
    </DiscountContext.Provider>
  );
};

const DiscountApplies = (discount: DiscountCodeBasic, product: any): true => {
  if (discount.customerGets.items.__typename === "AllDiscountItems")
    return true;
  if (discount.customerGets.items.__typename === "DiscountCollections") {
    const handles = discount.customerGets.items.collections.nodes.map(
      ({ handle }) => handle
    );
    return product.collections.edges.some(({ node }) =>
      handles.includes(node.handle)
    );
  }
};
