import { Container, Grid } from "~components/atoms";
import storyblokEditable from "~lib/storyblokEditable";
import styles from "./Paragraph.module.scss"
import clsx from "clsx";

type ParagraphProps = {
  first?: boolean;
  content: any;
};

export const Paragraph = ({ content }: ParagraphProps) => {
  const { title, text, large_title, horizontal } = content;
  return (
    <Container {...storyblokEditable(content)}>
      <Grid className={clsx(styles.grid, {
        [styles.horizontal]: horizontal,
      })}>
        <h2 className={clsx(styles.title, {
          [styles.large]: large_title,
        })}>{title}</h2>
        <p className={styles.paragraph}>{text}</p>
      </Grid>
    </Container>
  );
}