import { motion } from "framer-motion";
import styled from "styled-components";
import { ShoppingBasket } from "../Icons";
import { Placeholder } from "../LoadingPlaceholder/LoadingPlaceholder";

export const Anchor = styled(motion.a)`
  cursor: pointer;
`;

export const ImgWrapper = styled.div`
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Discount = styled.div`
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  background: ${(p) => p.theme.colors.secondary};
  padding: 0.4rem 0.8rem;

  color: white;
  font-size: 1.4rem;
  line-height: 160%;
  font-weight: 600;
  text-transform: uppercase;
`;

export const CartButton = styled(motion.button)`
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.primary};

  margin: 1.6rem;
  position: absolute;
  bottom: 0px;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const Cart = styled(ShoppingBasket)`
  height: 3.2rem;
  width: 3.2rem;
`;

export const ProductInformation = styled.div`
  margin-top: 1.6rem;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: ${(p) => p.theme.colors.grey900};
  font-weight: 400;
`;

export const Heading3 = styled.h3`
  margin-top: 1.6rem;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: ${(p) => p.theme.colors.grey900};
  font-weight: 400;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;
`;

export const Price = styled.p<{ isDiscounted?: boolean }>`
  font-weight: 700;
  position: relative;
  width: fit-content;

  ${({ isDiscounted, theme }) =>
    isDiscounted &&
    `
      span:first-child {
        text-decoration: line-through;
        color: ${theme.colors.grey700};
      }

      span:nth-child(2) {
        margin-left: 0.8rem;
      }
    `}
`;

export const PlaceholderAnchor = styled(motion.div)``;

export const ImagePlaceholder = styled(Placeholder)`
  aspect-ratio: 1;
  border-radius: 0.8rem;
  margin-bottom: 2rem;
`;

export const TextPlaceholder = styled(Placeholder)`
  width: 100%;
  border-radius: 0.8rem;
  margin-top: 1rem;
  height: 1.8rem;
`;
