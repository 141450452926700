import React from "react";
import ReactSelect, { Props } from "react-select";
import styled, { css, useTheme } from "styled-components";

import { TetherComponent } from "./TetherComponent";

const StyledSelect = styled(ReactSelect).attrs({
  className: "react-select",
  classNamePrefix: "react-select",
})<any>(
  ({ styledTheme: theme }) => css`
    font-size: ${theme.fontSizes[1.75]}px;
    line-height: ${theme.lineHeights.high};
    width: max-content;

    /* leave font-size 16px for consistency sake, IOS browsers zoom in on inputs if they are below 16px */
    @media (max-width: ${theme.breakpoints.sm}) {
      font-size: ${theme.fontSizes[2]}px;
    }

    .react-select {
      &__placeholder {
        color: ${theme.colors.grey500};
        font-size: ${theme.fontSizes[1.75]}px;
        line-height: ${theme.lineHeights.high};
        position: relative;
        transform: none;

        @media (max-width: ${theme.breakpoints.sm}) {
          font-size: ${theme.fontSizes[2]}px;
        }
      }

      &__control {
        transition: border-color 0.18s ease;
        background: none;
        border-radius: ${theme.radii.full};
        border-color: ${theme.colors.grey500};
        cursor: pointer;

        &:hover {
          border-color: ${theme.colors.primary};
        }

        &--menu-is-open {
          .react-select__dropdown-indicator {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        &--is-disabled {
          cursor: not-allowed;
          opacity: 0.6;
          background: none;
        }

        &--is-focused {
          background: none;
          border-color: ${theme.colors.primary};
          box-shadow: none;

          .react-select__placeholder {
            color: ${theme.colors.white};
          }
        }
      }

      &__dropdown-indicator {
        color: ${theme.colors.grey900};

        &:hover {
          color: ${theme.colors.grey900};
        }
      }

      &__single-value {
        color: ${theme.colors.grey800};
        max-width: 100%;
        position: relative;
        transform: none;
      }

      &__value-container {
        padding: 6px 8px 4px 14px !important;
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
        width: auto;
        min-width: 80px;
        border-radius: 4px;
      }

      &__menu-list {
        padding: ${theme.space[1]}px;
      }

      &__option {
        padding: ${theme.space[0.25]}px ${theme.space[0.75]}px 0;
        margin-bottom: ${theme.space[0.5]}px;
        transition: border-color 0.18s ease-in-out,
          background-color 0.18s ease-in-out, color 0.18s ease-in-out;
        color: ${theme.colors.grey900};
        border-radius: 2px;
        line-height: 1.4;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          background-color: ${theme.colors.primary600};
          color: ${theme.colors.grey100};
        }

        & {
          &--is-focused {
            background-color: ${theme.colors.primary600};
            color: ${theme.colors.grey100};
          }

          &--is-selected,
          &--is-active {
            color: ${theme.colors.white};
            background-color: ${theme.colors.primary};
          }
        }
      }
    }
  `
);

const Menu = styled.div<{ styledTheme: any }>(
  ({ styledTheme: theme }) => css`
    .react-select {
      &__menu {
        width: auto;
        min-width: 80px;
        border-radius: 4px;
      }

      &__menu-list {
        padding: ${theme.space[1]}px;
      }

      &__option {
        padding: ${theme.space[0.25]}px ${theme.space[0.75]}px 0;
        margin-bottom: ${theme.space[0.5]}px;
        transition: border-color 0.18s ease-in-out,
          background-color 0.18s ease-in-out, color 0.18s ease-in-out;
        color: ${theme.colors.grey900};
        border-radius: 2px;
        line-height: 1.4;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          background-color: ${theme.colors.primary200};
          color: ${theme.colors.grey900};
        }

        & {
          &--is-focused {
            background-color: ${theme.colors.primary200};
            color: ${theme.colors.grey900};
          }

          &--is-selected,
          &--is-active {
            color: ${theme.colors.white};
            background-color: ${theme.colors.primary};
          }
        }
      }
    }
  `
);

const tetherOptions = {
  attachment: "top right",
  targetAttachment: "bottom right",
  constraints: [
    {
      to: "scrollParent",
      attachment: "together",
    },
  ],
};

const MenuPortal = ({ ...props }) => {
  const theme = useTheme();

  return (
    <TetherComponent options={tetherOptions} target={props.controlElement}>
      <Menu styledTheme={theme}>{props.children}</Menu>
    </TetherComponent>
  );
};

interface SelectProps extends Props {
  theme?: any;
}

const Select = (props: SelectProps) => {
  const theme = useTheme(); // react-select and styled-components both need a theme so it needs to be renamed

  return (
    <StyledSelect
      components={{ MenuPortal }}
      menuPortalTarget=".drawer"
      menuShouldScrollIntoView={false}
      styles={{
        menu: (provided) => ({
          ...provided,
          position: "static",
          zIndex: 99999999,
        }),
      }}
      styledTheme={theme}
      theme={(t) => ({
        ...t,
        colors: {
          ...t.colors,
          primary25: theme.colors.grey100,
          primary50: theme.colors.primary200,
          primary: theme.colors.grey100,
        },
      })}
      {...props}
    />
  );
};

export default Select;
