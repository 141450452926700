import { css } from "@styled-system/css";

import { LegacyButton } from "~components/common/Button";

export const NavigationButton = ({ children, ...props }) => {
  return (
    <LegacyButton
      height={["auto", "auto", "32px"]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontWeight="medium"
      fontSize={1.75}
      lineHeight="high"
      color="grey700"
      transition="0.18s color ease-in-out, 0.18s background-color ease-in-out"
      p={0.5}
      borderRadius="full"
      css={css({
        "&:hover": { bg: ["transparent", "transparent", "grey200"] },
      })}
      variant="unstyled"
      {...props}
    >
      {children}
    </LegacyButton>
  );
};
