import { Box, css } from "@storyofams/react-ui";
import { m } from "framer-motion";
import { defineMessages, useIntl } from "react-intl";
import { useToggle } from "react-use";

import { LegacyButton } from "~components/common/Button";

const MotionBox = m(Box);

const messages = defineMessages({
  readMore: "Read more",
  showLess: "Show less",
});

type ReadMoreProps = {
  content: any;
};

export const ReadMore = ({ content }: ReadMoreProps) => {
  const intl = useIntl();

  const [showMore, toggle] = useToggle(false);
  return (
    <Box>
      <MotionBox
        overflow="hidden"
        animate={{ height: !showMore ? "calc(3 * 20px)" : "100%" }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        css={css({
          "& p": {
            fontSize: "1.4rem",
          },
          ...(!showMore
            ? {
                "p:first-of-type": {
                  overflow: "hidden",
                  display: "-webkit-box",
                  textOverflow: "ellipsis",
                  "-webkit-line-clamp": "3",
                  "-webkit-box-orient": "vertical",
                },
                "p:not(:first-of-type)": {
                  opacity: !showMore ? 0 : 1,
                  height: !showMore ? 0 : "100%",
                },
              }
            : {}),
        })}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      <LegacyButton
        variant="link-md"
        color="primary600"
        onClick={toggle}
        mt={3}
        fontSize="1.4rem"
      >
        {showMore
          ? intl.formatMessage(messages.showLess)
          : intl.formatMessage(messages.readMore)}
      </LegacyButton>
    </Box>
  );
};
