import { Box, Icon, css } from "@storyofams/react-ui";
import { FooterItem } from "~lib/storyblok/sdk";

import { LegacyButton } from "../Button";
import { Heading } from "../Heading/heading";
import {
  Facebook,
  Instagram,
  Pinterest,
  Linkedin,
  Twitter,
  Youtube,
} from "../Icons";

type FooterProps = {
  footer: FooterItem;
};

const Link = ({ icon, link }) => (
  <li>
    <LegacyButton variant="link-md" href={link}>
      <Icon fontSize={3} icon={icon} color="grey100" />
    </LegacyButton>
  </li>
);

export const Socials = ({ footer }: FooterProps) => {
  return (
    <Box
      order={[1, 1, "unset"]}
      width={["100%", "100%", "auto"]}
      mb={[2, 2, 0]}
    >
      <Heading as="h4" variant="h4">
        {footer?.content?.social_media_title}
      </Heading>

      <Box
        as="ul"
        css={css({
          display: "flex",
          flex: 1,
          listStyleType: "none",
          flexFlow: "row wrap",
          mx: "-12px",
          maxWidth: ["none", "144px"],

          "& > li": {
            marginTop: 3,
            mx: 1.5,
          },
        })}
      >
        {footer?.content?.facebook && (
          <Link link={footer?.content?.facebook} icon={Facebook} />
        )}
        {footer?.content?.instagram && (
          <Link link={footer?.content?.instagram} icon={Instagram} />
        )}
        {footer?.content?.pinterest && (
          <Link link={footer?.content?.pinterest} icon={Pinterest} />
        )}
        {footer?.content?.twitter && (
          <Link link={footer?.content?.twitter} icon={Twitter} />
        )}
        {footer?.content?.youtube && (
          <Link link={footer?.content?.youtube} icon={Youtube} />
        )}
        {footer?.content?.linkedin && (
          <Link link={footer?.content?.linkedin} icon={Linkedin} />
        )}
      </Box>
    </Box>
  );
};
