import { PaginationArrowLeft, PaginationArrowRight } from "../Icons";
import { Icon } from "@storyofams/react-ui";
import { defineMessages, useIntl } from "react-intl";
import { LegacyButton } from "../Button";
import { useRouter } from "next/router";

interface Props {
  currentPage: number;
  totalPages: number;
}

const messages = defineMessages({
  previous: "previous",
  next: "next",
});

export const Pagination = ({ currentPage, totalPages }: Props) => {
  const intl = useIntl();
  const router = useRouter();

  return (
    <>
      <LegacyButton
        disabled={currentPage < 1}
        onClick={() =>
          router.push({
            pathname: router.pathname,
            query: {
              ...router.query,
              page: currentPage - 1,
            },
          })
        }
        variant="outline"
      >
        <Icon
          icon={PaginationArrowLeft}
          fontSize={3}
          style={{ marginRight: "8px" }}
        />
        {intl.formatMessage(messages.previous)}
      </LegacyButton>
      <LegacyButton
        disabled={currentPage >= totalPages}
        onClick={() =>
          router.push({
            pathname: router.pathname,
            query: {
              ...router.query,
              page: currentPage + 1,
            },
          })
        }
        variant="outline"
      >
        {intl.formatMessage(messages.next)}
        <Icon
          icon={PaginationArrowRight}
          fontSize={3}
          style={{ marginLeft: "8px" }}
        />
      </LegacyButton>
    </>
  );
};
