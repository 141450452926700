import { useEffect, useRef, useState } from "react";
import { withPasswordProtect } from "@storyofams/next-password-protect";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "nprogress/nprogress.css";

import { Providers, UserAgent } from "~components";
import { seo } from "~config";
import { useDetectKeyboard } from "~hooks";
import { Container as ToastContainer } from "~components/common/Toast/Container";
import CSSreset from "~styles/CSSreset";
import "../styles/index.scss";

import "react-toastify/dist/ReactToastify.min.css";
import { RedirectPopup } from "~components/organisms";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  import("~lib/sentry").then((m) => m.initSentry());
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const progressTimer = useRef<any>(null);
  const { locale, query } = router;

  useDetectKeyboard();

  useEffect(() => {
    const startProgress = () => {
      if (!progressTimer.current) {
        progressTimer.current = setTimeout(NProgress.start, 120);
      }
    };

    const endProgress = (err) => {
      if (progressTimer.current) {
        clearTimeout(progressTimer.current);
        progressTimer.current = null;

        if (err?.cancelled) {
          NProgress.set(0.0);
          NProgress.remove();
        } else {
          NProgress.done();
        }
      }
    };

    router.events.on("routeChangeStart", startProgress);
    router.events.on("routeChangeComplete", endProgress);
    router.events.on("routeChangeError", endProgress);

    return () => {
      router.events.off("routeChangeStart", startProgress);
      router.events.off("routeChangeComplete", endProgress);
      router.events.off("routeChangeError", endProgress);
    };
  }, [router.events]);

  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (query.from === "splash") {
      setRedirected(true);
    }
  }, [query.from]);

  return (
    <Providers pageProps={pageProps} locale={locale}>
      <CSSreset />
      <DefaultSeo {...seo} />
      <UserAgent />
      {redirected && (
        <RedirectPopup
          close={() => {
            setRedirected(false);
          }}
        />
      )}
      <Component {...pageProps} />
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </Providers>
  );
};

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginComponentProps: {
        buttonBackgroundColor: "#144835",
        buttonColor: "#fff",
        logo: "/images/logo.png",
      },
    })
  : MyApp;
