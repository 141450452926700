import { Box, system, SystemProps } from "@storyofams/react-ui";
import styled from "styled-components";
import { variant, ResponsiveValue } from "styled-system";

const variants = {
  h1: {
    fontSize: ["69px", "96px"],
    fontWeight: "bold",
    lineHeight: [1.16, "heading"],
  },
  h2: {
    fontSize: ["41px", "59px"],
    fontWeight: "bold",
    lineHeight: [1.16, "heading"],
  },
  h3: {
    fontSize: ["26px", "37px"],
    fontWeight: "bold",
    lineHeight: ["medium", "heading"],
  },
  h4: {
    fontSize: ["16px", "23px"],
    fontWeight: "bold",
    lineHeight: ["medium", "heading"],
  },
  h5: {
    fontSize: ["10px", "14px"],
    fontWeight: "bold",
    lineHeight: ["medium", "heading"],
  },
  sub1: {
    fontSize: ["42px", "59px"],
    fontWeight: "light",
    lineHeight: "heading",
  },
  sub2: {
    fontSize: ["26px", "37px"],
    fontWeight: "light",
    lineHeight: ["medium", "heading"],
  },
  sub3: {
    fontSize: ["16px", "23px"],
    fontWeight: "light",
    lineHeight: ["medium", 1.2],
  },
  mobileh4: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "medium",
  },
};

type CustomProps = {
  variant?: ResponsiveValue<keyof typeof variants> | keyof typeof variants;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
} & SystemProps;

export const Heading = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? "h1",
}))<CustomProps>`
  font-family: ${(p) => p.theme.fonts.heading};
  ${variant({ variants })}

  ${system}
`;
