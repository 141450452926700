import { Box, Flex } from "@storyofams/react-ui";
import { PaymentIcon } from "./PaymentIcon";

export const PaymentOptions = ({ options, ...props }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexWrap="wrap"
    ml={["-8px", "-16px"]}
    {...props}
  >
    {options?.map((icon, i) => (
      <Box key={`icon-${i}`} ml={[1, 2]}>
        <PaymentIcon icon={icon} />
      </Box>
    ))}
  </Flex>
);
