import { cloneElement } from "react";
import Link from "next/link";

export const NextLink = ({ children, ...props }: any) => (
  <Link {...props}>
    {cloneElement(children, {
      onClick: (e) => {
        if (props?.onClick) {
          props.onClick(e);
        }
      },
    })}
  </Link>
);
