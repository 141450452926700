import React, { FC, forwardRef } from "react";
import { pick, omit } from "@styled-system/props";
import { useId } from "react-id-generator";
import { Props } from "react-select";

import { InputWrapper, InputWrapperProps } from "../InputWrapper";

import StyledSelect from "./components/StyledSelect";

export type SelectProps = {
  id?: string;
} & InputWrapperProps &
  Props;

export const Select: FC<SelectProps> = forwardRef(
  (
    {
      status = false,
      statusMessage = false,
      label = false,
      error,
      id: givenId,
      ...rest
    },
    ref
  ) => {
    const autoId = useId();
    const id = givenId || `select-${autoId}`;

    return (
      <InputWrapper
        id={id}
        label={label}
        status={status}
        statusMessage={statusMessage}
        error={error}
        {...pick(rest)}
      >
        <StyledSelect
          isClearable={false}
          isSearchable={false}
          forwardedRef={ref}
          {...omit(rest)}
          inputId={id}
        />
      </InputWrapper>
    );
  }
);
