import { useState, useEffect } from "react";

export const useTrueScreenHeight = () => {
  const [screenHeight, setScreenHeight] = useState(0);
  useEffect(() => {
    const onResize = () => {
      setScreenHeight(window.innerHeight);
    };
    if (typeof window !== "undefined") {
      onResize();
      window.addEventListener("resize", onResize);
      return () => {
        window.removeEventListener("resize", onResize);
      };
    }
  }, []);
  return screenHeight;
};
