import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import Tether from "tether";

interface TetherComponentProps {
  children: any;
  matchWidth?: boolean;
  options?: any;
  target: any;
}

const TetheredChildrenComponent = ({
  children,
  position,
}: Pick<TetherComponentProps, "children" | "target"> & {
  position: () => void;
}) => {
  useEffect(() => {
    position();
  }, [children]);

  return children;
};

export const TetherComponent = ({
  children,
  matchWidth,
  options,
  target,
}: TetherComponentProps) => {
  const tether = useRef<any>(null);
  const tetherContainer = useRef<any>(null);

  const destroyTetheredContent = () => {
    if (tetherContainer.current) {
      ReactDOM.unmountComponentAtNode(tetherContainer.current);
    }

    if (tether.current) {
      tether.current.destroy();
    }

    if (tetherContainer.current) {
      document.body.removeChild(tetherContainer.current);
    }
  };

  const renderTetheredContent = () => {
    ReactDOM.render(
      <TetheredChildrenComponent position={position} target={target}>
        {children}
      </TetheredChildrenComponent>,
      tetherContainer.current
    );
  };

  const position = () => {
    if (!tether.current) {
      tether.current = new Tether({
        ...options,
        element: tetherContainer.current,
        target,
      });
    }

    if (matchWidth) {
      tetherContainer.current.style.width = `${target.clientWidth}px`;
    }

    tether.current.position();
  };

  useEffect(() => {
    tetherContainer.current = document.createElement("div");
    document.body.appendChild(tetherContainer.current);

    renderTetheredContent();

    return destroyTetheredContent;
  }, []);

  useEffect(() => {
    renderTetheredContent();
  }, [children]);

  return null;
};
