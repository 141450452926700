import { createContext, useMemo, ReactNode } from "react";

export * from "./useProductColors";

type ColorMapping = {
  variant_colors?: Record<"string", "string"> | unknown;
  accent_colors?: Record<"string", "string"> | unknown;
};

interface ProviderProps {
  children: ReactNode;
  colorMapping: {
    variant_colors?: any[];
    accent_colors?: any[];
  };
}

export const ProductColorsContext = createContext<ColorMapping>({});

export const ProductColorsProvider = ({
  children,
  colorMapping: givenColorMapping = {},
}: ProviderProps) => {
  const { variant_colors, accent_colors } = givenColorMapping;
  const colorMapping = useMemo(() => {
    return {
      variant_colors: variant_colors?.reduce((all, current) => {
        all[`color_${current?.tag?.item ?? "-"}`] = {
          color: current?.color?.color,
        };

        return all;
      }, {}),
      accent_colors: accent_colors?.reduce((all, current) => {
        all[`accent_${current?.tag?.item ?? "-"}`] = {
          color: current?.color?.color,
        };

        return all;
      }, {}),
    };
  }, [givenColorMapping]);

  return (
    <ProductColorsContext.Provider value={colorMapping}>
      {children}
    </ProductColorsContext.Provider>
  );
};
