import { Icon } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import { useState, useMemo } from "react";
import styled from "styled-components";
import { SliderInput } from "~components/atoms";
import { Divider } from "~components/common/Divider";
import { FilterCheckbox } from "~components/common/FilterCheckbox";
import { FilterChevronDown } from "~components/common/Icons";
import { ColorOption } from "~components/product/ColorOption";
import { useProductColors } from "~context";

const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.5rem;
  width: 2.4rem;
  height: 2.4rem;
  background: ${(p) => p.theme.colors.primary200};
  color: ${(p) => p.theme.colors.primary600};
`;

const StyledFilter = styled.button`
  font-size: 1.6rem;
  gap: 0.8rem;
  width: 100%;
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;

  .chevron-down {
    transition: transform ease-in-out 0.2s;
  }

  .wrapper {
    width: auto;
    display: flex;
    gap: 1.4rem;
  }
`;

const StyledFilterDropDown = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.6rem;
  background: ${(p) => p.theme.colors.grey200};
  width: 100%;
  height: max-content;
`;

const StyledFilterDropDownItem = styled.div`
  margin-left: 0.8rem;
  height: 2rem;
  width: 100%;
`;

const StyledFilterColorItem = styled.div`
  margin-left: 0.8rem;
  height: 2rem;
  width: 9.7rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

type FilterBaseProps = {
  title: string;
  value: string;
  onChange: (name: string, value: string) => void;
  defaultOpen: boolean;
};

type SelectProps = {
  type: "select" | "color";
  options: {
    _uid: string;
    title: string;
    value: string;
    count: number;
    tag: { item: string };
  }[];
  parsedFilters: string[];
};

type SliderProps = {
  type: "slider";
  min: number;
  max: number;
  unit: string;
};

type FilterProps = FilterBaseProps & (SelectProps | SliderProps);

export const FilterTab = ({
  value,
  title,
  onChange,
  defaultOpen,
  ...rest
}: FilterProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const colorMapping = useProductColors();
  const { query } = useRouter();

  const count = useMemo(() => {
    let count = 0;
    let queryValue = query[value];
    if (rest.type === "color" || rest.type === "select") {
      rest;
      if (queryValue) {
        if (!Array.isArray(queryValue)) queryValue = queryValue.split(",");
        count = queryValue.length;
      }
    } else if (rest.type === "slider") {
      rest;
      if (queryValue) {
        count = 1;
      }
    }
    return count;
  }, [query]);

  const isValid = useMemo(() => {
    if (rest.type === "slider") {
      const { min, max } = rest;
      const isValid = min && max && min < max;
      if (!isValid)
        console.warn(
          `Invalid min or max value: [${String(min)},${String(max)}]`
        );
      return isValid;
    } else if (rest.type === "select" || rest.type === "color") {
      const { options } = rest;
      const isValid = options && options.length > 0;
      if (!isValid) console.warn("Invalid options");
      return isValid;
    } else {
      return true;
    }
  }, [rest]);
  if (!isValid) return null;

  return (
    <>
      <StyledFilter onClick={() => setIsOpen(!isOpen)}>
        {title}
        <div className="wrapper">
          <StyledBadge>{count}</StyledBadge>
          <Icon
            icon={FilterChevronDown}
            fontSize={3}
            className={isOpen ? "chevron-down-nested" : "chevron-down"}
          />
        </div>
      </StyledFilter>
      <Divider />

      {isOpen && (
        <StyledFilterDropDown>
          {rest.type === "slider" ? (
            <SliderInput name={value} {...rest} onChange={onChange} />
          ) : rest?.type === "select" ? (
            rest.options.map((option, idx) => (
              <StyledFilterDropDownItem key={idx}>
                <label data-test={option?.tag?.item || option?.title}>
                  <FilterCheckbox
                    value={value}
                    option={option}
                    parsedFilters={rest.parsedFilters}
                    onChange={onChange}
                  />
                </label>
              </StyledFilterDropDownItem>
            ))
          ) : rest.type === "color" ? (
            rest.options.map((option, idx) => (
              <StyledFilterColorItem key={idx}>
                <ColorOption
                  color={
                    colorMapping?.variant_colors?.[`color_${option.tag.item}`]
                      ?.color
                  }
                  isFilter
                  isSelected={rest.parsedFilters.includes(option.tag.item)}
                  onChange={() =>
                    onChange(value, option.value || option.tag.item)
                  }
                  id={option.tag.item}
                  type="checkbox"
                  count={option.count}
                  label={option.title}
                />
              </StyledFilterColorItem>
            ))
          ) : null}
        </StyledFilterDropDown>
      )}
    </>
  );
};
