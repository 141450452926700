import React from "react";
import styled from "styled-components";

import { LegacyButton, ButtonProps } from "~components/common/Button";

export type SlideProps = {
  title: string;
  height: any;
} & Omit<ButtonProps, "children">;

export const Slide = ({ title, height, ...props }: SlideProps) => (
  <Container {...props} height={height} variant="unstyled">
    {title}
  </Container>
);

const Container = styled(LegacyButton)<{ height: any }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cbd5d3;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  color: #212c2a;
  width: 100%;

  & div {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${(p) => p.height}px;
  }
`;
