import { useMemo } from "react";
import { useRouter } from "next/router";
import { defineMessages, useIntl } from "react-intl";
import { ProductSortKeys } from "~lib/shopify/sdk";
import reactSelect from "react-select";
import styled from "styled-components";
import { Icon } from "@storyofams/react-ui";
import { FilterChevronDown } from "~components/common/Icons";

const messages = defineMessages({
  popular: "Popular",
  priceAsc: "Price, low-high",
  priceDesc: "Price, high-low",
  sortBy: "Sort",
});

const StyledSelect = styled(reactSelect).attrs({
  className: "react-select",
  classNamePrefix: "react-select",
  components: {
    DropdownIndicator: () => <Icon icon={FilterChevronDown} fontSize={3} />,
  },
})`
  .react-select {
    &__control {
      font-size: 14px;
      font-weight: bold;
      border: 2px solid;
      border-radius: 100px;
      padding-right: 32px;
      padding-left: 32px;
      padding-top: 16px;
      padding-bottom: 16px;
      gap: 8px;
      color: ${(p) => p.theme.colors.grey900};
      cursor: pointer;
      box-shadow: none;

      &:hover {
        transition: "background-color 0.18s ease-in-out, box-shadow 0.18s, border-color 0.18s ease-in-out, color 0.18s ease-in-out, opacity 0.18s ease-in-out";

        background-color: ${(p) => p.theme.colors.primary400};
        border-color: ${(p) => p.theme.colors.primary600};
      }

      &--is-focused {
        border-color: ${(p) => p.theme.colors.grey900};
      }

      &--menu-is-open {
        border-color: ${(p) => p.theme.colors.grey900};
      }

      @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
        padding: 8px 16px;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__option {
      color: ${(p) => p.theme.colors.grey900};
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background-color: ${(p) => p.theme.colors.primary400};
      }

      &--is-focused {
        background-color: ${(p) => p.theme.colors.white};
      }

      &--is-selected {
        background-color: ${(p) => p.theme.colors.primary600};
        color: white;
        &:hover {
          background-color: ${(p) => p.theme.colors.primary600};
        }
      }
    }
  }
`;

const sortOptions = [
  {
    key: ProductSortKeys.BestSelling,
    message: messages.popular,
  },
  {
    key: ProductSortKeys.Price,
    message: messages.priceAsc,
    sortOrder: "ASC",
  },
  {
    key: ProductSortKeys.Price,
    message: messages.priceDesc,
    sortOrder: "DESC",
  },
];

export const SortBy = () => {
  const { push, query } = useRouter();
  const intl = useIntl();

  const handleSortChange = (sortIdx: string) => {
    const option = sortOptions[parseInt(sortIdx, 10)];
    delete query.page;

    push(
      {
        query: {
          ...query,
          sortKey: option.key,
          sortOrder: option.sortOrder || "ASC",
        },
      },
      undefined,
      { shallow: true }
    );
  };

  const value = useMemo(() => {
    const optionIdx = sortOptions.findIndex(
      ({ key }) => key === query?.sortKey
    );
    if (optionIdx >= 0) {
      if (query?.sortOrder === "DESC") {
        return {
          label: intl.formatMessage(sortOptions[optionIdx + 1].message),
          value: `${optionIdx + 1}`,
        };
      }
      return {
        label: intl.formatMessage(sortOptions[optionIdx].message),
        value: `${optionIdx}`,
      };
    }

    return {
      label: intl.formatMessage(messages.sortBy),
      value: "default",
    };
  }, [sortOptions, query]);

  return (
    <StyledSelect
      isSearchable={false}
      /* @ts-ignore */
      onChange={(event) => handleSortChange(event.value)}
      value={value}
      options={sortOptions.map(({ message }, idx) => ({
        label: intl.formatMessage(message),
        value: `${idx}`,
      }))}
    />
  );
};
