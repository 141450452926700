import { general } from "~config";

export const getLocalizedUrl = (router, locale: string) => {
  /** @todo configurable domain */
  let url = `${general.websiteUrl}${
    locale !== router.defaultLocale ? `/${locale}` : ""
  }${router.basePath}${router.asPath?.replace(/\?.*/, "")}`;

  if (url?.endsWith("/")) {
    url = url.slice(0, -1);
  }

  return url;
};
