import React from "react";
import styles from "./Button.module.scss";
import { clsx } from "clsx";
import Link from "next/link";

type Props = (
  | ({
      as: "link";
    } & React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >)
  | ({
      as: "button";
    } & React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >)
) & {
  size?: "sm" | "lg";
  color:
    | "primary"
    | "secondary"
    | "inverted"
    | "outline"
    | "invertedOutline"
    | "secondaryOutline"
    | "halfOutline"
    | "link"
    | "unstyled";
  isLoading?: boolean;
};

export const Button = ({
  as,
  size,
  color,
  isLoading,
  children,
  ...props
}: Props) => {
  const ButtonElement = as === "button" ? "button" : ({href,...props}) => (<Link href={href}><a {...props} /></Link>);
  return (
    <ButtonElement
      {...(props as any)}
      aria-disabled={isLoading}
      disabled={isLoading}
      className={clsx(
        {
          [styles["button"]]: color !== "link" && color !== "unstyled",
        },
        styles[color],
        styles[size],
        props.className
      )}
    >
      {isLoading ? "Loading" : children}
    </ButtonElement>
  );
};
