import { Stack, Icon } from "@storyofams/react-ui";
import { useIntl, defineMessages } from "react-intl";
import { useToggle } from "react-use";

import { useCheckout } from "~hooks";

import { LegacyButton } from "~components/common/Button";
import { Drawer } from "~components/common/Drawer";
import { Checkout } from "~components/common/Icons";

import { List } from "./List";
import { PreconnectCheckout } from "./PreconnectCheckout";

const messages = defineMessages({
  goToCart: "Go to cart",
  continueShopping: "Continue shopping",
  addedToCart: "Added to cart",
});

export const CartOverlay = () => {
  const intl = useIntl();
  const { showCartOverlay, toggleCartModal } = useCheckout();
  const [isBusy] = useToggle(false);

  return (
    <Drawer
      title={intl.formatMessage(messages.addedToCart)}
      isOpen={showCartOverlay}
      close={toggleCartModal}
    >
      <List sidebar />
      <Stack space={2} pt={1} flexDirection="column">
        <LegacyButton
          width="100%"
          href={"/cart"}
          onClick={() => toggleCartModal(false)}
          isLoading={isBusy}
          variant="primary"
        >
          <Icon icon={Checkout} mr={1.25} fontSize={2} />
          {intl.formatMessage(messages.goToCart)}
        </LegacyButton>
        <LegacyButton
          width="100%"
          onClick={() => toggleCartModal()}
          variant="outline"
        >
          {intl.formatMessage(messages.continueShopping)}
        </LegacyButton>
      </Stack>

      <PreconnectCheckout />
    </Drawer>
  );
};
