import { useContext } from "react";

import { ShopifyContext } from "~context";

export const useShopify = () => {
  const ctx = useContext(ShopifyContext);

  if (!ctx) {
    throw new Error(
      "Shopify context must be used within a shopify context provider"
    );
  }

  return ctx;
};

export * from "./useCheckout";
export * from "./useCustomer";
