type Props = {
  title:
    | string
    | {
        content: {
          content: {
            text: string;
            marks: { type: string }[];
            type: "text" | "hard_break";
          }[];
        }[];
      };
};

export const RichTitle = ({ title }: Props) => {
  const titleObject =
    typeof title === "string"
      ? title
      : title?.content?.[0]?.content?.map(({ text, marks, type }, idx) => {
          if (type === "hard_break") return <br key={idx} />;
          return marks?.some((mark) => mark.type === "bold") ? (
            <strong key={idx}>{text}</strong>
          ) : (
            text
          );
        });
  return <>{titleObject}</>;
};
