import React, { ReactNode } from "react";
import { StoryProvider } from "@storyofams/storyblok-toolkit";
import { LazyMotion } from "framer-motion";
import { IntlProvider } from "react-intl";
import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
} from "@tanstack/react-query";
import { ThemeProvider } from "styled-components";

import { ShopifyProvider } from "~context";
import theme from "~styles/theme/variables";
import { PathHistoryProvider } from "~context/PathHistory/usePathHistory";
import { DiscountProvider } from "~context/Discounts";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";

const loadFeatures = () =>
  import("./motionFeatures").then((res) => res.default);

type ProviderProps = {
  children: ReactNode;
  /** defaults to empty object */
  pageProps?: any;
  /** defaults to the defaultLocale */
  locale?: string;
};

const queryClient = new QueryClient();

export const Providers = ({
  children,
  locale = process.env.DEFAULT_LOCALE,
  pageProps = {},
}: ProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <HubspotProvider>
        <ThemeProvider theme={theme}>
          <IntlProvider
            messages={pageProps.messages}
            defaultLocale="en"
            locale={locale}
          >
            <StoryProvider
              token={process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}
              resolveRelations={[
                "blog-posts",
                "pages.pages",
                "blog-posts.pages",
                "collections.style",
                "rooms.style",
                "our-collections.collections",
                "special-editions.collections",
                "shop-by-collection.collections",
                "our-rooms.rooms",
              ]}
            >
              <ShopifyProvider>
                <PathHistoryProvider>
                  <DiscountProvider
                    value={pageProps.discounts?.nodes?.[0]?.discount}
                  >
                    <LazyMotion features={loadFeatures}>{children}</LazyMotion>
                  </DiscountProvider>
                </PathHistoryProvider>
              </ShopifyProvider>
            </StoryProvider>
          </IntlProvider>
        </ThemeProvider>
        </HubspotProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};
