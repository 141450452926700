import { Icon } from "@storyofams/react-ui"
import styled from "styled-components"
import { FilterCheck } from "../Icons"

const StyledContainer = styled.div`
  display: flex;
  float: left;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  label {
    cursor: pointer;
  }
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  border: 1px solid #b5b7b7;
  height: 20px;
  background: #ffffff;
  cursor: pointer;

  .active {
    visibility: visible;
  }

  .inactive {
    visibility: none;
  }
`

type FilterCheckboxProps = {
  value: string
  option: {
    _uid: string
    title: string
    value: string
    count: number
    tag: { item: string }
  }
  parsedFilters: string[]
  onChange: (name: string, value: string, replace?: boolean) => void
}

export const FilterCheckbox = ({
  value,
  option,
  parsedFilters,
  onChange
}: FilterCheckboxProps) => {
  const val = option?.tag?.item || option?.value
  return (
    <StyledContainer>
      <CheckboxContainer>
        <HiddenCheckbox
          id={option._uid}
          onChange={() => onChange(value, option?.value || option?.tag?.item)}
          checked={parsedFilters.includes(val)}
        />
        <StyledCheckbox>
          {parsedFilters.includes(val) && (
            <Icon icon={FilterCheck} fontSize={2} />
          )}
        </StyledCheckbox>
      </CheckboxContainer>
      <span>
        {option.title} ({option.count})
      </span>
    </StyledContainer>
  )
}
