import { useMemo, useRef } from "react"

export const useImageRatio = () => {
  const image = useRef<HTMLImageElement>(null)

  const imageRatio = useMemo(() => {
    if (!image.current) return 16 / 9;
    return image.current.width / image.current.width
  }
  , [image])

  return { imageRatio, image }
}