import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

const PathHistoryContext = createContext<Array<string>>([]);

interface ProviderProps {
  children: ReactNode;
}

export const PathHistoryProvider = ({ children }: ProviderProps) => {
  const [pathHistory, setPathHistory] = useState([]);
  const router = useRouter();

  useEffect(() => {
    setPathHistory((pathHistory) => {
      return [...pathHistory, router.asPath];
    });
  }, [router.asPath]);

  return (
    <PathHistoryContext.Provider value={pathHistory}>
      {children}
    </PathHistoryContext.Provider>
  );
};

export const usePathHistory = () => {
  const context = useContext(PathHistoryContext);

  return context;
};
