import { forwardRef, useEffect, useMemo, useState } from "react";
import type { countries as countriesList } from "countries-list";
import styled from "styled-components";

import { Select, SelectProps } from "../Select";

const Input = forwardRef(
  (
    { countries, ...props }: SelectProps & { countries: typeof countriesList },
    ref
  ) => {
    const options = useMemo(
      () =>
        Object.keys(countries)
          .map((code) => ({
            label: countries[code].name,
            value: code,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      []
    );

    useEffect(() => {
      if (options.length > 0 && typeof props.value === "undefined") {
        props.onChange(
          options?.find(({ value }) => value === (props.defaultValue as any)),
          {
            action: "select-option",
            option: options?.find(
              ({ value }) => value === (props.defaultValue as any)
            ),
          }
        );
      }
    }, [options.length]);

    return (
      <Select
        // @ts-expect-error
        ref={ref}
        width="100%"
        fontSize={2}
        options={options}
        {...props}
        isSearchable
      />
    );
  }
);

const DynamicInput = ({
  innerRef,
  ...props
}: SelectProps & { innerRef: any }) => {
  const [countries, setCountries] = useState(null);

  const loadCountries = async () => {
    const loadedCountries = (await import("countries-list")).countries;

    setCountries(loadedCountries);
  };

  useEffect(() => {
    loadCountries();
  }, []);

  if (!countries) {
    return null;
  }

  return <Input {...props} ref={innerRef} countries={countries} />;
};

const Wrapper = styled.div`
  .react-select {
    width: 100%;

    &__placeholder {
      line-height: ${(p) => p.theme.lineHeights.high};
      color: ${(p) => p.theme.colors.grey500};
      font-size: ${(p) => p.theme.fontSizes[1.75]}px;

      @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
        font-size: ${(p) => p.theme.fontSizes[2]}px;
      }
    }

    &__control {
      min-height: 44px;
      border-radius: 4px;
      border-color: ${(p) => p.theme.colors.grey500};
      line-height: ${(p) => p.theme.lineHeights.high};
      color: ${(p) => p.theme.colors.grey500};
      font-size: ${(p) => p.theme.fontSizes[1.75]}px;

      @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
        font-size: ${(p) => p.theme.fontSizes[2]}px;
      }
    }

    &__value-container {
      padding: 6px 8px 4px 14px !important;
    }
  }
`;

export const CountrySelect = forwardRef((props: SelectProps, ref) => {
  return (
    <Wrapper>
      <DynamicInput innerRef={ref} {...props} />
    </Wrapper>
  );
});
