import { motion } from "framer-motion";
import Link from "next/link";
import { defineMessages, useIntl } from "react-intl";

import { StyledImage, Text } from "~components";
import { Clock } from "~components/common/Icons";
import { useDate } from "~hooks/useDate";
import { BlogpostItemsQuery, BlogCategoryItemsQuery } from "~lib/storyblok/sdk";
import styles from "./BlogCard.module.scss";

const messages = defineMessages({
  minutesToRead: "min read",
});

interface BlogCardProps {
  blog: BlogpostItemsQuery["BlogpostItems"]["items"][number]["content"];
  to: string;
  publishedAt: string;
  featured?: boolean;
  category?:
    | BlogCategoryItemsQuery["BlogcategoryItems"]["items"][number]
    | undefined;
  detailed?: boolean;
}

export const BlogCard = ({
  blog: {
    title,
    synopsis,
    read_duration,
    thumbnail,
    category: ownCategory = {},
  },
  publishedAt,
  to,
  featured,
  category,
  detailed = true,
}: BlogCardProps) => {
  const { formatDate } = useDate();
  const intl = useIntl();

  return (
    <Link href={to}>
      <motion.a
        initial={{ scale: 1 }}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0.4rem 4rem rgba(0, 0, 0, 0.1)",
        }}
        className={styles.cardLink}
      >
        <div className={styles.imageWrapper}>
          {!!thumbnail && (
            <StyledImage
              src={thumbnail.filename}
              alt={thumbnail.alt || title}
              customLoader={"storyblok"}
              sizes={"(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 30vw"}
              objectFit="cover"
              layout="fill"
            />
          )}
        </div>

        <div className={styles.detailWrapper}>
          <h3>{title}</h3>

          {detailed && (
            <Text
              display={[featured ? "block" : "none", "block"]}
              variant="medium"
              color="grey700"
              mt={[1.5, 2]}
            >
              {synopsis}
            </Text>
          )}

          <div className={styles.textInfo}>
            <p>{category?.name ?? ownCategory?.name ?? ownCategory}</p>
            <time>{formatDate(publishedAt)}</time>
            <p>
              <Clock />
              {read_duration} {intl.formatMessage(messages.minutesToRead)}
            </p>
          </div>
        </div>
      </motion.a>
    </Link>
  );
};
