import React, { ReactNode } from "react";
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
  DialogOverlayProps,
} from "@reach/dialog";
import { Icon, Close, Flex, SystemProps } from "@storyofams/react-ui";
import { m, AnimatePresence } from "framer-motion";
import styled from "styled-components";

import { Heading } from "~components/common/Heading/heading";
import { useTrueScreenHeight } from "~hooks/useTrueScreenHeight";

const MotionOverlay = m(ReachDialogOverlay);
const MotionDrawer = m(ReachDialogContent);

const Overlay = styled(MotionOverlay).withConfig({
  shouldForwardProp: (prop: any) => prop !== "transparentOverlay",
})<{ transparentOverlay?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(p) => p.theme.zIndices.modal};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) =>
    p.transparentOverlay ? "transparent" : "rgba(0, 0, 0, 0.4)"};
`;

const Content = styled(MotionDrawer)<
  SystemProps & { children: ReactNode; screenHeight: number }
>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  width: 360px;
  max-width: 100%;
  height: ${(p) => p.screenHeight}px;
  padding: 0;
  margin: 0;
  background: transparent;
`;

const ContentWrapper = styled(m.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: ${(p) => p.theme.space[4]}px;
  background: ${(p) => p.theme.colors.white};
  z-index: ${(p) => p.theme.zIndices.modal + 1};
  &:after {
    content: "";
    position: fixed;
    right: 0;
    top: 0;
    width: 360px;
    height: ${(p) => p.theme.space[4]}px;
    background-color: white;
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: color 0.2s;

  &:hover {
    svg {
      color: ${(p) => p.theme.colors.grey700};
    }
  }
`;

interface Props extends DialogOverlayProps, SystemProps {
  isOpen: boolean;
  close(any): void;
  title?: string;
  ariaLabel?: string;
  children?: ReactNode;
  transparentOverlay?: boolean;
}

export const Drawer = ({
  transparentOverlay,
  children,
  ariaLabel,
  title,
  isOpen,
  close,
  ...props
}: Props) => {
  const trueScreenHeight = useTrueScreenHeight();
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          onDismiss={close}
          initial={{ opacity: 0 }}
          exit={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          transparentOverlay={transparentOverlay}
          transition={{ ease: "easeInOut", duration: 0.25 }}
          {...props}
        >
          <Content
            aria-label={ariaLabel || "modal"}
            initial={{ x: 360 }}
            exit={{ x: 360 }}
            animate={{ x: 0 }}
            screenHeight={trueScreenHeight}
            transition={{ ease: "easeInOut", duration: 0.25 }}
          >
            <ContentWrapper
              initial={{ opacity: 0 }}
              exit={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Flex
                position="sticky"
                zIndex="sticky"
                top={0}
                mb={2}
                justifyContent={!title ? "flex-end" : "space-between"}
                alignItems="center"
                bg="white"
              >
                {title && (
                  <Heading
                    as="h1"
                    variant="h4"
                    width="max-content"
                    color="grey900"
                    fontSize="23px"
                    lineHeight="heading"
                  >
                    {title}
                  </Heading>
                )}

                <CloseButton onClick={close} aria-label="Close modal">
                  <Icon icon={<Close />} fontSize="9px" color="grey700" />
                </CloseButton>
              </Flex>

              {children}
            </ContentWrapper>
          </Content>
        </Overlay>
      )}
    </AnimatePresence>
  );
};
