import { toast } from "react-toastify";

import { Toast } from "~components";

export const showToast = (
  msg: string,
  type: "info" | "success" | "error" = "info"
) =>
  toast(({ closeToast }) => (
    <Toast msg={msg} type={type} onClose={closeToast} />
  ));
