import { ReactNode } from "react";
import { Box, Flex, SystemProps } from "@storyofams/react-ui";
import { Image } from "~components";

interface HeroProps extends SystemProps {
  image?: any;
  title?: string | ReactNode;
  variant?: "default" | "masked-image";
}

export const Hero = ({
  image,
  title,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  variant = "default",
  ...props
}: HeroProps) => {
  return (
    <Flex
      alignItems="center"
      width="100%"
      height={["200px", "320px"]}
      position="relative"
      overflow="hidden"
      {...props}
    >
      <Box
        position="absolute"
        top="0"
        right="0"
        left="0"
        bottom="0"
        css={{
          ".storyblok-image-wrapper": {
            height: "100%",
          },
        }}
      >
        {!!image && (
          <Image
            src={image.filename}
            alt={image.alt || title} 
            customLoader={"storyblok"}
            layout="fill"
            objectFit="cover"
            sizes="100vw"
          />
        )}
      </Box>
    </Flex>
  );
};
