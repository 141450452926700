import { m } from "framer-motion";
import { FC, ReactNode } from "react";
import styled from "styled-components";

const StyledInviewLoader = styled(m.div)`
  position: relative;
  height: 100%;
`;

export const InviewLoader: FC<{ children: ReactNode, className?: string }> = ({
  children,
  className,
  ...props
}) => {
  return (
    <StyledInviewLoader
      initial={{ opacity: 0, top: "10vh" }}
      whileInView={{ opacity: 1, top: 0 }}
      viewport={{ once: true, amount: 0.4 }}
      transition={{ duration: 0.75 }}
      {...props}
      className={className}
    >
      {children}
    </StyledInviewLoader>
  );
};
