import { Image } from "@storyofams/storyblok-toolkit";
import storyblokEditable from "~lib/storyblokEditable";
import styles from "./ImageCollection.module.scss";

type ImageCollectionProps = {
  content: {
    images?: any;
  };
};

export const ImageCollection = ({ content }: ImageCollectionProps) => {
  const { images } = content;
  return (
    <div {...storyblokEditable(content as any)} className={styles.wrapper}>
      <div className={styles.leftColumn}>
        <div key={images[0].id} className={styles.imageCollectionImage}>
          <Image src={images[0].image.filename} />
        </div>

        <div key={images[1].id} className={styles.imageCollectionImage}>
          <Image src={images[1].image.filename} />
        </div>
      </div>
      <div className={styles.centerColumn}>
        <div key={images[2].id} className={styles.imageCollectionImage}>
          <Image src={images[2].image.filename} />
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div key={images[3].id} className={styles.imageCollectionImage}>
          <Image src={images[3].image.filename} />
        </div>
        <div key={images[4].id} className={styles.imageCollectionImage}>
          <Image src={images[4].image.filename} />
        </div>
      </div>
    </div>
  );
};
