import { Box, Flex, Icon, css } from "@storyofams/react-ui";
import dynamic from "next/dynamic";
import { LegacyContainer } from "~components";

import { useGetHref } from "~lib";
import { FooterItem } from "~lib/storyblok/sdk";
import { LegacyButton } from "../Button";
import { Divider } from "../Divider";
import { Heading } from "../Heading/heading";
import { Philips } from "../Icons";
import type { LanguageSwitch as LanguageSwitchType } from "../LanguageSwitch";
import { PaymentOptions } from "../PaymentOptions";
import { Text } from "../Text";
import { Socials } from "./Socials";
import { SubscriptionForm } from "./SubscriptionForm";

const DynamicLanguageSwitch = dynamic(
  () =>
    import("~components/common/LanguageSwitch").then((m) => m.LanguageSwitch),
  { ssr: false }
) as typeof LanguageSwitchType;

export type LinkType = {
  _uid: string;
  link: {
    cached_url?: string;
    fieldtype?: string;
    id?: string;
    linktype?: string;
    url?: string;
    email?: string;
    story?: any;
  };
  text?: string;
};

type MenuProps = {
  title?: string;
  links: LinkType[];
  i: number;
};

type FooterProps = {
  footer: FooterItem;
};

const Menu = ({ links, title, i }: MenuProps) => {
  const { getHref } = useGetHref();

  return (
    <Box
      width={["50%", "50%", "auto"]}
      mt={[4, 4, 0]}
      order={[i + 2, i + 2, "unset"]}
    >
      <Heading as="h4" variant="h4" mb={["-8px", "-8px", 0]}>
        {title}
      </Heading>

      <Box
        as="ul"
        css={css({
          flex: 1,
          listStyleType: "none",

          "& > li": {
            marginTop: [3, 3, 4],
          },
        })}
      >
        {links?.map(({ _uid, link, text }) => {
          const href = getHref(link);

          return (
            <Box as="li" key={_uid}>
              <LegacyButton variant="link-md" href={href} color="grey100">
                {text}
              </LegacyButton>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export const Footer = ({ footer }: FooterProps) => {
  const { getHref } = useGetHref();
  return (
    <Box
      pt={[2, 8]}
      pb={[3, 5]}
      as="footer"
      color="grey100"
      backgroundColor="primary600"
    >
      <LegacyContainer
        display="flex"
        flexDirection="column"
        alignItems={["flex-start", "center"]}
      >
        <SubscriptionForm
          title={footer?.content?.newsletter_title}
          subtitle={footer?.content?.newsletter_subtitle}
          button={footer?.content?.newsletter_button_label}
          placeholder={footer?.content?.newsletter_input_placeholder}
        />

        <Flex
          width="100%"
          mt={[5, 5, 8]}
          justifyContent="space-between"
          flexDirection="row"
          flexWrap={["wrap", "wrap", "nowrap"]}
        >
          <Icon
            icon={Philips}
            width="96px"
            height="123px"
            css={css({ svg: { height: "100%", width: "100%" } })}
            display={["none !important", "none !important", "flex !important"]}
          />

          {!!footer?.content?.columns?.length &&
            footer?.content?.columns?.map(({ links, title, _uid }, i) => (
              <Menu links={links} title={title} key={_uid} i={i} />
            ))}
          <Socials footer={footer} />
        </Flex>

        <Flex
          width="100%"
          position="relative"
          justifyContent="space-between"
          alignItems={["center", "center", "flex-end"]}
          flexDirection={["column", "column", "row"]}
          mt={[5, 5, 8]}
          mb={[5, 5, 0]}
          css={css({
            "&& button": {
              order: [1, 1, 2],
              mb: [5, 5, 0],
            },

            "> div": {
              order: [2, 2, 1],
            },
          })}
        >
          <Icon
            icon={Philips}
            width={["48px", "72px", "96px"]}
            height={["61px", "92px", "123px"]}
            css={css({ svg: { height: "100%", width: "100%" } })}
            display={["flex", "flex", "none !important"]}
            mb={[5, 5, 0]}
          />
          <PaymentOptions options={footer?.content?.payment_options} />
          <DynamicLanguageSwitch button footer />
        </Flex>

        <Divider
          bg="grey100"
          my={[2, 5]}
          display={["none !important", "none !important", "flex !important"]}
        />

        <Flex
          width="100%"
          justifyContent="space-between"
          flexDirection={["column", "column", "row"]}
        >
          <Flex flexWrap="wrap">
            {footer?.content?.legal_links?.map((legal_link, idx) => {
              return (
                <LegacyButton
                  key={legal_link?.link?.id || idx}
                  color="grey100"
                  variant="link-md"
                  href={getHref(legal_link?.link)}
                  mr={[3, 4]}
                >
                  {legal_link?.text}
                </LegacyButton>
              );
            })}
          </Flex>

          <Divider
            bg="grey100"
            my={2}
            display={["flex", "flex", "none !important"]}
          />

          <Text variant="medium" color="grey100">
            &#169;&nbsp;{`${new Date().getFullYear()} Philips`}
          </Text>
        </Flex>
      </LegacyContainer>
    </Box>
  );
};
