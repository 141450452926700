import { showToast } from "./showToast";

type OnErrorOptions =
  | {
      fallback?: never;
      silent: boolean;
    }
  | {
      fallback: string;
      silent?: never;
    };

class ErrorHandler {
  private shouldLog = process.env.NODE_ENV !== "production";
  constructor(private logger?: any) {
    this.logger = logger ?? console;
  }

  public logError(error: Error) {
    if (this.shouldLog) this.logger.error(error);
  }

  public logInfo(error: Error) {
    if (this.shouldLog) this.logger.error(error);
  }

  public onError(error: Error, { fallback, silent }: OnErrorOptions) {
    this.logError(error);
    if (!silent) showToast(error?.message ?? fallback, "error");
  }
}

export const errorHandler = new ErrorHandler();
