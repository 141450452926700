import { createContext, ReactNode, useEffect, useState } from "react";

export const ViewedProductsContext = createContext<any>(null);

type ViewedProductsProviderProps = {
  eventIdentifier: string;
  children: ReactNode;
};

function trackViewItemList(viewedProducts: any, eventIdentifier: string) {
  if (typeof window === "undefined") return;
  window.dataLayer.push({ ecommerce: null });
  window?.dataLayer?.push({
    event: eventIdentifier,
    ecommerce: {
      items: viewedProducts,
    },
  });
}

export const ViewedProductsProvider = ({
  children,
  eventIdentifier,
}: ViewedProductsProviderProps) => {
  const [viewedProducts, setViewedProducts] = useState<any>([]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    let timer = 0;

    if (viewedProducts.length > 0) {
      timer = window.setTimeout(() => {
        trackViewItemList(viewedProducts, eventIdentifier);
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [viewedProducts]);

  return (
    <ViewedProductsContext.Provider
      value={{ viewedProducts, setViewedProducts }}
    >
      {children}
    </ViewedProductsContext.Provider>
  );
};
