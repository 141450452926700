export const isActiveNavItem = (
  pathname: string,
  key: string,
  route: string
) => {
  switch (key) {
    case "collections":
      return pathname?.startsWith(route) || pathname?.startsWith("/styles/");
    default:
      return pathname === route;
  }
};
