import { Box, SystemProps, system } from "@storyofams/react-ui";
import styled from "styled-components";
import { ResponsiveValue, variant } from "styled-system";

const variants = {
  large: {
    fontSize: ["16px", "23px"],
    fontWeight: "regular",
    lineHeight: "high",
    color: "grey700",
  },
  medium: {
    fontSize: ["14px", "16px"],
    fontWeight: "regular",
    lineHeight: "high",
    color: "grey700",
  },
  small: {
    fontSize: ["10px", "9px"],
    fontWeight: "regular",
    lineHeight: "high",
    color: "grey900",
  },
  label: {
    fontSize: ["12px", "14px"],
    display: "flex",

    lineHeight: "high",
    fontWeight: "bold",
    color: "grey900",

    cursor: "pointer",

    '&[disabled=""]': {
      cursor: "not-allowed",
      opacity: 0.6,
    },
  },
};

type CustomProps = {
  /** defaults to `p` */
  as?:
    | "p"
    | "span"
    | "blockquote"
    | "strong"
    | "em"
    | "pre"
    | "label"
    | "div"
    | "li";
  variant?: ResponsiveValue<keyof typeof variants> | keyof typeof variants;
} & SystemProps;

export const Text = styled(Box).attrs((props: CustomProps) => ({
  as: "p",
  ...props,
}))<CustomProps>`
  ${variant({ variants })} && {
    ${system}
  }
`;
