import { FC } from "react";
import { Box, SystemProps } from "@storyofams/react-ui";

type NavigationProps = SystemProps

export const Navigation: FC<NavigationProps> = ({ children, ...props }) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex="modal"
      backgroundColor="white"
      boxShadow={"0px 0px 23px rgba(0, 0, 0, 0.09)"}
      {...props}
    >
      <Box width="100%" position="relative">
        {children}
      </Box>
    </Box>
  );
};
