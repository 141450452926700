import { SbBlokData } from "@storyblok/react";

export default (blok: SbBlokData) => {
  if (
    typeof blok !== "object" ||
    typeof blok._editable === "undefined" ||
    blok._editable === null
  ) {
    return {};
  }

  const options = JSON.parse(
    blok._editable.replace(/^<!--#storyblok#/, "").replace(/-->$/, "")
  );

  return {
    "data-blok-c": JSON.stringify(options),
    "data-blok-uid": options.id + "-" + options.uid,
  };
};
