import dynamic from "next/dynamic";

export const ApplePay = dynamic(() => import("./library/applepay.svg"));
export const ArrowLeft = dynamic(() => import("./library/arrow-left.svg"));
export const ArrowRight = dynamic(() => import("./library/arrow-right.svg"));
export const Attention = dynamic(() => import("./library/attention.svg"));
export const Bitcoin = dynamic(() => import("./library/bitcoin.svg"));
export const Cart = dynamic(() => import("./library/cart.svg"));
export const Checkout = dynamic(() => import("./library/checkout.svg"));
export const Checkmark = dynamic(() => import("./library/checkmark.svg"));
export const ChevronDown = dynamic(() => import("./library/chevron-down.svg"));
export const ChevronRight = dynamic(
  () => import("./library/chevron-right.svg")
);
export const ChevronDouble = dynamic(
  () => import("./library/chevrons-down.svg")
);
export const Clock = dynamic(() => import("./library/clock.svg"));
export const Cross = dynamic(() => import("./library/cross.svg"));
export const Facebook = dynamic(() => import("./library/facebook.svg"));
export const FilterCheck = dynamic(() => import("./library/filter-check.svg"));
export const FilterChevronDown = dynamic(
  () => import("./library/filter-chevron-down.svg")
);
export const FilterCross = dynamic(() => import("./library/filter-cross.svg"));
export const FilterSlider = dynamic(
  () => import("./library/filter-slider.svg")
);
export const Hex = dynamic(() => import("./library/hex.svg"));
export const Home = dynamic(() => import("./library/home.svg"));
export const HomepageBg1 = dynamic(() => import("./library/homepage-bg-1.svg"));
export const HomepageBg2 = dynamic(() => import("./library/homepage-bg-2.svg"));
export const Ideal = dynamic(() => import("./library/ideal.svg"));
export const Instagram = dynamic(() => import("./library/instagram.svg"));
export const Lines = dynamic(() => import("./library/lines.svg"));
export const Linkedin = dynamic(() => import("./library/linkedin.svg"));
export const Logo = dynamic(() => import("./library/my-creation-logo.svg"));
export const Logout = dynamic(() => import("./library/logout.svg"));
export const Mastercard = dynamic(() => import("./library/mastercard.svg"));
export const Minus = dynamic(() => import("./library/minus.svg"));
export const PaginationArrowRight = dynamic(
  () => import("./library/pagination-arrow-right.svg")
);
export const PaginationArrowLeft = dynamic(
  () => import("./library/pagination-arrow-left.svg")
);
export const Philips = dynamic(() => import("./library/philips.svg"));
export const Pinterest = dynamic(() => import("./library/pinterest.svg"));
export const Plus = dynamic(() => import("./library/plus.svg"));
export const Play = dynamic(() => import("./library/play.svg"));
export const ReviewStarFull = dynamic(
  () => import("./library/review-star-full.svg")
);
export const ReviewStarHalf = dynamic(
  () => import("./library/review-star-half.svg")
);
export const Scribble = dynamic(() => import("./library/scribble.svg"));
export const Search = dynamic(() => import("./library/search.svg"));
export const ShoppingBasket = dynamic(
  () => import("./library/shoppingbasket.svg")
);
export const Sofort = dynamic(() => import("./library/sofort.svg"));
export const Stripes = dynamic(() => import("./library/stripes.svg"));
export const Twitter = dynamic(() => import("./library/twitter.svg"));
export const User = dynamic(() => import("./library/user.svg"));
export const Visa = dynamic(() => import("./library/visa.svg"));
export const Wavy = dynamic(() => import("./library/wavy.svg"));
export const Wavy90 = dynamic(() => import("./library/wavy90.svg"));
export const WavyHome = dynamic(() => import("./library/wavy-home.svg"));
export const XCircle = dynamic(() => import("./library/x-circle.svg"));
export const Youtube = dynamic(() => import("./library/youtube.svg"));
