import React, { FC } from "react";
import { css, SystemProps } from "@storyofams/react-ui";
import { useRouter } from "next/router";

import { isActiveNavItem, useGetHref } from "~lib";
import { Link } from "~components/common/Link";

interface NavigationLinkProps extends SystemProps {
  text?: string;
  link?: {
    url?: string;
  };
  _uid: string;
}

export const NavigationLink: FC<NavigationLinkProps> = ({
  text,
  link,
  _uid,
  ...props
}) => {
  const router = useRouter();

  const { getHref } = useGetHref();
  const href = getHref(link);

  const active = isActiveNavItem(
    router.asPath?.split("?")?.[0],
    _uid,
    link?.url || href || "/"
  );

  return (
    <Link
      stylingProps={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: ["space-between", "space-between", "center"],
        fontWeight: "medium",
        fontSize: 1.75,
        lineHeight: "high",
        transition: "0.18s color ease-in-out",
        css: css({
          color: active ? "primary" : "grey700",
          "&:after": {
            content: JSON.stringify(""),
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "2px",
            bg: active ? "primary" : "transparent",
            borderRadius: "xxs",
          },
          "&:hover": { color: "primary" },
        }),
      }}
      href={href}
      {...props}
    >
      {text}
    </Link>
  );
};
