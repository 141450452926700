import React, { ReactNode, useState } from "react";
import { css, Box, Flex, SystemProps } from "@storyofams/react-ui";
import { useMeasure } from "react-use";

import { Heading } from "../Heading/heading";

export interface AccordionProps extends SystemProps {
  title: string;
  children: ReactNode | string;
}

export const Accordion = ({ title, children, ...props }: AccordionProps) => {
  const [open, setOpen] = useState(false);
  const [ref, { height }] = useMeasure();

  return (
    <Box borderBottom="1px solid" borderBottomColor="grey900" mt="-1px">
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        /** @ts-ignore this is an indication of whats wrong with the return type of the polymorph */
        onClick={() => setOpen(!open)}
        pt={3}
        pb={2}
        cursor="pointer"
        {...props}
      >
        <Heading variant="h4" as="h5" color="grey900">
          {title}
        </Heading>
        <Flex
          css={css({
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: ["26px", "32px"],
            "&::before, &::after": {
              content: JSON.stringify(""),
              bg: "grey900",
              right: 0,
              top: "50%",
              height: "2px",
              width: ["10px", "18px"],
              display: "block",
              transition: "0.1s transform ease-in-out",
              borderRadius: "xxs",
            },
            "&::after": {
              transform: `translateY(-2px) rotate(${open ? "0deg" : "90deg"})`,
            },
          })}
        />
      </Flex>
      <Box
        maxHeight={open ? `${height + 4 * 8}px` : 0}
        transition="max-height ease 0.3s"
        overflow="hidden"
      >
        <Box
          pt={1}
          pb={3}
          transition="opacity ease 0.25s"
          opacity={open ? 1 : 0}
          ref={ref}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
